import cn from 'classnames';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Nullable } from '@/app/types';
import { useOnceTrue } from '@/hooks/useOnceTrue';
import { FilterBtn } from '@/modules/showroom/advert/search/SearchAdverts/Filters/FilterBtn/FilterBtn';
import cls from '@/modules/showroom/advert/search/SearchAdverts/Filters/Filters.module.scss';
import { FiltersList } from '@/modules/showroom/advert/search/SearchAdverts/Filters/FiltersList/FiltersList';
import { FilterType } from '@/modules/showroom/advert/search/SearchAdverts/Filters/FiltersList/helpers';
import { useShowroomContacts } from '@/modules/showroom/contact/api';
import { ShowroomContact } from '@/modules/showroom/contact/types';
import { Dropdown } from '@/ui/Dropdown/Dropdown';

type Props = {
  contactIds: Nullable<number[]>;
  setContactIds: (contactIds: Nullable<number[]>) => void;
};

export function ContactsFilter({ contactIds, setContactIds }: Props) {
  const { t } = useTranslation();

  const [isOpen, setOpen] = useState(false);
  const onceOpened = useOnceTrue(isOpen);

  const [allContacts, contactsLoading] = useShowroomContacts(!onceOpened);
  const selectedContacts = allContacts?.filter((b) =>
    contactIds?.includes(b.id)
  );

  const onContactsChange = (v: FilterType) => {
    const c = v as ShowroomContact;
    if (contactIds && contactIds.includes(c.id)) {
      setContactIds(contactIds.filter((cId) => cId !== c.id));
    } else {
      setContactIds(contactIds ? [...contactIds, c.id] : [c.id]);
    }
  };

  const onContactsReset = () => {
    setOpen(false);
    setContactIds(null);
  };

  return (
    <>
      <FilterBtn
        text={t('showroom.contacts.manager')}
        onClick={() => setOpen(true)}
        active={isOpen}
        onResetClick={onContactsReset}
        value={selectedContacts
          ?.map((b) => b.name)
          .join(t('common.listSeparator') as string)}
        solidBorder
      />

      <Dropdown
        className={cn(cls.dropdown, cls.dropdown_select)}
        name="leads-contacts"
        isOpen={isOpen}
        close={() => setOpen(false)}
        withModal
        toLeft
      >
        <li className="hide-scrollbar">
          <FiltersList
            activeId={contactIds}
            list={allContacts || []}
            loading={contactsLoading}
            onClick={onContactsChange}
            search=""
            allButtonText={t('common.any')}
            onAllClick={onContactsReset}
            selectStyle
            multiple
          />
        </li>
      </Dropdown>
    </>
  );
}
