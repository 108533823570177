import cn from 'classnames';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Nullable } from '@/app/types';
import { useLang } from '@/hooks/useLang';
import { MAX_PRICE_LEN } from '@/modules/showroom/advert/create/helpers';
import { FilterBtn } from '@/modules/showroom/advert/search/SearchAdverts/Filters/FilterBtn/FilterBtn';
import { FilterRange } from '@/modules/showroom/advert/search/SearchAdverts/Filters/FiltersList/FilterRange';
import { Dropdown } from '@/ui/Dropdown/Dropdown';
import { ADV_CUR } from '@/utils/consts';
import { numberWithSpaces } from '@/utils/format';

import cls from './Filters.module.scss';

type Props = {
  min: Nullable<number>;
  max: Nullable<number>;
  setMin: (priceMin: Nullable<number>) => void;
  setMax: (priceMin: Nullable<number>) => void;
};

export function PriceFilter({ min, max, setMin, setMax }: Props) {
  const { t } = useTranslation();
  const [lang] = useLang();

  const [isPriceOpen, setPriceOpen] = useState(false);

  const valueText = useMemo(() => {
    const isMinNum = typeof min === 'number';
    const isMaxNum = typeof max === 'number';
    if (isMinNum && isMaxNum && min && max) {
      const str = t('common.hyphenSeparated', {
        first: numberWithSpaces(min, lang),
        second: numberWithSpaces(max, lang)
      });
      return t('priceCurrency', { price: str, currency: ADV_CUR });
    }

    if (!min && max && isMaxNum) {
      const str = t('toPrice', { price: numberWithSpaces(max, lang) });
      return t('priceCurrency', { price: str, currency: ADV_CUR });
    }

    if (min) {
      const str = t('fromPrice', { price: numberWithSpaces(min, lang) });
      return t('priceCurrency', { price: str, currency: ADV_CUR });
    }

    return '';
  }, [lang, max, min, t]);

  const onResetClick = () => {
    setMin(null);
    setMax(null);
  };

  return (
    <>
      <FilterBtn
        text={t('price')}
        onClick={() => setPriceOpen(true)}
        active={isPriceOpen}
        onResetClick={onResetClick}
        value={valueText}
      />

      <Dropdown
        className={cn(cls.dropdown, cls.dropdown_imp, cls.dropdown_range)}
        name="search-filters-price"
        isOpen={isPriceOpen}
        close={() => setPriceOpen(false)}
        withModal
        toLeft
      >
        <li>
          <FilterRange
            label={t('common.commaSeparated', {
              first: t('price'),
              second: ADV_CUR
            })}
            from={min}
            to={max}
            setFrom={setMin}
            setTo={setMax}
            maxLength={MAX_PRICE_LEN}
          />
        </li>
      </Dropdown>
    </>
  );
}
