import { axios } from '@/app/api';
import {
  AutoReplenishmentRes,
  SavedPaymentMethodsRes,
  UpdateAutoReplenishmentReq
} from '@/modules/wallet/AutoReplenishment/types';

export function getAutoReplenishmentInfo() {
  return axios.get<AutoReplenishmentRes>(`/accounts/auto-replenishment`);
}

export function updateAutoReplenishmentSettings(
  req: UpdateAutoReplenishmentReq
) {
  return axios.post<AutoReplenishmentRes>(`/accounts/auto-replenishment`, req);
}

export function getSavedPaymentMethods() {
  return axios.get<SavedPaymentMethodsRes>(
    `/accounts/auto-replenishment/cards/saved`
  );
}
