import { create } from 'zustand';

import { ClientCommunicationRes } from '@/modules/client-comm/types';
import { CountryCodeType } from '@/modules/country-codes/types';
import { RefreshWeekDay } from '@/modules/refresh/types';
import { BREAKPOINT } from '@/utils/consts';
import { getCookie, setCookie } from '@/utils/cookie';

const sidebarMinimizedName = 'sidebar-minimized';
const getFromCookie = (name: string): boolean => {
  const fromCookie = getCookie(name);
  return fromCookie ? (JSON.parse(fromCookie) as boolean) : false;
};

interface CommonStore {
  isSidebarMinimized: boolean;
  setSidebarMinimized: (isSidebarMinimized: boolean, save?: boolean) => void;
  windowWidth: number;
  setWindowWidth: (windowWidth: number) => void;
  callbackCountKey: number;
  setCallbackCountKey: (callbackCountKey: number) => void;
  countryCodes: CountryCodeType[];
  setCountryCodes: (countryCodes: CountryCodeType[]) => void;
  weekDays: RefreshWeekDay[];
  setWeekDays: (weekDays: RefreshWeekDay[]) => void;
  refreshPrice: number;
  setRefreshPrice: (refreshPrice: number) => void;
  clientCommunication: ClientCommunicationRes | undefined;
  setClientCommunication: (
    clientCommunication: ClientCommunicationRes | undefined
  ) => void;
}

export const useCommonStore = create<CommonStore>((set) => ({
  isSidebarMinimized: getFromCookie(sidebarMinimizedName),
  setSidebarMinimized: (isSidebarMinimized: boolean, save?: boolean) => {
    set({ isSidebarMinimized });
    const shouldSave = typeof save === 'boolean' ? save : true;
    if (shouldSave)
      setCookie(sidebarMinimizedName, JSON.stringify(isSidebarMinimized));
  },
  windowWidth: window.innerWidth,
  setWindowWidth: (windowWidth: number) => set({ windowWidth }),
  callbackCountKey: 0,
  setCallbackCountKey: (callbackCountKey: number) => set({ callbackCountKey }),
  countryCodes: [],
  setCountryCodes: (countryCodes: CountryCodeType[]) => set({ countryCodes }),
  weekDays: [],
  setWeekDays: (weekDays) => set({ weekDays }),
  refreshPrice: 0,
  setRefreshPrice: (refreshPrice) => set({ refreshPrice }),
  clientCommunication: undefined,
  setClientCommunication: (
    clientCommunication: ClientCommunicationRes | undefined
  ) => set({ clientCommunication })
}));

export function useDesktop(bp?: number): boolean {
  const windowWidth = useCommonStore((state) => state.windowWidth);
  return windowWidth > (bp || BREAKPOINT);
}
