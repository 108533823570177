import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useAuthStore } from '@/modules/auth/store';
import { useMyRoles } from '@/modules/roles/hooks';
import { RolesTab } from '@/modules/roles/RolesPage/RolesTab';
import { UsersTab } from '@/modules/roles/RolesPage/UsersTab';
import { Button } from '@/ui/Button/Button';
import { InputSearch } from '@/ui/InputSearch/InputSearch';
import { LayoutHeader } from '@/ui/layouts/SidebarLayout/LayoutHeader';
import { PageHead } from '@/ui/PageHead/PageHead';
import { TabOption, Tabs } from '@/ui/Tabs/Tabs';

import { AddIcon } from './icons';
import cls from './RolesPage.module.scss';

export function RolesPage() {
  const { t } = useTranslation();

  const auth = useAuthStore((s) => s.auth);
  const isOwner = !!auth?.is_owner;

  // Tabs
  const tabs = useMemo(
    () =>
      isOwner
        ? [
            { id: 'users', text: t('roles.users') },
            { id: 'roles', text: t('roles.roles') }
          ]
        : [{ id: 'roles', text: t('roles.roles') }],
    [isOwner, t]
  );
  const [tab, setTab] = useState(tabs[0].id);
  const activeTab = tabs.find((opt) => opt.id === tab);
  const isRolesTab = tab === 'roles';

  // State
  const [search, setSearch] = useState('');
  const [isInviteOpen, setInviteOpen] = useState(false);
  const [isNewRoleOpen, setNewRoleOpen] = useState(false);

  const onTabChange = (opt: TabOption) => {
    setTab(opt.id);
    setSearch('');
  };

  // Roles
  const roles = useMyRoles();

  return (
    <>
      <LayoutHeader title={t('roles.pageTitle')} />
      <PageHead title={t('pageTitle', { title: t('roles.pageTitle') })} />

      <div className={cls.root}>
        <div className={cls.tabs}>
          <Tabs
            tabs={tabs}
            active={activeTab}
            onChange={onTabChange}
            variant="underline"
          />
        </div>

        <div className={cls.container}>
          <div className={cls.row}>
            <div className={cls.search_field}>
              <InputSearch
                value={search}
                onChange={setSearch}
                placeholder={t('common.search')}
                showClear
                compact
                outlined
              />
            </div>

            {isRolesTab ? (
              <Button
                onClick={() => setNewRoleOpen(true)}
                gap={4}
                size="compact"
                variant="secondary"
                color="black"
              >
                <AddIcon />
                {t('roles.newRole')}
              </Button>
            ) : (
              <Button
                onClick={() => setInviteOpen(true)}
                gap={4}
                size="compact"
                variant="secondary"
                color="black"
              >
                <AddIcon />
                {t('roles.invite.label')}
              </Button>
            )}
          </div>

          {isRolesTab ? (
            <RolesTab
              roles={roles}
              search={search}
              isNewRoleOpen={isNewRoleOpen}
              setNewRoleOpen={setNewRoleOpen}
            />
          ) : (
            <UsersTab
              roles={roles}
              search={search}
              isInviteOpen={isInviteOpen}
              setInviteOpen={setInviteOpen}
            />
          )}
        </div>
      </div>
    </>
  );
}
