import { Navigate, Outlet, useLocation } from 'react-router-dom';

import { useRedirectCheck } from '@/hooks/useRedirectCheck';
import { AUTH_REDIRECT_ROUTES } from '@/modules/auth/helpers';
import { useAuthed } from '@/modules/auth/hooks';
import { AuthState } from '@/modules/auth/types';
import { useAvailablePathByRole } from '@/ui/layouts/SidebarLayout/Sidebar/Nav/helpers';

export function AuthRoute() {
  const location = useLocation();
  const { pathname } = location;
  const [isAuthed, authState] = useAuthed();
  const availablePath = useAvailablePathByRole();

  const { shouldConfirmEmail, shouldCreateShowroom } = useRedirectCheck();

  if (authState === AuthState.initial) {
    return null;
  }

  if (
    !shouldConfirmEmail &&
    !shouldCreateShowroom &&
    isAuthed &&
    (AUTH_REDIRECT_ROUTES.includes(pathname) ||
      AUTH_REDIRECT_ROUTES.some(
        (v) => pathname.startsWith(v) && pathname !== '/'
      ))
  ) {
    return <Navigate to={availablePath} state={{ from: location }} />;
  }

  return <Outlet />;
}
