import cn from 'classnames';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from '@/ui/Button/Button';
import { Input } from '@/ui/Input/Input';
import { Lottie } from '@/ui/Lottie';
import { AdaptiveModal } from '@/ui/modals/AdaptiveModal';
import { EmailCodeConfirm } from '@/ui/PhoneConfirm/EmailCodeConfirm/EmailCodeConfirm';
import { CloseIcon } from '@/ui/PhoneConfirm/PhoneCodeConfirm/icons';
import { showAlert } from '@/utils/network';
import { MAX_EMAIL_LENGTH, validateEmail } from '@/utils/validate';

import cls from './EmailCodeConfirm.module.scss';

type Props = {
  oldEmail: string;
  onConfirm: (email: string) => void;
  confirmEmailSendCode: (email: string) => Promise<unknown>;
  confirmEmail: (email: string, code: string) => Promise<unknown>;
  isEditEmailOpen: boolean;
  setEditEmailOpen: Dispatch<SetStateAction<boolean>>;
};

export function EditEmail({
  oldEmail,
  onConfirm,
  confirmEmailSendCode,
  confirmEmail,
  isEditEmailOpen,
  setEditEmailOpen
}: Props) {
  const { t } = useTranslation();

  const [email, setEmail] = useState(oldEmail);

  const [isEmailConfirmOpen, setEmailConfirmOpen] = useState(false);
  const [isEmailConfirmed, setEmailConfirmed] = useState(false);
  const [isEmailTouched, setEmailTouched] = useState(false);
  const [isEmailValid, setIsEmailValid] = useState(true);

  useEffect(() => {
    setIsEmailValid(!!email && validateEmail(email));
  }, [email, setIsEmailValid]);

  const [isCodeSending, setCodeSending] = useState(false);

  const sendCode = async () => {
    setCodeSending(true);

    try {
      await confirmEmailSendCode(email);
      setEmailConfirmOpen(true);
    } catch (error) {
      showAlert({ error });
    } finally {
      setCodeSending(false);
    }
  };

  const handleCofirmModalClose = () => {
    if (isEmailConfirmed) onConfirm(email);

    setEditEmailOpen(false);
  };

  return (
    <AdaptiveModal
      name="edit-phone-modal"
      isOpen={isEditEmailOpen}
      close={handleCofirmModalClose}
      containerClass={cn(cls.modal_container, cls.modal_container_imp)}
    >
      {isEmailConfirmOpen && (
        <EmailCodeConfirm
          email={email}
          confirmEmail={confirmEmail}
          confirmEmailSendCode={confirmEmailSendCode}
          close={handleCofirmModalClose}
          isEmailConfirmed={isEmailConfirmed}
          setEmailConfirmed={setEmailConfirmed}
          isEdit
        />
      )}

      {!isEmailConfirmOpen && (
        <div className={cls.root}>
          <div className={cls.icon}>
            <Lottie src="envelope" />
          </div>

          <h1 className={cls.title}>{t('changeEmail')}</h1>

          <h2 className={cls.code_title}>{t('auth.changeEmailText')}</h2>

          <div className={cls.fields}>
            <Input
              value={email}
              onChange={(e) => setEmail(e.currentTarget.value.trim())}
              onBlur={() => setEmailTouched(true)}
              label={t('auth.email')}
              type="email"
              errorText={
                !isEmailValid && isEmailTouched ? t('auth.invalidEmail') : ''
              }
              maxLength={MAX_EMAIL_LENGTH}
            />
          </div>

          <Button
            color="green"
            disabled={!isEmailValid}
            loading={isCodeSending}
            onClick={sendCode}
            fullWidth
          >
            {t('common.continue')}
          </Button>

          <button className={cls.close_btn} onClick={handleCofirmModalClose}>
            <CloseIcon />
          </button>
        </div>
      )}
    </AdaptiveModal>
  );
}
