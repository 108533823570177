import cn from 'classnames';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  isVinValid,
  MAX_VIN_LEN
} from '@/modules/showroom/advert/create/helpers';
import { Message } from '@/modules/showroom/advert/create/Message/Message';
import { VinHelp } from '@/modules/showroom/advert/create/VinSuggest/VinHelp';
import { VinPhotoRecognition } from '@/modules/showroom/advert/create/VinSuggest/VinPhotoRecognition/VinPhotoRecognition';
import { Button } from '@/ui/Button/Button';
import { Input } from '@/ui/Input/Input';
import { Lottie } from '@/ui/Lottie';
import { maxLength, onlyNumbersLatin } from '@/utils/format';

import cls from './VinSuggest.module.scss';

type Props = {
  onSkipClick: () => void;
  onSubmitClick: (vin: string) => void;
  loading?: boolean;
};

export function VinSuggest({ onSkipClick, onSubmitClick, loading }: Props) {
  const { t } = useTranslation();

  const [isHelpOpen, setHelpOpen] = useState(false);

  const [vin, setVin] = useState('');
  const isValid = isVinValid(vin);

  const onKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    const isOnlyEnter = e.key === 'Enter' && !e.shiftKey;
    if (isValid && isOnlyEnter) {
      onSubmitClick(vin);
    }
  };

  return (
    <div className={cls.root}>
      <div className={cn('box', cls.box)}>
        <div className={cls.img}>
          <Lottie src="vin" />
        </div>
        <h2 className={cls.title}>{t('vin.chassis')}</h2>
        <p className={cls.text}>{t('vin.suggestText')}</p>

        <div className={cls.input}>
          <Input
            value={vin}
            onChange={(e) =>
              setVin(
                onlyNumbersLatin(maxLength(e.currentTarget.value, MAX_VIN_LEN))
              )
            }
            onKeyDown={onKeyDown}
            disabled={loading}
            label={t('vin.chassis')}
          />

          <VinPhotoRecognition
            onVinRecognized={(vin) => {
              setVin(vin);
              onSubmitClick(vin);
            }}
          />
        </div>

        <div className={cls.tip_wrap}>
          <button
            className={cls.tip}
            type="button"
            onClick={() => setHelpOpen(true)}
          >
            {t('vin.searchTip')}
          </button>
        </div>

        <div className={cls.buttons}>
          <Button
            variant="secondary"
            color="black"
            onClick={onSkipClick}
            disabled={loading}
          >
            {t('vin.fillLater')}
          </Button>
          <Button
            onClick={() => onSubmitClick(vin)}
            disabled={!isValid || loading}
            loading={loading}
          >
            {t('common.continue')}
          </Button>
        </div>
      </div>

      <Message>
        <ul>
          <li>
            <p>{t('vin.msg.1')}</p>
          </li>
          <li>
            <p>{t('vin.msg.2')}</p>
          </li>
          <li>
            <p>{t('vin.msg.3')}</p>
          </li>
        </ul>
      </Message>

      <VinHelp isOpen={isHelpOpen} close={() => setHelpOpen(false)} />
    </div>
  );
}
