import { Skeleton } from '@/ui/Skeleton';

import cls from './RolesPage.module.scss';

export function UserCardSkeleton() {
  return (
    <div className={cls.card}>
      <div className={cls.card_row}>
        <div>
          <div style={{ marginBottom: 2 }}>
            <Skeleton width={120} height={23} />
          </div>
          <div>
            <Skeleton width={132} height={20} />
          </div>
        </div>

        <div className={cls.card_actions}>
          <Skeleton width={128} height={36} />
          <Skeleton width={36} height={36} />
        </div>
      </div>

      <div className={cls.card_hr} />

      <div className={cls.card_row} style={{ gap: 36 }}>
        <div className={cls.card_col}>
          <Skeleton width={40} height={20} />
          <Skeleton width={40} height={24} />
        </div>

        <div className={cls.card_col}>
          <Skeleton width={103} height={20} />
          <Skeleton width={103} height={24} />
        </div>
      </div>
    </div>
  );
}
