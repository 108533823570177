export function CloseIcon() {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.82694 16.1721C4.17394 16.5111 4.75921 16.5067 5.08961 16.1763L10.0014 11.2646L14.9103 16.1749C15.2465 16.5111 15.8311 16.5162 16.1715 16.1706C16.512 15.8236 16.5134 15.2521 16.1773 14.9145L11.2683 9.9976L16.1773 5.08869C16.5134 4.75254 16.5185 4.17446 16.1715 3.834C15.8245 3.487 15.2465 3.48556 14.9103 3.82826L10.0014 8.73717L5.08961 3.82682C4.75921 3.49131 4.1674 3.47901 3.82694 3.83257C3.48792 4.17957 3.49223 4.75685 3.82263 5.08725L8.73441 9.9976L3.82263 14.9174C3.49223 15.2463 3.48137 15.8316 3.82694 16.1721Z"
        fill="currentColor"
      />
    </svg>
  );
}
