import { useMemo } from 'react';

import { Nullable } from '@/app/types';
import { useAccountStore } from '@/modules/accounts/store';
import { useAuthStore } from '@/modules/auth/store';
import { ClientCommunicationRes } from '@/modules/client-comm/types';
import { PERMISSIONS_MAP } from '@/modules/roles/helpers';
import { EmployeeType } from '@/modules/roles/types';
import {
  CARS_ADVERTS_URL,
  RENT_ADVERTS_URL,
  VRP_ADVERTS_URL
} from '@/modules/showroom/advert/my/helpers';

import {
  OverviewIcon,
  CarIcon,
  CalendarIcon,
  PlateIcon,
  ChatIcon,
  CallIcon,
  CreditReqIcon,
  WalletNumbersIcon,
  TariffsIcon,
  InfoIcon,
  AddressIcon,
  ContactIcon,
  ReviewIcon,
  LeadsIcon,
  NotifyIcon,
  RolesIcon
} from './icons';

export type MappedNavChild = {
  label: string;
  path: string;
  icon: React.ReactNode;
  end?: boolean;
  count?: number;
  access: number;
};

export type MappedNavList = {
  label: string;
  children: MappedNavChild[];
}[];

function getNavList(counts: ClientCommunicationRes | undefined): MappedNavList {
  return [
    {
      label: 'analytics',
      children: [
        {
          access: PERMISSIONS_MAP.analytics.access,
          label: 'overview',
          path: '/',
          icon: <OverviewIcon />,
          end: true
        }
      ]
    },
    {
      label: 'sale',
      children: [
        {
          access: PERMISSIONS_MAP.carSale.access,
          label: 'carShort',
          path: CARS_ADVERTS_URL,
          icon: <CarIcon />,
          end: true
        },
        {
          access: PERMISSIONS_MAP.plateSale.access,
          label: 'numbers',
          path: VRP_ADVERTS_URL,
          icon: <PlateIcon />,
          end: true
        }
      ]
    },
    {
      label: 'rent',
      children: [
        {
          access: PERMISSIONS_MAP.rent.ads.access,
          label: 'carsRent',
          path: RENT_ADVERTS_URL,
          icon: <CarIcon />,
          end: true
        },
        {
          access: PERMISSIONS_MAP.rent.book.access,
          label: 'booking.titleMany',
          path: '/booking/rent',
          icon: <CalendarIcon />,
          count: counts?.count_unread_bookings || undefined,
          end: true
        }
      ]
    },
    {
      label: 'contactWithClients',
      children: [
        {
          access: PERMISSIONS_MAP.chat.access,
          label: 'chat.one',
          path: '/chat',
          icon: <ChatIcon />,
          count: counts?.count_unread_chats || undefined,
          end: true
        },
        {
          access: PERMISSIONS_MAP.callback.access,
          label: 'callback.title',
          path: '/callback',
          icon: <CallIcon />,
          count: counts?.count_unread_callbacks || undefined,
          end: true
        },
        {
          access: PERMISSIONS_MAP.credit.access,
          label: 'credits.req.title',
          path: '/credits/requests',
          icon: <CreditReqIcon />,
          count: counts?.count_unread_request_credit || undefined,
          end: true
        },
        {
          access: PERMISSIONS_MAP.leads.access,
          label: 'leads.title',
          path: '/leads',
          icon: <LeadsIcon />,
          count: counts?.count_unread_leads || undefined,
          end: true
        }
      ]
    },
    {
      label: 'tariffsAndPayment',
      children: [
        {
          access: PERMISSIONS_MAP.wallet.access,
          label: 'wallet.title',
          path: '/payment/wallet',
          icon: <WalletNumbersIcon />,
          end: true
        },
        {
          access: PERMISSIONS_MAP.tariffs.access,
          label: 'tariffs.title',
          path: '/payment/tariffs',
          icon: <TariffsIcon />,
          end: true
        }
      ]
    },
    {
      label: 'showroom.label',
      children: [
        {
          access: PERMISSIONS_MAP.showroom.info.access,
          label: 'general',
          path: '/showroom/general',
          icon: <InfoIcon />,
          end: true
        },
        {
          access: PERMISSIONS_MAP.showroom.addresses.access,
          label: 'addressesAndBranches',
          path: '/showroom/address',
          icon: <AddressIcon />,
          end: true
        },
        {
          access: PERMISSIONS_MAP.showroom.contacts.access,
          label: 'contacts.title',
          path: '/showroom/contacts',
          icon: <ContactIcon />,
          end: false
        },
        {
          access: PERMISSIONS_MAP.roles.access,
          label: 'roles.pageTitle',
          path: '/showroom/roles',
          icon: <RolesIcon />,
          end: true
        },
        {
          access: PERMISSIONS_MAP.showroom.notifications.access,
          label: 'notifications',
          path: '/showroom/notifications',
          icon: <NotifyIcon />
        },
        {
          access: PERMISSIONS_MAP.reviews.access,
          label: 'ratingsAndReviews',
          path: '/showroom/reviews',
          icon: <ReviewIcon />,
          end: true
        }
      ]
    }
  ];
}

function getFilteredNavList(employeeInfo: Nullable<EmployeeType>) {
  const accessIds = employeeInfo?.permissions.reduce((acc, cur) => {
    return [...acc, ...cur.options.map((opt) => opt.id)];
  }, [] as number[]);

  const navList = getNavList(undefined);

  const filteredNavList = employeeInfo
    ? navList
        .reduce((acc, nav) => {
          const children = accessIds
            ? nav.children.filter((c) => accessIds.includes(c.access))
            : nav.children;

          return [...acc, { ...nav, children }];
        }, [] as MappedNavList)
        .filter((nav) => nav.children.length > 0)
    : navList;

  return filteredNavList;
}

export function useNavList(clientComm: ClientCommunicationRes | undefined) {
  const auth = useAuthStore((s) => s.auth);
  const isOwner = !!auth?.is_owner;
  const employeeInfo = useAccountStore((s) => s.employeeInfo);
  const accessIds = useMemo(
    () =>
      employeeInfo?.permissions.reduce((acc, cur) => {
        return [...acc, ...cur.options.map((opt) => opt.id)];
      }, [] as number[]),
    [employeeInfo?.permissions]
  );

  const navList = useMemo(() => getNavList(clientComm), [clientComm]);

  const filteredNavList = useMemo(() => {
    if (isOwner) return navList;

    if (!accessIds) return [];

    return navList
      .reduce((acc, nav) => {
        const children = accessIds
          ? nav.children.filter((c) => accessIds.includes(c.access))
          : nav.children;

        return [...acc, { ...nav, children }];
      }, [] as MappedNavList)
      .filter((nav) => nav.children.length > 0);
  }, [accessIds, isOwner, navList]);

  return filteredNavList;
}

export function getAvailablePathByRole(employeeInfo: Nullable<EmployeeType>) {
  const navList = getFilteredNavList(employeeInfo);
  const navWithChild = navList.find((v) => v.children.length > 0);
  return navWithChild?.children[0]?.path || '/';
}

export function useAvailablePathByRole() {
  const employeeInfo = useAccountStore((s) => s.employeeInfo);
  return employeeInfo ? getAvailablePathByRole(employeeInfo) : '/';
}
