import cn from 'classnames';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Nullable } from '@/app/types';
import { useDefaultPeriod } from '@/hooks/useDefaultPeriod';
import { useLang } from '@/hooks/useLang';
import { DropdownRangePicker } from '@/ui/DropdownRangePicker/DropdownRangePicker';
import { CalendarIcon } from '@/ui/Filters/icons';
import { getRangeDatesString } from '@/utils/date';

import cls from './Filters.module.scss';

type Props = {
  from: Nullable<Date>;
  to: Nullable<Date>;
  onDatesChange: (from: Date, to: Date) => void;
};

export const DatesFilter: React.FC<Props> = ({ from, to, onDatesChange }) => {
  const { t } = useTranslation();
  const [lang] = useLang();
  const defaultPeriod = useDefaultPeriod();

  const [isDatesOpen, setDatesOpen] = useState(false);

  const [fromState, setFrom] = useState<Nullable<Date>>(from);
  const [toState, setTo] = useState<Nullable<Date>>(to);
  const dateValue = getRangeDatesString(from, to, lang);

  const handleDatesChange = (start: Nullable<Date>, end: Nullable<Date>) => {
    setFrom(start as Date);
    setTo(end as Date);

    if (start && end) {
      onDatesChange(start, end);
    }
  };

  useEffect(() => {
    if (!from && !to) {
      setFrom(defaultPeriod.from);
      setTo(defaultPeriod.to);
    }
  }, [defaultPeriod, from, to]);

  return (
    <div className={cls.filter}>
      <button
        onClick={() => setDatesOpen((p) => !p)}
        className={cn(cls.btn, {
          [cls.btn_open]: isDatesOpen,
          [cls.btn_selected]: dateValue
        })}
      >
        <CalendarIcon />
        <span>{dateValue ? dateValue : t('filters.date')}</span>
      </button>

      <DropdownRangePicker
        isOpen={isDatesOpen}
        close={() => setDatesOpen(false)}
        parentCls={cls.filter}
        from={fromState}
        to={toState}
        onDatesChange={handleDatesChange}
      />
    </div>
  );
};
