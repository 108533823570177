export function ArrowIcon() {
  return (
    <svg
      width="13"
      height="13"
      viewBox="0 0 13 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.5233 6.54713C10.5233 6.43014 10.4765 6.32251 10.3829 6.2336L7.28029 3.14039C7.17734 3.03744 7.07907 3 6.96678 3C6.73745 3 6.55963 3.16847 6.55963 3.40244C6.55963 3.51475 6.59708 3.62238 6.67196 3.69726L7.72019 4.76421L9.57797 6.45822L9.67157 6.22424L8.16476 6.13065H2.24506C2.00172 6.13065 1.83325 6.30379 1.83325 6.54713C1.83325 6.79047 2.00172 6.96362 2.24506 6.96362H8.16476L9.67157 6.87003L9.57797 6.64073L7.72019 8.33008L6.67196 9.39699C6.59708 9.4672 6.55963 9.57952 6.55963 9.69181C6.55963 9.92582 6.73745 10.0943 6.96678 10.0943C7.07907 10.0943 7.17734 10.0522 7.27095 9.96324L10.3829 6.86067C10.4765 6.77175 10.5233 6.66412 10.5233 6.54713Z"
        fill="currentColor"
      />
    </svg>
  );
}
