import { onlyNumbers } from '@/utils/format';

export const makeWhatsAppUrl = (phone: string) => {
  const formattedPhone = onlyNumbers(phone);
  return `https://api.whatsapp.com/send?phone=${formattedPhone}`;
};

export const useWhatsAppUrl = (phone: string) => {
  if (!phone) return '';
  return makeWhatsAppUrl(phone);
};
