import Tooltip from 'rc-tooltip';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useImgPlaceholder } from '@/hooks/useImgPlaceholder';
import { useLang } from '@/hooks/useLang';
import { useWhatsAppUrl } from '@/hooks/useWhatsAppUrl';
import {
  MailIcon,
  PhoneIcon
} from '@/modules/credit/CreditRequests/CreditRequest/icons';
import { markLeadsAsViewed } from '@/modules/leads/api';
import {
  ChatIcon,
  WhatsAppIcon
} from '@/modules/leads/LeadsPage/Leads/LeadRow/icons';
import { Lead, LeadType } from '@/modules/leads/types';
import { useAdvertName } from '@/modules/showroom/advert/hooks';
import { AdvertStatusType } from '@/modules/showroom/advert/types';
import { Avatar } from '@/ui/Avatar/Avatar';
import { ButtonLink } from '@/ui/Button/ButtonLink';
import { ImgSkeleton } from '@/ui/ImgSkeleton/ImgSkeleton';
import {
  ADV_CUR,
  APP_URL,
  HIDDEN_CAR_IMG_PATH,
  rcTooltipProps
} from '@/utils/consts';
import { isThisYear, isToday, isYesterday } from '@/utils/date';
import { numberWithSpaces } from '@/utils/format';
import { showAlert } from '@/utils/network';

import { ArrowIcon } from './icons';
import cls from './LeadRowMob.module.scss';

type Props = {
  lead: Lead;
};

export function LeadRowMob({ lead }: Props) {
  const { t } = useTranslation();
  const [lang] = useLang();

  const ad = lead.ad;
  const customer = lead.customer;
  const contact = lead.showroom_contact;
  const email = customer?.email;

  const createdStr = useMemo(() => {
    const createdDate = new Date(lead.created_at * 1000);
    const today = isToday(createdDate);
    const yesterday = isYesterday(createdDate);
    const thisYear = isThisYear(createdDate);

    const dateStr = createdDate.toLocaleDateString(lang, {
      day: '2-digit',
      month: '2-digit',
      year: thisYear ? undefined : '2-digit',
      hour: '2-digit',
      minute: '2-digit'
    });

    if (today || yesterday) {
      const str = today ? t('common.today') : t('common.yesterday');
      return `${str}, ${dateStr}`;
    }

    return dateStr;
  }, [lead.created_at, lang, t]);

  const advertUrl = ad?.url ? `${APP_URL}/${ad.url}` : '';
  const adName = useAdvertName(ad);
  const phone = customer?.phone_number || '';
  const whatsAppUrl = useWhatsAppUrl(phone);

  const hasActions = !!email || !!phone || !!whatsAppUrl;

  const price = useMemo(
    () =>
      t('priceCurrency', {
        price: numberWithSpaces(ad?.price || 0, lang),
        currency: ADV_CUR
      }),
    [ad, lang, t]
  );

  const imgPlaceholder = useImgPlaceholder();
  const adImage = useMemo(() => {
    if (ad.vrp_image_url) return ad.vrp_image_url;

    if (ad.photos && ad.photos[0]) {
      const ph = ad.photos[0];
      return ph.thumbnail_url || ph.photo_url || imgPlaceholder;
    }
  }, [ad, imgPlaceholder]);
  const [imgSrc, setImgSrc] = useState(adImage);

  useEffect(() => {
    setImgSrc(adImage);
  }, [adImage]);

  // View badge
  const [viewed, setViewed] = useState(!!lead.has_been_viewed);
  const [markLoading, setMarkLoading] = useState(false);
  const onHover = async () => {
    if (markLoading || viewed) return;
    setMarkLoading(true);

    try {
      await markLeadsAsViewed({ ids: [lead.id] });
      setViewed(true);
    } catch (error) {
      showAlert({ error });
    } finally {
      setMarkLoading(false);
    }
  };
  // Lead
  const leadTypeIcon = useMemo<Record<LeadType, JSX.Element>>(() => {
    return {
      [LeadType.phone]: <PhoneIcon />,
      [LeadType.chat]: <ChatIcon />,
      [LeadType.whatsapp]: <WhatsAppIcon />
    };
  }, []);

  const leadTypeLabel = useMemo<Record<LeadType, string>>(() => {
    return {
      [LeadType.phone]: t('phone'),
      [LeadType.chat]: t('chat.one'),
      [LeadType.whatsapp]: t('contacts.whatsapp.title')
    };
  }, [t]);

  const leadTooltipLabel = useMemo(() => {
    const customerName = customer?.name || t('leads.anon');
    const contactName = contact?.name
      ? t('leads.to', { contact: contact.name })
      : '';

    return {
      [LeadType.phone]: t('leads.tooltip.phone', {
        contact: contactName,
        customer: customerName
      }),
      [LeadType.chat]: t('leads.tooltip.chat', {
        contact: contactName,
        customer: customerName
      }),
      [LeadType.whatsapp]: t('leads.tooltip.whatsapp', {
        contact: contactName,
        customer: customerName
      })
    };
  }, [contact, customer, t]);

  return (
    <div className={cls.root} onPointerDown={onHover}>
      <div className={cls.row}>
        <div className={cls.img}>
          <ImgSkeleton
            key={imgSrc}
            className={cls.img_inner}
            src={imgSrc}
            alt=""
            imgProxyWidth={85}
            imgProxyHeight={64}
            onError={() => setImgSrc(HIDDEN_CAR_IMG_PATH)}
            style={{
              objectFit: imgSrc === HIDDEN_CAR_IMG_PATH ? 'contain' : 'cover'
            }}
          />
          {advertUrl && (
            <a
              className={cls.link}
              href={advertUrl}
              target="_blank"
              rel="noreferrer"
            />
          )}
        </div>

        <div className={cls.info}>
          <div className={cls.header}>
            <p className={cls.name}>{adName}</p>

            <div className={cls.status_wrap}>
              {ad.status?.title !== AdvertStatusType.published && (
                <span className={cls.status}>{ad.status?.name}</span>
              )}
              {!viewed && <span className={cls.view_badge} />}
            </div>
          </div>
          <p className={cls.price}>{price}</p>

          <div className={cls.lead_row}>
            <Tooltip
              placement="top"
              overlay={leadTooltipLabel[lead.lead_type]}
              {...rcTooltipProps}
            >
              <div className={cls.lead_type}>
                {leadTypeIcon[lead.lead_type]}
                {leadTypeLabel[lead.lead_type]}
              </div>
            </Tooltip>
            {contact && (
              <>
                <ArrowIcon />
                <p className={cls.lead_contact}>{contact.name}</p>
              </>
            )}
          </div>

          {advertUrl && (
            <a
              className={cls.link}
              href={advertUrl}
              target="_blank"
              rel="noreferrer"
            />
          )}
        </div>
      </div>

      <div className={cls.footer}>
        {customer ? (
          <Avatar
            size={36}
            src={
              customer?.avatar?.thumbnail_url ||
              customer?.avatar?.photo_url ||
              ''
            }
            user={{ id: customer.id, name: customer.name || '' }}
          />
        ) : (
          <Avatar size={36} user={{ id: lead.id, name: t('leads.anon') }} />
        )}
        <div>
          {customer?.name ? (
            <p
              className={cls.customer_name}
              style={{ maxWidth: hasActions ? undefined : 200 }}
            >
              {customer.name}
            </p>
          ) : (
            <p
              className={cls.customer_name}
              style={{ maxWidth: hasActions ? undefined : 200 }}
            >
              {t('leads.anon')}
            </p>
          )}
          <p className={cls.date}>{createdStr}</p>
        </div>

        {hasActions && (
          <div className={cls.actions}>
            {!!email && (
              <ButtonLink
                externalLink
                to={`mailto:${email}`}
                variant="outline"
                color="black"
                size="compact"
              >
                <MailIcon />
              </ButtonLink>
            )}

            {whatsAppUrl && (
              <ButtonLink
                externalLink
                to={whatsAppUrl}
                variant="outline"
                color="black"
                size="compact"
              >
                <WhatsAppIcon />
              </ButtonLink>
            )}

            {phone && (
              <div className={cls.phone}>
                <ButtonLink
                  externalLink
                  to={`tel:${phone}`}
                  variant="outline"
                  color="black"
                  size="compact"
                  gap={4}
                >
                  <PhoneIcon />
                  <span>{t('common.call')}</span>
                </ButtonLink>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
}
