import { Collapse } from 'react-collapse';

import { Switch } from '@/ui/Switch/Switch';

import cls from './ChatSetting.module.scss';

type Props = {
  enabled: boolean;
  disabled?: boolean;
  title: string;
  subtitle: string;
  icon: JSX.Element;
  children: React.ReactNode;
  toggle: VoidFunction;
};

export function ChatSetting({
  enabled,
  disabled,
  title,
  subtitle,
  icon,
  children,
  toggle
}: Props) {
  return (
    <div className={cls.root}>
      <div className={cls.header}>
        {icon}
        <div className={cls.text}>
          <p className={cls.title}>{title}</p>
          <p className={cls.subtitle}>{subtitle}</p>
        </div>
        <Switch
          id={title}
          checked={enabled}
          disabled={disabled}
          onChange={toggle}
        />
      </div>

      <Collapse isOpened={enabled}>
        <div>
          <div className={cls.hr} />
          {children}
        </div>
      </Collapse>
    </div>
  );
}
