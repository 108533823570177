'use client';

import { useNavigate } from 'react-router-dom';

import { useAccountStore } from '@/modules/accounts/store';
import { useAuthStore } from '@/modules/auth/store';
import { LoginAccountRes } from '@/modules/auth/types';
import { getMeAsEmployee } from '@/modules/roles/api';
import { getAvailablePathByRole } from '@/ui/layouts/SidebarLayout/Sidebar/Nav/helpers';

import { Signin } from './Signin';

export function SigninPage() {
  const navigate = useNavigate();
  const setAuth = useAuthStore((s) => s.setAuth);
  const setAccountInfo = useAccountStore((s) => s.setAccountInfo);
  const setEmployeeInfo = useAccountStore((s) => s.setEmployeeInfo);

  const onSubmit = (response: LoginAccountRes) => {
    setAuth(response);
    setAccountInfo(response);

    if (response.is_owner) {
      navigate(response.showroom_created ? '/' : '/showroom/create');
    } else {
      getMeAsEmployee()
        .then(({ data }) => {
          setEmployeeInfo(data);
          navigate(getAvailablePathByRole(data));
        })
        .catch(() => {
          navigate('/');
        });
    }
  };

  return (
    <div className="box">
      <Signin onSubmit={onSubmit} />
    </div>
  );
}
