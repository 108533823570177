import { Skeleton } from '@/ui/Skeleton';

import cls from './LeadRowMob.module.scss';

const blockStyle = { display: 'block' };

export function LeadRowMobSkeleton() {
  return (
    <div className={cls.root}>
      <div className={cls.row}>
        <Skeleton style={blockStyle} width={85} height={64} borderRadius={8} />

        <div className={cls.info}>
          <Skeleton style={blockStyle} width={120} height={20} />
          <Skeleton style={blockStyle} width={88} height={20} />
          <Skeleton
            width={132}
            height={17}
            style={{ ...blockStyle, marginTop: 8 }}
          />
        </div>
      </div>

      <div className={cls.footer}>
        <Skeleton style={blockStyle} width={36} height={36} borderRadius={18} />
        <div>
          <Skeleton style={blockStyle} width={120} height={20} />
          <Skeleton style={blockStyle} width={96} height={16} />
        </div>
      </div>
    </div>
  );
}
