import { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { useInterval } from '@/hooks/useInterval';
import { useRefState } from '@/hooks/useRefState';
import { confirmEmail, confirmEmailSendCode } from '@/modules/auth/api';
import { CODE_LEN, COUNTER } from '@/modules/auth/helpers';
import { ConfirmEmailRes } from '@/modules/auth/types';
import { Button } from '@/ui/Button/Button';
import { SplittedInput } from '@/ui/Input/SplittedInput';
import { Lottie } from '@/ui/Lottie';
import { showAlert } from '@/utils/network';

import cls from '../Signin/Signin.module.scss';

type Props = {
  email: string;
  onSubmit: (response: ConfirmEmailRes) => void;
};

export function ConfirmEmailCode({ onSubmit, email }: Props) {
  const { t } = useTranslation();

  const [isLoading, setLoading] = useState(false);
  const [code, setCode] = useState('');

  // Counter
  const [counter, setCounter, counterRef] = useRefState<number>(COUNTER);
  useInterval(
    () => {
      const v = counterRef.current;
      if (v > 0) setCounter(v - 1);
    },
    counter > 0 ? 1000 : null
  );

  const onResendClick = async () => {
    setLoading(true);

    try {
      await confirmEmailSendCode({ email });
      setCounter(COUNTER);
    } catch (error) {
      showAlert({ error });
    } finally {
      setLoading(false);
    }
  };

  // Submit
  const isValid = !!email && code.length === CODE_LEN;
  const submit = async (codeValue: string) => {
    if (!codeValue || !email || isLoading) return;
    setLoading(true);

    try {
      const r = await confirmEmail({
        code: codeValue,
        email
      });
      onSubmit(r.data);
    } catch (error) {
      showAlert({ error });
    } finally {
      setLoading(false);
    }
  };

  const onKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    const isOnlyEnter = e.key === 'Enter' && !e.shiftKey;
    if (isValid && !isLoading && isOnlyEnter) {
      submit(code);
    }
  };

  const handleCodeChange = (v: string) => {
    setCode(v);

    const isValid = !!email && v.length === CODE_LEN;
    if (isValid && !isLoading) {
      submit(v);
    }
  };

  return (
    <>
      <div className={cls.icon}>
        <Lottie src="mail" />
      </div>

      <h1 className={cls.title}>{t('auth.enterCode')}</h1>

      <h2 className={cls.code_title}>
        <Trans
          t={t}
          i18nKey="auth.emailConfirm"
          components={{ emailWrap: <b /> }}
          values={{ email }}
        />
      </h2>

      <div className={cls.field}>
        <SplittedInput
          value={code}
          onChange={handleCodeChange}
          length={CODE_LEN}
          onLastKeyDown={onKeyDown}
          disabled={isLoading}
          numbersOnly
        />
      </div>

      <div className={cls.resend}>
        {counter > 0 ? (
          <p>
            {t('auth.resendCode', {
              seconds: counter
            })}
          </p>
        ) : (
          <button
            className={cls.resend_btn}
            type="button"
            onClick={onResendClick}
            disabled={!email || isLoading}
          >
            {t('auth.resendAgain')}
          </button>
        )}
      </div>

      <Button
        onClick={() => submit(code)}
        loading={isLoading}
        disabled={!isValid || isLoading}
        fullWidth
      >
        {t('common.continue')}
      </Button>
    </>
  );
}
