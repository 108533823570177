import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { loginAccount } from '@/modules/auth/api';
import { LoginAccountRes } from '@/modules/auth/types';
import { Button } from '@/ui/Button/Button';
import { ButtonLink } from '@/ui/Button/ButtonLink';
import { Input } from '@/ui/Input/Input';
import { Lottie } from '@/ui/Lottie';
import { MIN_PASSWORD_LENGTH } from '@/utils/consts';
import { showAlert } from '@/utils/network';
import { MAX_EMAIL_LENGTH, validateEmail } from '@/utils/validate';

import cls from './Signin.module.scss';

type Props = {
  onSubmit: (response: LoginAccountRes) => void;
};

export function Signin({ onSubmit }: Props) {
  const { t } = useTranslation();

  // Fields
  const [emailTouched, setEmailTouched] = useState<boolean>(false);
  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');

  // Submit
  const isEmailValid = email ? validateEmail(email) : true;
  const isValid =
    isEmailValid && !!password && password.length >= MIN_PASSWORD_LENGTH;

  const [loading, setLoading] = useState(false);

  const submit = async () => {
    if (!isValid) return;

    setLoading(true);

    try {
      const r = await loginAccount({ email, password });
      onSubmit(r.data);
    } catch (error) {
      showAlert({ error });
    } finally {
      setLoading(false);
    }
  };

  const onKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (isValid && e.key === 'Enter') {
      submit();
    }
  };

  return (
    <>
      <div className={cls.icon}>
        <Lottie src="signin" />
      </div>
      <h1 className={cls.title}>{t('sellersCabinet')}</h1>
      <p className={cls.subtitle}>{t('auth.signinText')}</p>

      <div className={cls.field_email}>
        <Input
          value={email}
          onChange={(e) => setEmail(e.currentTarget.value.trim())}
          label={t('auth.email')}
          disabled={loading}
          onBlur={() => setEmailTouched(true)}
          errorText={
            !isEmailValid && emailTouched ? t('auth.invalidEmail') : ''
          }
          maxLength={MAX_EMAIL_LENGTH}
        />
      </div>

      <div className={cls.field_password}>
        <Input
          value={password}
          onChange={(e) => setPassword(e.currentTarget.value.trim())}
          label={t('auth.password')}
          onKeyDown={onKeyDown}
          disabled={loading}
          type="password"
        />
        <Link className={cls.restore_password} to="/password/restore">
          {t('auth.restore.cta')}
        </Link>
      </div>

      <div className={cls.buttons}>
        <Button
          onClick={submit}
          loading={loading}
          disabled={loading || !isValid}
          fullWidth
        >
          {t('auth.signin')}
        </Button>

        <ButtonLink to="/signup" color="black" variant="secondary" fullWidth>
          {t('auth.signup')}
        </ButtonLink>
      </div>
    </>
  );
}
