import { Skeleton } from '@/ui/Skeleton';

import cls from './ExpensesDefault.module.scss';

const skeletons = new Array(6).fill(0);

export function ExpensesDefaultSkeleton() {
  return (
    <div className={cls.root}>
      <div className={cls.date_row}>
        <Skeleton width={300} height={24} style={{ marginLeft: 16 }} />

        {skeletons.map((_, i) => (
          <div key={i} className={cls.adverts}>
            <div className={cls.expense_row}>
              <div className={cls.expense_header}>
                <div className={cls.expense_type}>
                  <Skeleton width={20} height={20} />
                  <Skeleton width={100} height={20} />
                  <Skeleton width={130} height={20} />
                </div>

                <p className={cls.expense_cost}>
                  <Skeleton width={50} height={20} />
                </p>
              </div>

              <div className={cls.expense_advert}>
                <Skeleton width={72} height={54} />

                <div className={cls.advert_info}>
                  <p className={cls.advert_name}>
                    <Skeleton width={260} height={24} />
                  </p>
                  <p className={cls.advert_price}>
                    <Skeleton width={260} height={20} />
                  </p>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
