import { useMemo } from 'react';

import {
  Specs,
  Props as SpecProps
} from '@/modules/showroom/advert/create/Specs/Specs';
import { SpecsSlice } from '@/modules/showroom/advert/create/types';
import { useRentAdvertUpdateFlags } from '@/modules/showroom/advert/rent/update/hooks';
import { useRentUpdateAdvertStore } from '@/modules/showroom/advert/rent/update/store';

type Props = Omit<SpecProps, 'store' | 'disableSpecs' | 'type'>;

export function SpecsWrap(props: Props) {
  const { disableCoreChanges } = useRentAdvertUpdateFlags();
  const year = useRentUpdateAdvertStore((s) => s.year);
  const setYear = useRentUpdateAdvertStore((s) => s.setYear);
  const generation = useRentUpdateAdvertStore((s) => s.generation);
  const setGeneration = useRentUpdateAdvertStore((s) => s.setGeneration);
  const body = useRentUpdateAdvertStore((s) => s.body);
  const setBody = useRentUpdateAdvertStore((s) => s.setBody);
  const engine = useRentUpdateAdvertStore((s) => s.engine);
  const setEngine = useRentUpdateAdvertStore((s) => s.setEngine);
  const drive = useRentUpdateAdvertStore((s) => s.drive);
  const setDrive = useRentUpdateAdvertStore((s) => s.setDrive);
  const transmission = useRentUpdateAdvertStore((s) => s.transmission);
  const setTransmission = useRentUpdateAdvertStore((s) => s.setTransmission);
  const modification = useRentUpdateAdvertStore((s) => s.modification);
  const setModification = useRentUpdateAdvertStore((s) => s.setModification);
  const bodyColor = useRentUpdateAdvertStore((s) => s.bodyColor);
  const setBodyColor = useRentUpdateAdvertStore((s) => s.setBodyColor);
  const interiorColor = useRentUpdateAdvertStore((s) => s.interiorColor);
  const setInteriorColor = useRentUpdateAdvertStore((s) => s.setInteriorColor);
  const isSpecsFilled = useRentUpdateAdvertStore((s) => s.isSpecsFilled);
  const setSpecsFilled = useRentUpdateAdvertStore((s) => s.setSpecsFilled);
  const step = useRentUpdateAdvertStore((s) => s.step);
  const setStep = useRentUpdateAdvertStore((s) => s.setStep);

  const slice = useMemo<
    Omit<
      SpecsSlice,
      'mileage' | 'setMileage' | 'isMileageFilled' | 'setMileageFilled'
    >
  >(
    () => ({
      year,
      setYear,
      generation,
      setGeneration,
      body,
      setBody,
      engine,
      setEngine,
      drive,
      setDrive,
      transmission,
      setTransmission,
      modification,
      setModification,
      color: bodyColor,
      setColor: setBodyColor,
      interiorColor,
      setInteriorColor,
      isSpecsFilled,
      setSpecsFilled,
      step,
      setStep
    }),
    [
      body,
      bodyColor,
      drive,
      engine,
      generation,
      interiorColor,
      isSpecsFilled,
      modification,
      setBody,
      setBodyColor,
      setDrive,
      setEngine,
      setGeneration,
      setInteriorColor,
      setModification,
      setSpecsFilled,
      setStep,
      setTransmission,
      setYear,
      step,
      transmission,
      year
    ]
  );

  return <Specs store={slice} disableSpecs={disableCoreChanges} {...props} />;
}
