import cn from 'classnames';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { OptionI } from '@/app/types';
import { UseMyRoles } from '@/modules/roles/hooks';
import { Input } from '@/ui/Input/Input';
import { Select } from '@/ui/Select/Select';

import { AddIcon, CheckIcon } from './icons';
import cls from './InviteEmployee.module.scss';

type Props = {
  id: string;
  email: string;
  roleId: number;
  onChange: (id: string, v: { email: string; role_id: number }) => void;
  roles: UseMyRoles;
  onNewRoleClick: VoidFunction;
  disabled: boolean;
};

export function InviteRow({
  id,
  email,
  roleId,
  onChange,
  roles: rolesData,
  onNewRoleClick,
  disabled
}: Props) {
  const { t } = useTranslation();

  const createOption = useMemo<OptionI>(
    () => ({ id: 'create', text: t('roles.newRole') }),
    [t]
  );

  const [roles] = rolesData;
  const options = useMemo<OptionI[]>(
    () => [
      ...(roles?.map((r) => ({
        id: String(r.id),
        text: r.name
      })) || []),
      createOption
    ],
    [createOption, roles]
  );

  const option = options.find((opt) => opt.id === String(roleId));

  const selectOptionRender = useCallback(
    (opt: OptionI, closeDropdown: () => void) => {
      const isActive = option?.id === opt.id;
      const isCreate = opt.id === 'create';

      return (
        <div
          className={cn(cls.select_btn, {
            [cls.select_btn_active]: isActive
          })}
          style={{
            background: isActive ? '0' : undefined
          }}
        >
          <button
            className={cls.select_btn_inner}
            type="button"
            onClick={() => {
              closeDropdown();
              if (isCreate) {
                onNewRoleClick();
              } else {
                onChange(id, { role_id: Number(opt.id), email });
              }
            }}
          />

          <div className={cls.select_btn_icon}>
            {isCreate ? <AddIcon /> : <>{isActive && <CheckIcon />}</>}
          </div>
          <span
            className={cls.select_btn_text}
            style={{ color: isCreate ? 'var(--clr-blue)' : undefined }}
          >
            <span>{opt.text}</span>
          </span>
        </div>
      );
    },
    [email, id, onChange, onNewRoleClick, option?.id]
  );

  return (
    <div className={cls.row}>
      <Input
        value={email}
        onChange={(e) =>
          onChange(id, { role_id: roleId, email: e.currentTarget.value })
        }
        type="email"
        label={t('roles.invite.email')}
        disabled={disabled}
      />

      <Select
        name={`invite-role-${id}`}
        label={t('roles.role')}
        value={option}
        onChange={(e) => onChange(id, { role_id: Number(e.id), email })}
        options={options}
        renderOption={selectOptionRender}
        disabled={disabled}
      />
    </div>
  );
}
