export function ChevronIcon() {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.0626 7.99957C11.0603 7.79469 10.9838 7.61816 10.8217 7.46199L6.12896 2.86461C5.99231 2.7329 5.8294 2.66675 5.63262 2.66675C5.24509 2.66675 4.93359 2.97113 4.93359 3.36252C4.93359 3.55327 5.0112 3.72981 5.14555 3.86693L9.38216 7.99844L5.14555 12.1322C5.01228 12.2682 4.93359 12.4409 4.93359 12.6377C4.93359 13.028 5.24509 13.3334 5.63262 13.3334C5.82723 13.3334 5.99231 13.2673 6.12896 13.1306L10.8217 8.5371C10.9849 8.37597 11.0626 8.20332 11.0626 7.99957Z"
        fill="currentColor"
      />
    </svg>
  );
}
