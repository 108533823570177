import { useMemo } from 'react';

import {
  Specs,
  Props as SpecProps
} from '@/modules/showroom/advert/create/Specs/Specs';
import { SpecsSlice } from '@/modules/showroom/advert/create/types';
import { useAdvertUpdateFlags } from '@/modules/showroom/advert/update/hooks';
import { useUpdateAdvertStore } from '@/modules/showroom/advert/update/store';

type Props = Omit<SpecProps, 'store' | 'disableSpecs' | 'type'>;

export function SpecsWrap(props: Props) {
  const { disableCoreChanges } = useAdvertUpdateFlags();
  const year = useUpdateAdvertStore((s) => s.year);
  const setYear = useUpdateAdvertStore((s) => s.setYear);
  const generation = useUpdateAdvertStore((s) => s.generation);
  const setGeneration = useUpdateAdvertStore((s) => s.setGeneration);
  const body = useUpdateAdvertStore((s) => s.body);
  const setBody = useUpdateAdvertStore((s) => s.setBody);
  const engine = useUpdateAdvertStore((s) => s.engine);
  const setEngine = useUpdateAdvertStore((s) => s.setEngine);
  const drive = useUpdateAdvertStore((s) => s.drive);
  const setDrive = useUpdateAdvertStore((s) => s.setDrive);
  const transmission = useUpdateAdvertStore((s) => s.transmission);
  const setTransmission = useUpdateAdvertStore((s) => s.setTransmission);
  const modification = useUpdateAdvertStore((s) => s.modification);
  const setModification = useUpdateAdvertStore((s) => s.setModification);
  const color = useUpdateAdvertStore((s) => s.color);
  const setColor = useUpdateAdvertStore((s) => s.setColor);
  const isSpecsFilled = useUpdateAdvertStore((s) => s.isSpecsFilled);
  const setSpecsFilled = useUpdateAdvertStore((s) => s.setSpecsFilled);
  const step = useUpdateAdvertStore((s) => s.step);
  const setStep = useUpdateAdvertStore((s) => s.setStep);

  const slice = useMemo<
    Omit<
      SpecsSlice,
      'mileage' | 'setMileage' | 'isMileageFilled' | 'setMileageFilled'
    >
  >(
    () => ({
      year,
      setYear,
      generation,
      setGeneration,
      body,
      setBody,
      engine,
      setEngine,
      drive,
      setDrive,
      transmission,
      setTransmission,
      modification,
      setModification,
      color,
      setColor,
      isSpecsFilled,
      setSpecsFilled,
      step,
      setStep
    }),
    [
      body,
      color,
      drive,
      engine,
      generation,
      isSpecsFilled,
      modification,
      setBody,
      setColor,
      setDrive,
      setEngine,
      setGeneration,
      setModification,
      setSpecsFilled,
      setStep,
      setTransmission,
      setYear,
      step,
      transmission,
      year
    ]
  );

  return <Specs store={slice} disableSpecs={disableCoreChanges} {...props} />;
}
