import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Nullable } from '@/app/types';
import { EmirateType } from '@/modules/emirate/types';
import { useVrpBaseInfo } from '@/modules/showroom/advert/vrp/api';
import { useVrpAdvertUpdateFlags } from '@/modules/showroom/advert/vrp/CreateVrp/hooks';
import { GetVrpBaseInfoReq } from '@/modules/showroom/advert/vrp/types';
import { PublishResultWrap } from '@/modules/showroom/advert/vrp/UpdateVrp/PublishResultWrap';
import { useUpdateVrpStore } from '@/modules/showroom/advert/vrp/UpdateVrp/store';

import cls from './StickyBar.module.scss';

type Props = {
  emirates: EmirateType[];
};

export function VrpStickyBar({ emirates }: Props) {
  const { t } = useTranslation();

  const isPriceFilled = useUpdateVrpStore((s) => s.isPriceFilled);
  const { showPromo: shouldShowPromo } = useVrpAdvertUpdateFlags();

  const previewUrl = useUpdateVrpStore((s) => s.previewUrl);
  const plateNumber = useUpdateVrpStore((s) => s.plateNumber);

  // Emirate
  const emirateId = useUpdateVrpStore((s) => s.emirateId);
  const emirate = emirates.find((em) => em.id === emirateId);

  // Type
  const typeId = useUpdateVrpStore((s) => s.typeId);
  const typesReq = useMemo<Nullable<GetVrpBaseInfoReq>>(
    () => (emirateId ? { emirate_id: emirateId } : null),
    [emirateId]
  );
  const [allTypes] = useVrpBaseInfo(typesReq);
  const type = allTypes?.find((tp) => tp.id === typeId);

  // Code
  const codeId = useUpdateVrpStore((s) => s.codeId);
  const code = type?.plate_codes.find((c) => c.id === codeId);

  const title = [code?.name, plateNumber].filter((v) => !!v).join(' ');

  const subtitle = [emirate?.name, type?.name]
    .filter((v) => !!v)
    .join(` ${t('common.dot')} `);

  return (
    <div className={cls.root}>
      <div className="container">
        <div className={cls.inner}>
          <div className={cls.row}>
            {previewUrl && (
              <div className={cls.img}>
                <img className={cls.img_vrp_inner} src={previewUrl} alt="" />
              </div>
            )}
            <div>
              {!!title.length && <p className={cls.name}>{title}</p>}
              {!!subtitle.length && <p className={cls.info}>{subtitle}</p>}
            </div>
          </div>

          <PublishResultWrap
            disablePublish={shouldShowPromo ? !isPriceFilled : false}
            hideDraft={!shouldShowPromo}
            buttonsOnly
          />
        </div>
      </div>
    </div>
  );
}
