import { Sidebar } from '@/ui/layouts/SidebarLayout/Sidebar/Sidebar';
import { AsideModal } from '@/ui/modals/AsideModal/AsideModal';

import cls from './Sidebar.module.scss';

type Props = {
  isOpen: boolean;
  close: VoidFunction;
};

export function SidebarNavModal({ isOpen, close }: Props) {
  return (
    <AsideModal
      isOpen={isOpen}
      close={close}
      name="sidebar-modal"
      containerClass={cls.modal_container}
      mobileBottomModal={false}
    >
      <Sidebar closeSidebarModal={close} />
    </AsideModal>
  );
}
