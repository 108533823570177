export function AddIcon() {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.33337 10.0001C3.33337 10.4894 3.73741 10.8884 4.22159 10.8884H9.11325V15.78C9.11325 16.2628 9.51075 16.6668 10 16.6668C10.4893 16.6668 10.8948 16.2628 10.8948 15.78V10.8884H15.7799C16.2626 10.8884 16.6667 10.4894 16.6667 10.0001C16.6667 9.51088 16.2626 9.1054 15.7799 9.1054H10.8948V4.22171C10.8948 3.73753 10.4893 3.3335 10 3.3335C9.51075 3.3335 9.11325 3.73753 9.11325 4.22171V9.1054H4.22159C3.73741 9.1054 3.33337 9.51088 3.33337 10.0001Z"
        fill="currentColor"
      />
    </svg>
  );
}

export function CheckIcon() {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.19071 17.5002C8.62826 17.5002 8.97342 17.3176 9.21323 16.9518L17.1225 4.62183C17.2997 4.34449 17.3708 4.11242 17.3708 3.88406C17.3708 3.30464 16.9633 2.90479 16.3779 2.90479C15.9625 2.90479 15.7214 3.04446 15.4694 3.43994L8.15705 15.057L4.38887 10.2119C4.14367 9.88716 3.88636 9.75116 3.52286 9.75116C2.92207 9.75116 2.5 10.1717 2.5 10.7526C2.5 11.0022 2.59323 11.2543 2.80309 11.5093L7.16264 16.9637C7.45294 17.3267 7.76679 17.5002 8.19071 17.5002Z"
        fill="currentColor"
      />
    </svg>
  );
}
