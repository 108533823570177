import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Nullable, OptionI } from '@/app/types';
import { useSavedPaymentMethods } from '@/modules/wallet/AutoReplenishment/hooks';
import { PaymentMethodSelectOption } from '@/modules/wallet/AutoReplenishment/SavedPaymentMethods/PaymentMethodSelectOption';
import { PaymentMethodSelectValue } from '@/modules/wallet/AutoReplenishment/SavedPaymentMethods/PaymentMethodSelectValue';
import { SavedPaymentMethod } from '@/modules/wallet/AutoReplenishment/types';
import { Select } from '@/ui/Select/Select';

type Props = {
  selectedPaymentMethodId: Nullable<SavedPaymentMethod['payment_method_id']>;
  onPaymentMethodIdChange: (
    paymentMethod: Nullable<SavedPaymentMethod['payment_method_id']>
  ) => void;
  disabled?: boolean;
};

export function SavedPaymentMethods({
  selectedPaymentMethodId,
  onPaymentMethodIdChange,
  disabled
}: Props) {
  const { t } = useTranslation();
  const [savedPaymentMethods, isPaymentMethodsLoading] =
    useSavedPaymentMethods();

  const paymentMethodsOptions = useMemo<OptionI[]>(
    () =>
      savedPaymentMethods.map((pm) => ({
        id: pm.payment_method_id,
        text: pm.last_four,
        extra: pm.brand
      })),
    [savedPaymentMethods]
  );

  const selectedPaymentMethodOption = useMemo(
    () =>
      paymentMethodsOptions.find((pmo) => pmo.id === selectedPaymentMethodId),
    [paymentMethodsOptions, selectedPaymentMethodId]
  );

  const handlePaymentMethodChange = (paymentMethodOption: OptionI) => {
    const newPaymentMethod = savedPaymentMethods.find(
      (pm) => pm.payment_method_id === paymentMethodOption.id
    );

    if (newPaymentMethod)
      onPaymentMethodIdChange(newPaymentMethod.payment_method_id);
  };

  return (
    <Select
      name="payment-method-select"
      value={selectedPaymentMethodOption}
      options={paymentMethodsOptions}
      onChange={handlePaymentMethodChange}
      disabled={isPaymentMethodsLoading || disabled}
      emptyOptionsLabel={t('paymentMethods.emptyCards') as string}
      renderValue={(value) => (
        <PaymentMethodSelectValue
          value={value}
          paymentMethods={savedPaymentMethods}
        />
      )}
      renderOption={(value, closeDropdown) => (
        <PaymentMethodSelectOption
          value={value}
          paymentMethods={savedPaymentMethods}
          onChange={handlePaymentMethodChange}
          closeDropdown={closeDropdown}
        />
      )}
    />
  );
}
