import cn from 'classnames';
import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useLang } from '@/hooks/useLang';
import { useAuthStore } from '@/modules/auth/store';
import { deleteReply } from '@/modules/reviews/api-req';
import { ReviewsContext } from '@/modules/reviews/reviews-ctx';
import { DeleteIcon } from '@/modules/reviews/ReviewsList/ReviewCard/icons';
import { ReviewText } from '@/modules/reviews/ReviewsList/ReviewCard/ReviewText';
import { MyReview, ReviewStatus } from '@/modules/reviews/types';
import { Spinner } from '@/ui/Spinner/Spinner';
import { formatDate } from '@/utils/date';
import { showAlert } from '@/utils/network';

import cls from './ReviewCard.module.scss';

type Props = {
  review: MyReview;
};

export function ReplyText({ review }: Props) {
  const { t } = useTranslation();
  const [lang] = useLang();

  const auth = useAuthStore((s) => s.auth);
  const isOwner = !!auth?.is_owner;

  const reply = review.comments.reviewed;
  const createdDate =
    reply && reply.created_at ? new Date(reply.created_at * 1000) : null;
  const createdDateStr = createdDate
    ? t('profile.reviews.replyByDate', {
        date: formatDate(createdDate, 'd MMMM', lang)
      })
    : '';

  const reviewStatusId = review.reply_status?.id;
  const showStatus =
    reviewStatusId === ReviewStatus.declined ||
    reviewStatusId === ReviewStatus.moderation;

  const [isReplyDeleting, setReplyDeleting] = useState(false);
  const { fetchReviews } = useContext(ReviewsContext);

  const onReplyDelete = async () => {
    if (!review?.id) return;
    setReplyDeleting(true);

    try {
      const params = { review_id: review.id };
      const res = await deleteReply(params);
      if (res.data.result) await fetchReviews(true);
    } catch (error) {
      showAlert({ error });
    } finally {
      setReplyDeleting(false);
    }
  };

  return (
    <>
      {reply?.comment && (
        <div className={cls.reply_text}>
          <div className={cls.reply_header}>
            {review.reply_status && showStatus && (
              <div
                className={cn(cls.status, {
                  [cls.status_moderation]:
                    reviewStatusId === ReviewStatus.moderation,
                  [cls.status_declined]:
                    reviewStatusId === ReviewStatus.declined
                })}
              >
                <p>
                  {reviewStatusId === ReviewStatus.declined
                    ? review.reply_moderation_rejected_reason
                    : review.reply_status.name}
                </p>
              </div>
            )}

            {!showStatus && <p className={cls.reply_date}>{createdDateStr}</p>}
          </div>
          <div className={cls.reply_content}>
            <div className={cn({ [cls.hidden_review]: showStatus })}>
              <ReviewText text={reply.comment} />
            </div>

            {isOwner && (
              <button className={cls.reply_delete} onClick={onReplyDelete}>
                {isReplyDeleting && <Spinner size={20} />}
                {!isReplyDeleting && <DeleteIcon />}
              </button>
            )}
          </div>
        </div>
      )}
    </>
  );
}
