import cn from 'classnames';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useCookieBool } from '@/hooks/useCookieBool';
import { useShowroomPayCounters } from '@/modules/showroom/api';
import { TopUpModal } from '@/modules/showroom/tariff/TopUpModal/TopUpModal';
import { pluralKey } from '@/utils/format';

import { ChevronIcon, CloseIcon } from './icons';
import cls from './TopupBanners.module.scss';

const COOKIE_AGE_DAYS = 1;

export function TopupBanners() {
  const { t } = useTranslation();
  const [isTopUpOpen, setTopUpOpen] = useState(false);

  const { unpaidAds, remainingDays } = useShowroomPayCounters();

  const [unpaidAdsWatched, setUnpaidAdsWatched] = useCookieBool(
    'BANNER_UNPAID_ADS',
    COOKIE_AGE_DAYS
  );
  const showUnpaidAds = unpaidAds > 0 && !unpaidAdsWatched;

  const [remainingDaysWatched, setRemainingDaysWatched] = useCookieBool(
    'BANNER_REMAINING_DAYS',
    COOKIE_AGE_DAYS
  );
  const showRemainingDays =
    remainingDays > 0 && remainingDays <= 7 && !remainingDaysWatched;

  return (
    <>
      <div className={cls.root}>
        {showUnpaidAds && (
          <div className={cn(cls.banner, cls.banner_black)}>
            <p>{t('hasUnpaidAds')}</p>
            <button
              className={cls.topup_btn}
              type="button"
              onClick={() => setTopUpOpen(true)}
            >
              {t('tariffs.topUp.action')}
              <ChevronIcon />
            </button>
            <button
              className={cls.close_btn}
              type="button"
              onClick={() => setUnpaidAdsWatched(true)}
            >
              {t('remindTomorrow')}
              <CloseIcon />
            </button>
          </div>
        )}

        {showRemainingDays && (
          <div className={cn(cls.banner, cls.banner_red)}>
            <p>
              {t('balanceRemainingDays', {
                days: pluralKey(
                  remainingDays,
                  t('days.plural.one', {
                    value: remainingDays
                  }),
                  t('days.plural.few', {
                    value: remainingDays
                  }),
                  t('days.plural.many', {
                    value: remainingDays
                  })
                )
              })}
            </p>
            <button
              className={cls.topup_btn}
              type="button"
              onClick={() => setTopUpOpen(true)}
            >
              {t('tariffs.topUp.action')}
              <ChevronIcon />
            </button>
            <button
              className={cls.close_btn}
              type="button"
              onClick={() => setRemainingDaysWatched(true)}
            >
              {t('remindTomorrow')}
              <CloseIcon />
            </button>
          </div>
        )}
      </div>

      <TopUpModal isOpen={isTopUpOpen} setOpen={setTopUpOpen} />
    </>
  );
}
