import { Skeleton } from '@/ui/Skeleton';

import cls from './CreditRequestMob.module.scss';

const blockStyle = { display: 'block' };

export function CreditRequestMobSkeleton() {
  return (
    <div className={cls.root}>
      <div className={cls.row}>
        <Skeleton style={blockStyle} width={85} height={64} borderRadius={8} />

        <div>
          <Skeleton
            style={{ ...blockStyle, marginBottom: 2 }}
            width={232}
            height={20}
          />
          <Skeleton
            style={{ ...blockStyle, marginBottom: 2 }}
            width={80}
            height={20}
          />
          <Skeleton style={blockStyle} width={104} height={20} />
        </div>
      </div>

      <div className={cls.loan}>
        <div className={cls.loan_row}>
          <div style={{ flex: 1 }}>
            <Skeleton style={blockStyle} width="100%" height={20} />
          </div>
          <div style={{ flex: 1 }}>
            <Skeleton style={blockStyle} width="100%" height={20} />
          </div>
        </div>
        <div className={cls.loan_row}>
          <div style={{ flex: 1 }}>
            <Skeleton style={blockStyle} width="100%" height={20} />
          </div>
          <div style={{ flex: 1 }}>
            <Skeleton style={blockStyle} width="100%" height={20} />
          </div>
        </div>
        <div className={cls.loan_row}>
          <div style={{ flex: 1 }}>
            <Skeleton style={blockStyle} width="100%" height={20} />
          </div>
          <div style={{ flex: 1 }}>
            <Skeleton style={blockStyle} width="100%" height={20} />
          </div>
        </div>
      </div>

      <div className={cls.footer}>
        <Skeleton style={blockStyle} width={36} height={36} borderRadius={18} />

        <div>
          <Skeleton style={blockStyle} width={120} height={20} />
          <Skeleton style={blockStyle} width={96} height={16} />
        </div>
      </div>
    </div>
  );
}
