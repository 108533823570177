import { Data, DotLottie, DotLottieReact } from '@lottiefiles/dotlottie-react';
import { useEffect, useMemo, useRef, useState } from 'react';

import { Nullable } from '@/app/types';

const PATH = '/lottie';

type Props = {
  data?: Data;
  src?: string;
  loop?: boolean | number;
  autoplay?: boolean;
  playOnClick?: boolean;
};

export function Lottie({
  data,
  src,
  loop = false,
  autoplay = true,
  playOnClick
}: Props) {
  const [dotLottie, setDotLottie] = useState<Nullable<DotLottie>>(null);

  const resultSrc = useMemo(() => {
    if (!src) return;
    if (src.startsWith('http')) return src;
    return src.startsWith(PATH) ? src : `${PATH}/${src}.lottie`;
  }, [src]);

  const playAnimation = (lottie?: DotLottie) => {
    const instance = lottie || dotLottie;
    if (instance) {
      instance.stop();
      instance.play();
    }
  };

  const onClick = () => {
    if (playOnClick) {
      playAnimation();
    }
  };

  const loopCount = useRef(0);
  const onComplete = () => {
    const newCount = loopCount.current + 1;
    loopCount.current = newCount;

    if (typeof loop === 'number' && newCount < loop) {
      playAnimation();
    }

    if (newCount === loop && dotLottie) {
      dotLottie.removeEventListener('complete', onComplete);
    }
  };

  useEffect(() => {
    const isLoopNum = typeof loop === 'number';
    if (dotLottie && isLoopNum) {
      dotLottie.addEventListener('complete', onComplete);
    }

    return () => {
      if (dotLottie) {
        dotLottie.removeEventListener('complete', onComplete);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dotLottie, loop]);

  return (
    <DotLottieReact
      dotLottieRefCallback={setDotLottie}
      data={data}
      src={resultSrc}
      loop={typeof loop === 'boolean' ? loop : false}
      autoplay={autoplay}
      onClick={onClick}
    />
  );
}
