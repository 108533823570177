import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Nullable } from '@/app/types';
import { useDebounce } from '@/hooks/useDebounce';
import { deleteEmployee } from '@/modules/roles/api';
import { useEmployees, UseMyRoles } from '@/modules/roles/hooks';
import { EditEmployeeRole } from '@/modules/roles/RolesPage/EditEmployeeRole';
import { InviteEmployee } from '@/modules/roles/RolesPage/InviteEmployee/InviteEmployee';
import { UserCardSkeleton } from '@/modules/roles/RolesPage/UserCardSkeleton';
import { EmployeeType } from '@/modules/roles/types';
import { Button } from '@/ui/Button/Button';
import { ConfirmModal } from '@/ui/modals/ConfirmModal/ConfirmModal';
import { showAlert } from '@/utils/network';

import { DeleteIcon } from './icons';
import cls from './RolesPage.module.scss';

const skeletons = new Array(5).fill(0);

type Props = {
  roles: UseMyRoles;
  search: string;
  isInviteOpen: boolean;
  setInviteOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

export function UsersTab({
  roles,
  search,
  isInviteOpen,
  setInviteOpen
}: Props) {
  const { t } = useTranslation();

  const debouncedSearch = useDebounce(search, 800);
  const req = useMemo(
    () => ({ search: debouncedSearch || null }),
    [debouncedSearch]
  );
  const [employees, loading, refetch] = useEmployees(req);

  const closeInvite = () => {
    setInviteOpen(false);
  };

  // Edit
  const [isEditOpen, setEditOpen] = useState(false);
  const [editingEmployee, setEditingEmployee] =
    useState<Nullable<EmployeeType>>(null);
  const onEditClick = (e: EmployeeType) => {
    setEditingEmployee(e);
    setEditOpen(true);
  };

  // Delete
  const [isDeleteLoading, setDeleteLoading] = useState(false);
  const [isDeleteConfirmOpen, setDeleteConfirmOpen] = useState(false);
  const [deletingEmployee, setDeletingEmployee] =
    useState<Nullable<EmployeeType>>(null);
  const onDeleteClick = (e: EmployeeType) => {
    setDeletingEmployee(e);
    setDeleteConfirmOpen(true);
  };
  const onDeleteConfirm = async (confirmed: boolean) => {
    if (!deletingEmployee) return;
    if (!confirmed) {
      setDeleteConfirmOpen(false);
      setDeletingEmployee(null);
      return;
    }

    setDeleteLoading(true);

    try {
      await deleteEmployee(deletingEmployee.id);
      refetch();
      setDeleteConfirmOpen(false);
      setDeletingEmployee(null);
    } catch (error) {
      showAlert({ error });
    } finally {
      setDeleteLoading(false);
    }
  };

  return (
    <>
      {employees && employees.length > 0 && !loading && (
        <ul className={cls.list}>
          {employees.map((e) => (
            <li key={e.id}>
              <div className={cls.card}>
                <div className={cls.card_row}>
                  <div>
                    <p className={cls.card_title}>{e.name}</p>
                    <p className={cls.card_subtitle}>{e.email}</p>
                  </div>

                  <div className={cls.card_actions}>
                    <Button
                      onClick={() => onEditClick(e)}
                      size="compact"
                      variant="secondary"
                      color="black"
                    >
                      {t('common.edit')}
                    </Button>

                    <div className={cls.delete_btn}>
                      <Button
                        onClick={() => onDeleteClick(e)}
                        size="compact"
                        variant="secondary"
                        color="black"
                      >
                        <DeleteIcon />
                      </Button>
                    </div>
                  </div>
                </div>

                <div className={cls.card_hr} />

                <div className={cls.card_row} style={{ gap: 36 }}>
                  <div className={cls.card_col}>
                    <p className={cls.card_subtitle}>{t('roles.role')}</p>
                    <p className={cls.card_text}>{e.role.name}</p>
                  </div>

                  <div className={cls.card_col}>
                    <p className={cls.card_subtitle}>{t('roles.rights')}</p>
                    <p className={cls.card_text}>
                      {e.permissions
                        .map((p) => p.category_name)
                        .join(t('common.listSeparator') as string)}
                    </p>
                  </div>
                </div>
              </div>
            </li>
          ))}
        </ul>
      )}

      {loading && (
        <ul className={cls.list}>
          {skeletons.map((_, i) => (
            <li key={i}>
              <UserCardSkeleton />
            </li>
          ))}
        </ul>
      )}

      <InviteEmployee roles={roles} isOpen={isInviteOpen} close={closeInvite} />
      {editingEmployee && (
        <EditEmployeeRole
          roles={roles}
          roleId={editingEmployee.role.id}
          employeeId={editingEmployee.id}
          onSave={refetch}
          isOpen={isEditOpen}
          close={() => setEditOpen(false)}
        />
      )}

      {deletingEmployee && (
        <ConfirmModal
          name="employee-delete"
          isOpen={isDeleteConfirmOpen}
          close={() => setDeleteConfirmOpen(false)}
          title={t('roles.employeeDeleteConfirm', {
            name: deletingEmployee.name
          })}
          confirmText={t('common.confirm')}
          cancelText={t('common.cancel')}
          onButtonClick={onDeleteConfirm}
          loading={isDeleteLoading}
        />
      )}
    </>
  );
}
