import { useTranslation } from 'react-i18next';

import { Nullable, OptionI } from '@/app/types';
import { usePaymentMethodInfo } from '@/modules/wallet/AutoReplenishment/SavedPaymentMethods/hooks';
import { SavedPaymentMethod } from '@/modules/wallet/AutoReplenishment/types';

import cls from './SavedPaymentMethods.module.scss';

type Props = {
  value?: Nullable<OptionI>;
  paymentMethods: SavedPaymentMethod[];
};

export function PaymentMethodSelectValue({ value, paymentMethods }: Props) {
  const { t } = useTranslation();

  const { PaymentMethodIcon, paymentMethodName } = usePaymentMethodInfo(
    paymentMethods,
    value?.id
  );

  return (
    <div className={cls.option_value}>
      {!value && (
        <p className={cls.select_card}>
          {t('paymentMethods.selectPaymentMethod')}
        </p>
      )}

      {value && (
        <div className={cls.card}>
          <PaymentMethodIcon />

          <div className={cls.details}>
            <p className={cls.brand}>{paymentMethodName}</p>
            <p className={cls.number}>{`**** ${value.text}`}</p>
          </div>
        </div>
      )}
    </div>
  );
}
