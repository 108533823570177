import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { AutoReplenishmentForm } from '@/modules/wallet/AutoReplenishment/AutoReplenishmentForm';
import { useAutoReplenishmentInfo } from '@/modules/wallet/AutoReplenishment/hooks';
import { AsideModal } from '@/ui/modals/AsideModal/AsideModal';
import { Switch } from '@/ui/Switch/Switch';

import cls from './AutoReplenishment.module.scss';

export function AutoReplenishment() {
  const { t } = useTranslation();

  const [isSettingsOpen, setSettingsOpen] = useState(false);
  const [replenishmentInfo, isReplenishmentInfoLoading, loadReplenishmentInfo] =
    useAutoReplenishmentInfo();

  useEffect(() => {
    if (isSettingsOpen) loadReplenishmentInfo();
  }, [isSettingsOpen, loadReplenishmentInfo]);

  return (
    <>
      <div className={cls.switch}>
        <div className={cls.switch_text}>
          <b>{t('replenishment.title')}</b>
          <p>{t('replenishment.openSettings')}</p>
        </div>
        <Switch
          id="auto-replenishment-toggle"
          checked={!!replenishmentInfo?.is_enabled}
          green
        />
        <button onClick={() => setSettingsOpen(true)} type="button" />
      </div>

      <AsideModal
        name="auto-replenishment-settings-modal"
        title={t('replenishment.title')}
        isOpen={isSettingsOpen}
        close={() => setSettingsOpen(false)}
        containerClass={cls.modal}
      >
        {replenishmentInfo && (
          <AutoReplenishmentForm
            replenishmentInfo={replenishmentInfo}
            isLoading={isReplenishmentInfoLoading}
            updateInfo={loadReplenishmentInfo}
          />
        )}
      </AsideModal>
    </>
  );
}
