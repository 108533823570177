import cn from 'classnames';
import { useCallback, useContext, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { OptionI } from '@/app/types';
import { useBookingStatuses } from '@/modules/booking/api';
import {
  BookingExistedStatusType,
  GetExistedBookingStatusesRes
} from '@/modules/booking/types';
import { MyAdvertsContext } from '@/modules/showroom/advert/my/MyAdverts/my-adverts-ctx';
import cls from '@/modules/showroom/advert/search/SearchAdverts/SearchAdverts.module.scss';
import { Skeleton } from '@/ui/Skeleton';
import { Tabs } from '@/ui/Tabs/Tabs';

const skeletons = new Array(6).fill(0);

export function Statuses() {
  const { t } = useTranslation();
  const { refreshKey } = useContext(MyAdvertsContext);
  const { status, setStatus } = useContext(MyAdvertsContext);

  const loadHandledRef = useRef(false);
  const onStatusesLoad = useCallback(
    (loadedList: GetExistedBookingStatusesRes) => {
      if (!loadHandledRef.current && loadedList[0]) {
        loadHandledRef.current = true;
        setStatus(loadedList[0]);
      }
    },
    [setStatus]
  );

  const [statusesData, loading] = useBookingStatuses(
    refreshKey,
    onStatusesLoad
  );
  const statuses = useMemo(
    () =>
      statusesData
        ? [
            ...statusesData,
            {
              status: BookingExistedStatusType.archive,
              title: t('booking.archive'),
              count: -1,
              id: -1
            }
          ]
        : statusesData,
    [statusesData, t]
  );

  const options = useMemo<OptionI[]>(
    () =>
      statuses
        ? [
            ...statuses.map((v) => ({
              id: v.status as string,
              text: v.title,
              extra: v.count === -1 ? undefined : String(v.count)
            }))
          ]
        : [],
    [statuses]
  );
  const option = options.find((opt) => opt.id === status?.status);
  const onChange = (opt: OptionI) => {
    const s = statuses?.find((v) => v.status === opt.id);
    if (s) setStatus(s);
  };

  return (
    <div className={cn(cls.statuses, 'hide-scrollbar')}>
      {!loading && statuses && statuses.length > 0 ? (
        <Tabs
          variant="underline"
          tabs={options}
          active={option}
          onChange={onChange}
        />
      ) : (
        <ul className={cls.skeletons}>
          {skeletons.map((_, i) => (
            <li key={i}>
              <div style={{ paddingBottom: 14 }}>
                <Skeleton width={80} height={22} />
              </div>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
}
