import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { publishAllUnpaidAds } from '@/modules/showroom/advert/api';
import { Button } from '@/ui/Button/Button';
import { showAlert } from '@/utils/network';

import cls from './UnpaidBanner.module.scss';

type Props = {
  onPublish: VoidFunction;
};

export function UnpaidBanner({ onPublish }: Props) {
  const { t } = useTranslation();

  const [loading, setLoading] = useState(false);
  const onPublishClick = async () => {
    setLoading(true);

    try {
      await publishAllUnpaidAds();
      onPublish();
    } catch (error) {
      showAlert({ error });
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className={cls.root}>
      <div className={cls.text}>
        <b className={cls.title}>{t('unpaidAds.title')}</b>
        <p className={cls.subtitle}>{t('unpaidAds.subtitle')}</p>
      </div>

      <Button
        onClick={onPublishClick}
        disabled={loading}
        loading={loading}
        variant="primary"
        color="black"
        size="s"
      >
        {t('unpaidAds.action')}
      </Button>
    </div>
  );
}
