export function StartIcon() {
  return (
    <svg
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_8816_6966)">
        <rect width="28" height="28" fill="#676BFF" />
        <g clipPath="url(#clip1_8816_6966)">
          <path
            d="M23.0119 9.8008V16.0369C23.0119 18.4234 21.6559 19.8072 19.2365 19.8072H13.9266L10.9848 22.4694C10.5837 22.8392 10.3273 23 9.99493 23C9.51053 23 9.23007 22.6559 9.23007 22.1269V19.8072H8.72477C6.38933 19.8072 5 18.43 5 16.0369V9.8008C5 7.40778 6.35604 6.02393 8.77543 6.02393H19.2365C21.6559 6.02393 23.0119 7.41438 23.0119 9.8008ZM12.7825 11.5692C12.4327 11.5692 12.1588 11.8365 12.1588 12.1673C12.1588 12.5237 12.4327 12.7719 12.7825 12.7719H13.565V15.9971H12.6465C12.2916 15.9971 12.0243 16.2643 12.0243 16.5951C12.0243 16.953 12.2916 17.2064 12.6465 17.2064H15.781C16.1359 17.2064 16.4032 16.953 16.4032 16.5951C16.4032 16.2643 16.1359 15.9971 15.781 15.9971H14.9219V12.3403C14.9219 11.8796 14.6896 11.5692 14.2559 11.5692H12.7825ZM12.9003 9.30434C12.9003 9.89327 13.3781 10.3725 13.9619 10.3725C14.5523 10.3725 15.0235 9.89327 15.0235 9.30434C15.0235 8.70818 14.5523 8.23328 13.9619 8.23328C13.3781 8.23328 12.9003 8.70818 12.9003 9.30434Z"
            fill="white"
          />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_8816_6966">
          <rect width="28" height="28" rx="6" fill="white" />
        </clipPath>
        <clipPath id="clip1_8816_6966">
          <rect
            width="18.2531"
            height="18"
            fill="white"
            transform="translate(5 5)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}

export function GreetIcon() {
  return (
    <svg
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_8816_6984)">
        <rect width="28" height="28" fill="#E95D44" />
        <path
          d="M21.0939 21.2018C23.1672 19.1346 23.6576 16.9468 22.8879 14.3433C22.4788 12.6487 21.5193 10.7874 20.6424 9.14158C20.2153 8.30824 19.6772 7.22485 19.3418 6.84823C18.9648 6.42306 18.4134 6.39744 17.9846 6.78592C17.4977 7.21606 17.4824 7.77526 17.7971 8.68296L18.8759 11.6317C18.9994 11.9848 18.9664 12.1536 18.7706 12.3981C17.5245 13.8446 17.3595 15.5921 18.458 16.8038C18.6655 17.0487 18.6558 17.4019 18.4249 17.6234C18.2096 17.8482 17.825 17.8421 17.6097 17.6032C16.5095 16.4073 15.8186 14.1036 17.8703 11.6472L11.2089 4.99363C10.7809 4.55775 10.0904 4.55775 9.66232 4.99191C9.22816 5.41823 9.22816 6.11221 9.66404 6.54636L14.8506 11.7364C14.6159 11.846 14.3733 11.979 14.129 12.1391L8.13501 6.14681C7.69913 5.72049 7.01815 5.72049 6.58055 6.14681C6.15423 6.58269 6.15423 7.27322 6.58055 7.70127L12.5093 13.6188C12.3241 13.8327 12.1493 14.0641 11.9876 14.2875L6.55589 8.85578C6.12173 8.43118 5.4356 8.42946 5.00143 8.85578C4.56727 9.28823 4.56899 9.98391 5.00315 10.4102L10.905 16.3104C10.7859 16.613 10.7128 16.9156 10.6442 17.2026L6.54002 13.1001C6.10586 12.6659 5.42317 12.6625 4.98901 13.0966C4.55925 13.523 4.55925 14.2091 4.98901 14.6433L11.4827 21.137C14.5133 24.1692 18.3477 23.9385 21.0939 21.2018Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_8816_6984">
          <rect width="28" height="28" rx="6" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

export function CheckIcon() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.82886 20.9999C10.3539 20.9999 10.7681 20.7808 11.0559 20.3417L20.547 5.54581C20.7597 5.213 20.8449 4.93452 20.8449 4.66048C20.8449 3.96517 20.356 3.48535 19.6534 3.48535C19.155 3.48535 18.8656 3.65297 18.5633 4.12754L9.78846 18.068L5.26664 12.2539C4.97241 11.8642 4.66364 11.701 4.22743 11.701C3.50648 11.701 3 12.2057 3 12.9027C3 13.2022 3.11188 13.5048 3.36371 13.8107L8.59516 20.356C8.94352 20.7917 9.32015 20.9999 9.82886 20.9999Z"
        fill="currentColor"
      />
    </svg>
  );
}
