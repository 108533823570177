import { useState, useCallback, useEffect } from 'react';

import { axios } from '@/app/api';
import { usePermissionAccess } from '@/modules/roles/helpers';
import { ShowroomTariffRes } from '@/modules/showroom/tariff/types';
import { showAlert } from '@/utils/network';

function getTariff(category_id: number) {
  return axios.get('/dealers/showrooms/tariff', {
    params: { category_id }
  });
}

export function useTariff(
  category_id: number
): [ShowroomTariffRes | undefined, boolean] {
  const { tariffs } = usePermissionAccess();
  const hasAccess = tariffs.access;

  const [isLoading, setLoading] = useState<boolean>(false);
  const [data, setData] = useState<ShowroomTariffRes>();

  const load = useCallback(async () => {
    setLoading(true);

    try {
      const r = await getTariff(category_id);
      setData(r.data);
    } catch (error) {
      showAlert({ error });
    } finally {
      setLoading(false);
    }
  }, [category_id]);

  useEffect(() => {
    if (hasAccess) load();
  }, [hasAccess, load]);

  return [data, isLoading];
}
