export function ChevronIcon() {
  return (
    <svg
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.29674 5.99961C8.29508 5.84596 8.2377 5.71356 8.11608 5.59643L4.59654 2.1484C4.49405 2.04962 4.37186 2 4.22428 2C3.93363 2 3.70001 2.22829 3.70001 2.52183C3.70001 2.66489 3.75821 2.79729 3.85898 2.90014L7.03644 5.99877L3.85898 9.09907C3.75903 9.2011 3.70001 9.33059 3.70001 9.4782C3.70001 9.77091 3.93363 10 4.22428 10C4.37024 10 4.49405 9.95041 4.59654 9.84792L8.11608 6.40276C8.23851 6.28192 8.29674 6.15243 8.29674 5.99961Z"
        fill="currentColor"
      />
    </svg>
  );
}

export function CloseIcon() {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.0617 12.9378C3.3393 13.209 3.80751 13.2055 4.07183 12.9412L8.00126 9.01181L11.9284 12.9401C12.1973 13.209 12.665 13.2131 12.9374 12.9366C13.2097 12.659 13.2109 12.2018 12.942 11.9317L9.01482 7.99822L12.942 4.07109C13.2109 3.80218 13.215 3.33972 12.9374 3.06735C12.6598 2.78975 12.1973 2.7886 11.9284 3.06275L8.00126 6.98988L4.07183 3.0616C3.80751 2.79319 3.33406 2.78336 3.0617 3.0662C2.79048 3.34381 2.79393 3.80562 3.05825 4.06994L6.98768 7.99822L3.05825 11.934C2.79393 12.1972 2.78524 12.6654 3.0617 12.9378Z"
        fill="currentColor"
      />
    </svg>
  );
}
