import { create } from 'zustand';

import { Nullable } from '@/app/types';
import { getAccountInfo, getBalance } from '@/modules/accounts/api';
import { useAuthStore } from '@/modules/auth/store';
import { getMeAsEmployee } from '@/modules/roles/api';
import { hasPermissionAccess } from '@/modules/roles/helpers';
import { EmployeeType } from '@/modules/roles/types';
import { showAlert } from '@/utils/network';

import { BalanceRes, NullableAccountInfo } from './types';

interface AccountStore {
  isUserLoading: boolean;
  employeeInfo: Nullable<EmployeeType>;
  setEmployeeInfo: (employeeInfo: Nullable<EmployeeType>) => void;
  accountInfo: NullableAccountInfo;
  setAccountInfo: (accountInfo: NullableAccountInfo) => void;
  fetchAccountInfo: () => void;
  balanceInfo: Nullable<BalanceRes>;
  fetchBalance: () => void;
  reset: () => void;
}

export const useAccountStore = create<AccountStore>((set, get) => ({
  isUserLoading: false,
  employeeInfo: null,
  setEmployeeInfo: (employeeInfo: Nullable<EmployeeType>) =>
    set({ employeeInfo }),
  accountInfo: null,
  setAccountInfo: (accountInfo: NullableAccountInfo) => set({ accountInfo }),
  fetchAccountInfo: async () => {
    set({ isUserLoading: true });

    try {
      let employeeInfo = null;
      const { data } = await getAccountInfo();

      const auth = useAuthStore.getState().auth;
      if (auth && auth.is_owner === false) {
        const r = await getMeAsEmployee();
        employeeInfo = r.data;
      }

      set({ employeeInfo, accountInfo: data, isUserLoading: false });
      if (hasPermissionAccess(employeeInfo).wallet.access) get().fetchBalance();
    } catch (error) {
      showAlert({ error });
      set({ isUserLoading: false });
    }
  },
  balanceInfo: null,
  fetchBalance: async () => {
    set({ isUserLoading: true });
    try {
      const { data } = await getBalance();
      set({ balanceInfo: data, isUserLoading: false });
    } catch (error) {
      showAlert({ error });
      set({ isUserLoading: false });
    }
  },
  reset: () => {
    set({
      employeeInfo: null,
      accountInfo: null,
      isUserLoading: false,
      balanceInfo: null
    });
  }
}));
