import { useEffect } from 'react';

import { useDarkmode } from '@/modules/theme/useTheme';

export const MAPKIT_TOKEN =
  'eyJhbGciOiJFUzI1NiIsInR5cCI6IkpXVCIsImtpZCI6IjJON0FDQjg2N1IifQ.eyJpYXQiOjE3Mjg2NTU5MjEuOTQzLCJpc3MiOiI5UUFaSjZSVk0zIn0.GQjlwB5eEteaJUcYPAxiA0YE2KYV1Y1wIgS3BEpUwxA0b86-PvkwKWN1bMbx6kisiGmBYM76DF7yyPU_Bj4gCg';

export const emiratesNamesMap: Record<number, string[]> = {
  1: ['abu-dhabi', 'abu dhabi', 'أبوظبي'],
  2: ['ajman', 'عجمان'],
  3: ['dubai', 'دبي'],
  4: ['ras-al-khaimah', 'ras al-khaimah', 'رأس الخيمة'],
  5: ['umm-al-quwain', 'umm al-quwain', 'أم القيوين'],
  6: ['sharjah', 'عجمان'],
  7: ['fujairah', 'الفجيرة']
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function useMapDarkmode(map: any) {
  const { isDark } = useDarkmode();

  useEffect(() => {
    if (map) {
      map.colorScheme = isDark ? 'dark' : 'light';
    }
  }, [isDark, map]);
}
