import { AccountInfo } from '@/modules/accounts/types';

export interface LoginAccountReq {
  email: string;
  password: string;
}

export interface LoginAccountRes extends AccountInfo {
  access_token: string;
  token_type: string;
  is_owner: boolean;
}

export type CreateAccountReq = LoginAccountReq;
export type CreateAccountRes = { email: string };

export type ConfirmEmailSendCodeReq = { email: string };
export type ConfirmEmailReq = { email: string; code: string };
export type ConfirmEmailRes = {
  access_token: LoginAccountRes['access_token'];
  token_type: LoginAccountRes['token_type'];
};

export enum AuthState {
  initial = 'initial',
  authed = 'authed',
  not_authed = 'not_authed'
}

export type AuthType = ConfirmEmailRes & {
  is_owner: boolean;
};
