import cn from 'classnames';
import React, { CSSProperties } from 'react';

import cls from './Checkbox.module.scss';
import {
  CheckboxIcon,
  CheckboxRoundedIcon,
  UncheckIcon,
  UncheckRoundedIcon
} from './icons';

interface Props {
  id: string;
  checked: boolean;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  disabled?: boolean;
  name?: string;
  children?: React.ReactNode;
  rounded?: boolean;
  small?: boolean;
  iconStyle?: CSSProperties;
  defaultColor?: string;
  checkedColor?: string;
}

export const Checkbox: React.FC<Props> = ({
  id,
  checked,
  onChange,
  disabled,
  name,
  children,
  rounded,
  small,
  iconStyle,
  defaultColor = 'var(--clr-blue)',
  checkedColor = 'var(--clr-blue)'
}) => {
  return (
    <div
      className={cn(cls.container, { [cls.container_small]: small })}
      style={
        {
          '--checkbox-default-color': defaultColor,
          '--checkbox-checked-color': checkedColor
        } as React.CSSProperties
      }
    >
      <input
        className={cls.input}
        type="checkbox"
        id={id}
        checked={checked}
        onChange={onChange}
        disabled={disabled}
        name={name}
      />

      <label className={cls.label} htmlFor={id}>
        {rounded ? (
          <>
            {checked ? (
              <CheckboxRoundedIcon style={iconStyle} />
            ) : (
              <UncheckRoundedIcon style={iconStyle} />
            )}
          </>
        ) : (
          <>
            {checked ? (
              <CheckboxIcon style={iconStyle} />
            ) : (
              <UncheckIcon style={iconStyle} />
            )}
          </>
        )}
      </label>

      {children && (
        <label className={cls.text} htmlFor={id}>
          {children}
        </label>
      )}
    </div>
  );
};

Checkbox.displayName = 'Checkbox';
