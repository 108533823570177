import cn from 'classnames';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { PhotoType } from '@/app/types';
import { useLang } from '@/hooks/useLang';
import { useOnceTrue } from '@/hooks/useOnceTrue';
import { EmojiType } from '@/modules/emojis/types';
import { OfferModal } from '@/modules/offers/OfferModal/OfferModal';
import { PromotionPackage } from '@/modules/promotion/types';
import { useAdvertName } from '@/modules/showroom/advert/hooks';
import { getMyAds } from '@/modules/showroom/advert/my/api';
import { Actions } from '@/modules/showroom/advert/my/MyAdvertsList/MyAdvertCard/Actions/Actions';
import { Promotions } from '@/modules/showroom/advert/my/MyAdvertsList/MyAdvertCard/Promotions/Promotions';
import { MyAdvert } from '@/modules/showroom/advert/my/types';
import { SearchAdvertsReq } from '@/modules/showroom/advert/search/types';
import { AdvertStatusType } from '@/modules/showroom/advert/types';
import { VRP_CATEGORY_ID } from '@/modules/showroom/advert/vrp/helpers';
import { AdEffectiveness } from '@/ui/AdEffectiveness/AdEffectiveness';
import { ImgSkeleton } from '@/ui/ImgSkeleton/ImgSkeleton';
import { ADV_CUR, APP_URL } from '@/utils/consts';
import { numberWithSpaces } from '@/utils/format';

import cls from './MyAdvertCardMob.module.scss';

import { EyeIcon, HeartIcon, ClickIcon, LeadIcon } from '../MyAdvertCard/icons';

type Props = {
  advert: MyAdvert;
  onPublishToggle?: () => void;
  promotionsList?: PromotionPackage[];
  stickersList?: EmojiType[];
  // discountTypes?: DiscountType[];
  // euroRate?: Nullable<number>;
  // usdRate?: Nullable<number>;
};

export function MyAdvertCardMob({
  advert: advertProp,
  onPublishToggle,
  promotionsList,
  stickersList
}: Props) {
  const { t } = useTranslation();
  const [lang] = useLang();

  // Ad
  const [advert, setAdvert] = useState(advertProp);
  const { engagement } = advert;

  // Refresh
  const refreshAdvert = async () => {
    const req: Partial<SearchAdvertsReq> = {
      category_id: advert.category.id,
      price_min: advert.price ? advert.price - 1 : null,
      price_max: advert.price ? advert.price + 1 : null
    };

    try {
      const r = await getMyAds(req);
      const ad = r.data.find((v) => v.id === advert.id);
      if (ad) setAdvert((prev) => ({ ...prev, ...ad }));
    } catch (error) {
      console.error(error);
    }
  };

  // Image, name
  const images = useMemo(() => {
    const { vrp_image_url } = advert;
    if (vrp_image_url)
      return [
        { photo_url: vrp_image_url, thumbnail_url: vrp_image_url } as PhotoType
      ];

    return advert.photos || [];
  }, [advert]);
  const name = useAdvertName(advert);

  // Flags
  const isDraft = advert.status.title === AdvertStatusType.draft;
  const isPublished = advert.status.title === AdvertStatusType.published;
  const isModeration = advert.status.title === AdvertStatusType.moderation;
  const isSold = advert.status.title === AdvertStatusType.sold;
  const showStatus = !isPublished;
  const isVrp = advert.category.id === VRP_CATEGORY_ID;

  const showPromo = isPublished && !!promotionsList && !!stickersList;

  // Urls
  const advertUrl = advert.url ? `${APP_URL}/${advert.url}` : '';
  const editUrl = isVrp ? `/vrp/edit/${advert.id}` : `/cars/edit/${advert.id}`;

  // Stats
  const StatsCell = ({
    children,
    icon,
    count
  }: {
    children?: React.ReactNode;
    icon: React.ReactNode;
    count: number;
  }) => (
    <li>
      <div className={cls.stats_cell}>
        <div className={cls.stats_icon}>{icon}</div>
        <p>{numberWithSpaces(count, lang)}</p>
        {children}
      </div>
    </li>
  );

  // Offer
  const [isOfferOpen, setOfferOpen] = useState(false);
  const offerOpenedOnce = useOnceTrue(isOfferOpen);

  // const countAddToFavorites = engagement.count_add_to_favorites;
  // const canMakeOffer = !!countAddToFavorites;

  // Footer
  const [isScrollStart, setScrollStart] = useState(true);
  const onPromoScroll = (e: React.UIEvent<HTMLDivElement, UIEvent>) => {
    setScrollStart(e.currentTarget.scrollLeft <= 0);
  };

  return (
    <>
      <div className={cls.root}>
        <div className={cls.images_wrap}>
          {showStatus && (
            <span
              className={cn(cls.status, {
                [cls.status_green]: isSold,
                [cls.status_orange]: isModeration
              })}
            >
              {advert.status.name}
            </span>
          )}

          <ul className={cn(cls.images, 'hide-scrollbar')}>
            {images.map((img, i) => {
              const one = images.length < 2;
              const first = !one && i === 0;
              const last = !one && i === images.length - 1;

              return (
                <li key={img.photo_url}>
                  <ImgSkeleton
                    className={cn(cls.img, {
                      [cls.img_one]: one,
                      [cls.img_first]: first,
                      [cls.img_last]: last
                    })}
                    src={img.thumbnail_url || img.photo_url}
                    alt=""
                    imgProxyWidth={200}
                    imgProxyHeight={150}
                    style={{ background: isVrp ? 'var(--thm-card)' : '' }}
                  />
                  {isDraft && <Link className={cls.link} to={editUrl} />}
                </li>
              );
            })}

            {images.length > 1 && <li className={cls.img_filler} />}
          </ul>
        </div>

        <div className={cls.info}>
          <b className={cls.price}>
            {t('priceCurrency', {
              price: numberWithSpaces(
                advert.price ? Number(advert.price) : 0,
                lang
              ),
              currency: ADV_CUR
            })}
          </b>
          <p className={cls.name}>{name}</p>

          {isPublished && advertUrl ? (
            <a
              className={cls.link}
              href={advertUrl}
              target="_blank"
              rel="noreferrer"
            />
          ) : (
            <Link className={cls.link} to={editUrl} />
          )}
        </div>

        <ul className={cls.list}>
          {advert.ad_effectiveness && (
            <li>
              <div className={cls.ad_effectiveness}>
                <AdEffectiveness adEffectiveness={advert.ad_effectiveness} />
              </div>
            </li>
          )}

          <StatsCell icon={<EyeIcon />} count={engagement.count_shows} />
          <StatsCell icon={<ClickIcon />} count={engagement.count_views} />
          <StatsCell icon={<LeadIcon />} count={engagement.count_leads} />
          <StatsCell
            icon={<HeartIcon />}
            count={engagement.count_add_to_favorites}
          >
            {/* {canMakeOffer && (
              <button
                className={cls.send_offer}
                type="button"
                onClick={() => setOfferOpen(true)}
              >
                <ChatIcon />
                {t('advertisements.my.sendOffer')}
              </button>
            )} */}
          </StatsCell>
        </ul>

        <div
          className={cn(cls.footer, {
            [cls.footer_scroll_start]: isScrollStart
          })}
        >
          <div
            className={cn(cls.promotions, 'hide-scrollbar')}
            onScroll={onPromoScroll}
          >
            {showPromo && (
              <Promotions
                advert={advert}
                promotionsList={promotionsList}
                stickersList={stickersList}
                onPublishToggle={refreshAdvert}
                hovered
              />
            )}
          </div>

          <div className={cls.actions}>
            <Actions advert={advert} onPublishToggle={onPublishToggle} mobile />
          </div>
        </div>
      </div>

      {offerOpenedOnce && (
        <OfferModal
          isOpen={isOfferOpen}
          close={() => setOfferOpen(false)}
          advert={advert}
        />
      )}
    </>
  );
}
