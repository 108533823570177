import Tooltip from 'rc-tooltip';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useImgPlaceholder } from '@/hooks/useImgPlaceholder';
import { useLang } from '@/hooks/useLang';
import { useAdvertInfo } from '@/modules/chat/hooks';
import creditCls from '@/modules/credit/CreditRequests/CreditRequest/CreditRequest.module.scss';
import {
  CopyIcon,
  MailIcon,
  PersonIcon,
  PhoneIcon
} from '@/modules/credit/CreditRequests/CreditRequest/icons';
import { markLeadsAsViewed } from '@/modules/leads/api';
import { Lead, LeadType } from '@/modules/leads/types';
import { useAdvertName } from '@/modules/showroom/advert/hooks';
import { AdvertStatusType } from '@/modules/showroom/advert/types';
import { Avatar } from '@/ui/Avatar/Avatar';
import { ImgSkeleton } from '@/ui/ImgSkeleton/ImgSkeleton';
import { useFormatPhone } from '@/ui/PhoneInput/helpers';
import { APP_URL, HIDDEN_CAR_IMG_PATH, rcTooltipProps } from '@/utils/consts';
import { copyToClipboard } from '@/utils/copy';
import { isThisYear, isToday, isYesterday } from '@/utils/date';
import { showAlert } from '@/utils/network';

import { ChevronIcon, WhatsAppIcon, ChatIcon } from './icons';
import cls from './LeadRow.module.scss';

type Props = {
  lead: Lead;
};

export function LeadRow({ lead }: Props) {
  const { t } = useTranslation();
  const [lang] = useLang();

  const ad = lead.ad;

  const customer = lead.customer;
  const contact = lead.showroom_contact;

  const advertInfo = useAdvertInfo(ad);

  const createdStr = useMemo(() => {
    const createdDate = new Date(lead.created_at * 1000);
    const today = isToday(createdDate);
    const yesterday = isYesterday(createdDate);
    const thisYear = isThisYear(createdDate);

    const dateStr = createdDate.toLocaleDateString(lang, {
      day: '2-digit',
      month: 'long',
      year: thisYear ? undefined : 'numeric',
      hour: '2-digit',
      minute: '2-digit'
    });

    if (today || yesterday) {
      const str = today ? t('common.today') : t('common.yesterday');
      return `${str}, ${dateStr}`;
    }

    return dateStr;
  }, [lead.created_at, lang, t]);

  const advertUrl = ad?.url ? `${APP_URL}/${ad.url}` : '';
  const adName = useAdvertName(ad);
  const formattedPhone = useFormatPhone(customer?.phone_number || '');

  const imgPlaceholder = useImgPlaceholder();
  const adImage = useMemo(() => {
    if (ad.vrp_image_url) return ad.vrp_image_url;

    if (ad.photos && ad.photos[0]) {
      const ph = ad.photos[0];
      return ph.thumbnail_url || ph.photo_url || imgPlaceholder;
    }
  }, [ad, imgPlaceholder]);
  const [imgSrc, setImgSrc] = useState(adImage);

  useEffect(() => {
    setImgSrc(adImage);
  }, [adImage]);

  const onCopyClick = (copyStr: string) => () => {
    copyToClipboard(copyStr, t('common.copied') as string);
  };

  // View badge
  const [viewed, setViewed] = useState(!!lead.has_been_viewed);
  const [markLoading, setMarkLoading] = useState(false);
  const onHover = async () => {
    if (markLoading || viewed) return;
    setMarkLoading(true);

    try {
      await markLeadsAsViewed({ ids: [lead.id] });
      setViewed(true);
    } catch (error) {
      showAlert({ error });
    } finally {
      setMarkLoading(false);
    }
  };

  // Lead
  const leadTypeIcon = useMemo<Record<LeadType, JSX.Element>>(() => {
    return {
      [LeadType.phone]: <PhoneIcon />,
      [LeadType.chat]: <ChatIcon />,
      [LeadType.whatsapp]: <WhatsAppIcon />
    };
  }, []);

  const leadTypeLabel = useMemo<Record<LeadType, string>>(() => {
    return {
      [LeadType.phone]: t('phone'),
      [LeadType.chat]: t('chat.one'),
      [LeadType.whatsapp]: t('contacts.whatsapp.title')
    };
  }, [t]);

  const leadTooltipLabel = useMemo(() => {
    const customerName = customer?.name || t('leads.anon');
    const contactName = contact?.name
      ? t('leads.to', { contact: contact.name })
      : '';

    return {
      [LeadType.phone]: t('leads.tooltip.phone', {
        contact: contactName,
        customer: customerName
      }),
      [LeadType.chat]: t('leads.tooltip.chat', {
        contact: contactName,
        customer: customerName
      }),
      [LeadType.whatsapp]: t('leads.tooltip.whatsapp', {
        contact: contactName,
        customer: customerName
      })
    };
  }, [contact, customer, t]);

  return (
    <div className={creditCls.root} onMouseEnter={onHover}>
      <div className={creditCls.col}>
        <a
          className={creditCls.link}
          href={advertUrl || ''}
          target="_blank"
          rel="noreferrer"
        />

        <div className={creditCls.img}>
          <ImgSkeleton
            key={imgSrc}
            className={creditCls.img_inner}
            src={imgSrc}
            alt=""
            imgProxyWidth={170}
            imgProxyHeight={128}
            onError={() => setImgSrc(HIDDEN_CAR_IMG_PATH)}
            style={{
              objectFit: imgSrc === HIDDEN_CAR_IMG_PATH ? 'contain' : 'cover'
            }}
          />
        </div>
      </div>

      <div
        className={creditCls.col}
        style={{ justifyContent: 'space-between' }}
      >
        <a
          className={creditCls.link}
          href={advertUrl || ''}
          target="_blank"
          rel="noreferrer"
        />

        <div>
          <div className={creditCls.advert_wrap} style={{ marginBottom: 4 }}>
            <p className={creditCls.advert}>{adName}</p>
            {ad.status?.title !== AdvertStatusType.published && (
              <span className={creditCls.advert_status}>{ad.status?.name}</span>
            )}
            {!viewed && <span className={creditCls.view_badge} />}
          </div>

          <ul className={cls.ad_stats}>
            {advertInfo.map((row, i) => (
              <li key={i}>{row}</li>
            ))}
          </ul>
        </div>

        <div className={cls.lead_row}>
          <Tooltip
            placement="top"
            overlay={leadTooltipLabel[lead.lead_type]}
            overlayClassName={cls.tooltip_overlay}
            {...rcTooltipProps}
          >
            <div className={cls.lead_type}>
              {leadTypeIcon[lead.lead_type]}
              {leadTypeLabel[lead.lead_type]}
            </div>
          </Tooltip>
          {contact && (
            <>
              <ChevronIcon />
              <div className={cls.lead_customer}>
                <Avatar
                  size={28}
                  src={contact.avatar_url || ''}
                  user={{ id: contact.id, name: contact.name || '' }}
                />
                {contact.name}
              </div>
            </>
          )}
        </div>

        <p className={creditCls.date} style={{ marginTop: 0 }}>
          {createdStr}
        </p>
      </div>

      {customer ? (
        <div className={creditCls.col}>
          <p className={creditCls.col_label}>{t('credits.req.aboutLead')}</p>

          {customer.name && (
            <button
              className={creditCls.contact}
              type="button"
              onClick={onCopyClick(customer.name)}
            >
              <PersonIcon />
              <span>{customer.name}</span>
              <CopyIcon />
            </button>
          )}
          {formattedPhone && (
            <button
              className={creditCls.contact}
              type="button"
              onClick={onCopyClick(formattedPhone)}
            >
              <PhoneIcon />
              <span>{formattedPhone}</span>
              <CopyIcon />
            </button>
          )}
          {customer.email && (
            <button
              className={creditCls.contact}
              type="button"
              onClick={onCopyClick(customer.email)}
            >
              <MailIcon />
              <span>{customer.email}</span>
            </button>
          )}
        </div>
      ) : (
        <div className={creditCls.col} style={{ minHeight: 'unset' }}>
          <p className={creditCls.col_label}>{t('credits.req.aboutLead')}</p>

          <div className={creditCls.contact}>
            <PersonIcon />
            <span style={{ maxWidth: 'unset' }}>{t('leads.anon')}</span>
          </div>
        </div>
      )}
    </div>
  );
}
