import { useState, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { useDefaultPeriod } from '@/hooks/useDefaultPeriod';
import { useLang } from '@/hooks/useLang';
import { GraphView } from '@/modules/overview/CountCard/GraphView';
import { useEngagementStats } from '@/modules/overview/hooks';
import { usePermissionAccess } from '@/modules/roles/helpers';
import { AdvertsCard } from '@/modules/showroom/advert/AdvertsCard/AdvertsCard';
import { BalanceCard } from '@/modules/wallet/BalanceCard/BalanceCard';
import { ExpensesCard } from '@/modules/wallet/ExpensesCard/ExpensesCard';
import { useExpensesDetailStatistics } from '@/modules/wallet/hooks';
import { DatesFilter } from '@/ui/Filters/DatesFilter';
import { LayoutHeader } from '@/ui/layouts/SidebarLayout/LayoutHeader';
import { PageHead } from '@/ui/PageHead/PageHead';
import { numberWithSpaces, onlyNumbers } from '@/utils/format';

import cls from './Overview.module.scss';

const cardStyle = {
  height: '100%'
};

export function Overview() {
  const { t } = useTranslation();
  const [lang] = useLang();

  const permissions = usePermissionAccess();

  // Dates
  const defaultPeriod = useDefaultPeriod();
  const [from, setFrom] = useState<Date>(defaultPeriod.from);
  const [to, setTo] = useState<Date>(defaultPeriod.to);
  const datesRange = [from, to] as [Date, Date];

  const onDatesChange = (fromDate: Date, toDate: Date) => {
    setFrom(fromDate);
    setTo(toDate);
  };
  const fromPeriodUnix = useMemo(
    () => Math.round(from.getTime() / 1000),
    [from]
  );
  const toPeriodUnix = useMemo(() => Math.round(to.getTime() / 1000), [to]);

  // Expenses data
  const expensesReq = useMemo(
    () => ({
      period_start: fromPeriodUnix,
      period_end: toPeriodUnix
    }),
    [fromPeriodUnix, toPeriodUnix]
  );
  const [expensesData] = useExpensesDetailStatistics(expensesReq, lang);
  const [engagementStatsData, engagementStatsLoading] = useEngagementStats(
    expensesReq,
    lang
  );

  // Shows
  const showsAmount = useMemo(() => {
    const data = engagementStatsData?.shows;
    if (!data || data.length === 0) return '0';

    const summary = data.reduce((a, b) => a + b.count, 0);
    return numberWithSpaces(summary, lang);
  }, [engagementStatsData?.shows, lang]);

  const showsTrend = useMemo(() => {
    if (engagementStatsData) {
      return t('common.percentValue', {
        value: numberWithSpaces(Math.abs(engagementStatsData.shows_trend), lang)
      });
    }

    return '';
  }, [engagementStatsData, lang, t]);

  // Views
  const viewsAmount = useMemo(() => {
    const data = engagementStatsData?.views;
    if (!data || data.length === 0) return '0';

    const summary = data.reduce((a, b) => a + b.count, 0);
    return numberWithSpaces(summary, lang);
  }, [engagementStatsData?.views, lang]);

  const viewsConversion = useMemo(() => {
    const data = engagementStatsData?.views;
    if (!data || data.length === 0) return '0';

    const summary = data.reduce((a, b) => a + b.count, 0);
    const count = numberWithSpaces(summary, lang);

    if (!showsAmount || showsAmount === '0') return count;

    const ratio = Math.round(
      (summary / Number(onlyNumbers(showsAmount))) * 100
    );

    return t('common.percentValue', { value: ratio });
  }, [engagementStatsData?.views, lang, showsAmount, t]);

  const viewsTrend = useMemo(() => {
    if (engagementStatsData) {
      return t('common.percentValue', {
        value: numberWithSpaces(Math.abs(engagementStatsData.views_trend), lang)
      });
    }

    return '';
  }, [engagementStatsData, lang, t]);

  // Leads
  const leadsAmount = useMemo(() => {
    const data = engagementStatsData?.leads;
    if (!data) return '0';

    const summary = data.length > 0 ? data.reduce((a, b) => a + b.count, 0) : 0;
    return numberWithSpaces(summary, lang);
  }, [engagementStatsData?.leads, lang]);

  const leadsConversion = useMemo(() => {
    const data = engagementStatsData?.leads;
    if (!data) return '0';

    const summary = data.length > 0 ? data.reduce((a, b) => a + b.count, 0) : 0;
    const count = numberWithSpaces(summary, lang);

    const viewsSummary = engagementStatsData?.views
      ? engagementStatsData?.views.reduce((a, b) => a + b.count, 0)
      : 0;
    if (!viewsSummary) return count;

    const ratio = Math.round((summary / viewsSummary) * 100);
    return t('common.percentValue', { value: ratio });
  }, [engagementStatsData?.leads, engagementStatsData?.views, lang, t]);

  const leadsTrend = useMemo(() => {
    if (engagementStatsData) {
      return t('common.percentValue', {
        value: numberWithSpaces(Math.abs(engagementStatsData.leads_trend), lang)
      });
    }

    return '';
  }, [engagementStatsData, lang, t]);

  return (
    <div>
      <LayoutHeader title={t('overview')} />
      <PageHead title={t('pageTitle', { title: t('overview') })} />

      <div className={cls.filter}>
        <DatesFilter from={from} to={to} onDatesChange={onDatesChange} />
      </div>
      <ul className={cls.list}>
        <li>
          <GraphView
            cardStyle={cardStyle}
            data={engagementStatsData?.shows}
            datesRange={datesRange}
            title={t('views.shows')}
            amount={showsAmount}
            trend={showsTrend}
            negativeTrend={
              engagementStatsData && engagementStatsData.shows_trend < 0
            }
            tooltipOverlay={t('views.showsTooltip')}
            dataLoading={engagementStatsLoading}
          />
        </li>

        <li>
          <GraphView
            cardStyle={cardStyle}
            data={engagementStatsData?.views}
            datesRange={datesRange}
            title={t('views.views')}
            amount={viewsAmount}
            conversion={viewsConversion}
            trend={viewsTrend}
            negativeTrend={
              engagementStatsData && engagementStatsData.views_trend < 0
            }
            tooltipOverlay={t('views.viewsTooltip')}
            dataLoading={engagementStatsLoading}
          />
        </li>

        <li>
          <GraphView
            cardStyle={cardStyle}
            data={engagementStatsData?.leads}
            datesRange={datesRange}
            title={t('views.leads')}
            amount={leadsAmount}
            conversion={leadsConversion}
            trend={leadsTrend}
            negativeTrend={
              engagementStatsData && engagementStatsData.leads_trend < 0
            }
            tooltipOverlay={t('views.leadsTooltip')}
            dataLoading={engagementStatsLoading}
            linkTo="/leads"
          />
        </li>

        <li>
          <AdvertsCard />
        </li>

        {permissions.wallet.access && (
          <li>
            <div className={cls.wallet}>
              <BalanceCard
                fromPeriod={fromPeriodUnix}
                toPeriod={toPeriodUnix}
                buttonSize="m"
                flatCard
              />
            </div>
          </li>
        )}
        <li>
          <ExpensesCard expenses={expensesData} from={from} to={to} />
        </li>
      </ul>
    </div>
  );
}
