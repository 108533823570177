import { Skeleton } from '@/ui/Skeleton';

import cls from './RolesPage.module.scss';

export function RoleCardSkeleton() {
  return (
    <div className={cls.card}>
      <div className={cls.card_row}>
        <div>
          <div style={{ marginBottom: 2 }}>
            <Skeleton width={120} height={23} />
          </div>
          <div>
            <Skeleton width={132} height={20} />
          </div>
        </div>

        <div className={cls.card_actions}>
          <Skeleton width={128} height={36} />
        </div>
      </div>
    </div>
  );
}
