import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Nullable } from '@/app/types';
import { UseMyRoles } from '@/modules/roles/hooks';
import { RoleCardSkeleton } from '@/modules/roles/RolesPage/RoleCardSkeleton';
import { RoleForm } from '@/modules/roles/RolesPage/RoleForm/RoleForm';
import { MyRole } from '@/modules/roles/types';
import { Button } from '@/ui/Button/Button';

import cls from './RolesPage.module.scss';

const skeletons = new Array(5).fill(0);

type Props = {
  roles: UseMyRoles;
  search: string;
  isNewRoleOpen: boolean;
  setNewRoleOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

export function RolesTab({
  roles: rolesData,
  search,
  isNewRoleOpen,
  setNewRoleOpen
}: Props) {
  const { t } = useTranslation();
  const [roles, loading, refetch] = rolesData;
  const rolesList = roles || [];

  const searchLow = search.toLowerCase();
  const filtered = search
    ? rolesList.filter((v) => {
        const byName = v.name.toLowerCase().includes(searchLow);
        return byName;
      })
    : rolesList;

  const closeNewRole = () => {
    setNewRoleOpen(false);
  };

  const onRoleCreate = () => {
    refetch();
    closeNewRole();
  };

  // Edit
  const [isEditOpen, setEditOpen] = useState(false);
  const [editingRole, setEditingRole] = useState<Nullable<MyRole>>(null);
  const onEditClick = (e: MyRole) => {
    setEditingRole(e);
    setEditOpen(true);
  };

  const closeEditRole = () => {
    setEditOpen(false);
  };

  const onRoleEdit = () => {
    refetch();
    closeEditRole();
  };

  return (
    <>
      {filtered.length > 0 && !loading && (
        <ul className={cls.list}>
          {filtered.map((r) => (
            <li key={r.id}>
              <div className={cls.card}>
                <div className={cls.card_row}>
                  <div>
                    <p className={cls.card_title}>{r.name}</p>
                    <p className={cls.card_subtitle}>
                      {r.permissions
                        .map((p) => p.category_name)
                        .join(t('common.listSeparator') as string)}
                    </p>
                  </div>

                  <div className={cls.card_actions}>
                    <Button
                      onClick={() => onEditClick(r)}
                      size="compact"
                      variant="secondary"
                      color="black"
                    >
                      {t('common.edit')}
                    </Button>
                  </div>
                </div>
              </div>
            </li>
          ))}
        </ul>
      )}

      {loading && (
        <ul className={cls.list}>
          {skeletons.map((_, i) => (
            <li key={i}>
              <RoleCardSkeleton />
            </li>
          ))}
        </ul>
      )}

      <RoleForm
        isOpen={isNewRoleOpen}
        close={closeNewRole}
        onSave={onRoleCreate}
        type="create"
      />

      {editingRole && (
        <RoleForm
          isOpen={isEditOpen}
          close={closeEditRole}
          onSave={onRoleEdit}
          type="edit"
          role={editingRole}
        />
      )}
    </>
  );
}
