import cn from 'classnames';
import { useMemo } from 'react';

import { useDesktop } from '@/app/common-store';
import { Nullable } from '@/app/types';
import { CloseBtn } from '@/ui/CloseBtn/CloseBtn';
import { BottomModal } from '@/ui/modals/BottomModal/BottomModal';
import {
  CommonModal,
  CommonModalProps
} from '@/ui/modals/CommonModal/CommonModal';

import cls from './AsideModal.module.scss';

interface Props extends CommonModalProps {
  title?: Nullable<string>;
  mobileBottomModal?: boolean;
}

export const AsideModal: React.FC<Props> = ({
  title,
  containerClass,
  children,
  mobileBottomModal = true,
  ...rest
}) => {
  const isDesktop = useDesktop();

  const modalType = useMemo<'common' | 'bottom'>(() => {
    if (isDesktop) return 'common';
    return mobileBottomModal ? 'bottom' : 'common';
  }, [isDesktop, mobileBottomModal]);

  const Modal = modalType === 'bottom' ? BottomModal : CommonModal;

  return (
    <Modal
      containerClass={cn(cls.container, containerClass, {
        [cls.container_common]: modalType === 'common'
      })}
      {...rest}
    >
      {title && (
        <div className={cls.head}>
          <p className={cls.title}>{title}</p>
          <CloseBtn onClick={rest.close} />
        </div>
      )}
      {children}
    </Modal>
  );
};
