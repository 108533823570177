import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';

import { AUTH_LAYOUT } from '@/app/router/layouts';
import { useSignout } from '@/modules/auth/hooks';
import { Button } from '@/ui/Button/Button';
import { CloseEditBtn } from '@/ui/layouts/BaseLayout/CloseEditBtn';
import { HeaderActions } from '@/ui/layouts/BaseLayout/HeaderActions/HeaderActions';
import { loadNotoFont } from '@/ui/layouts/BaseLayout/helpers';
import { useAvailablePathByRole } from '@/ui/layouts/SidebarLayout/Sidebar/Nav/helpers';
import { ConfirmModal } from '@/ui/modals/ConfirmModal/ConfirmModal';

import cls from './BaseLayout.module.scss';

export const HEADER_ID = 'base-layout-header';
export const LOGO_ID = 'base-layout-header-logo';

type Props = {
  children: React.ReactNode;
};

export function BaseLayout({ children }: Props) {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const availablePath = useAvailablePathByRole();

  const isShowroomCreate = pathname.startsWith('/showroom/create');
  const isAuth =
    AUTH_LAYOUT.includes(pathname) ||
    AUTH_LAYOUT.some((v) => pathname.startsWith(v) && pathname !== '/');

  // Signout
  const { isConfirmOpen, setConfirmOpen, onConfirmClick } = useSignout();

  useEffect(() => {
    loadNotoFont();
  }, []);

  return (
    <div className={cls.root}>
      <header className={cls.header} id={HEADER_ID}>
        <div className="container">
          <div className={cls.header_inner}>
            <Link id={LOGO_ID} className={cls.logo_link} to={availablePath}>
              <img src="/img/logo.svg" alt="AUTO.AE" />
            </Link>

            {isAuth ? (
              <HeaderActions />
            ) : (
              <>
                {isShowroomCreate && (
                  <div className={cls.header_row}>
                    <HeaderActions />
                    <Button
                      onClick={() => setConfirmOpen(true)}
                      variant="outline"
                      color="red"
                      size="s"
                    >
                      {t('auth.signout')}
                    </Button>
                  </div>
                )}

                {!isShowroomCreate && <CloseEditBtn />}
              </>
            )}
          </div>
        </div>
      </header>

      {children}

      {isShowroomCreate && (
        <ConfirmModal
          name="baselayout-signout"
          isOpen={isConfirmOpen}
          close={() => setConfirmOpen(false)}
          title={t('auth.signoutConfirm')}
          confirmText={t('common.confirm')}
          cancelText={t('common.cancel')}
          onButtonClick={onConfirmClick}
        />
      )}
    </div>
  );
}
