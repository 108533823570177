import cn from 'classnames';
import { useEffect, useMemo, useState } from 'react';
import { Collapse } from 'react-collapse';

import { CommonObj } from '@/app/types';
import { RoleOptionsCategory, RoleTypeOption } from '@/modules/roles/types';
import { Checkbox } from '@/ui/Checkbox/Checkbox';

import { RoleCategoryIcon, ChevronIcon, CornerIcon } from './icons';
import cls from './RoleOptionsList.module.scss';

const checkboxColor = 'var(--thm-silver-grey-1)';

type Props = {
  category: RoleOptionsCategory;
  categoryIds: number[];
  setCategoryIds: React.Dispatch<React.SetStateAction<number[]>>;
  optionsIds: number[];
  setOptionsIds: React.Dispatch<React.SetStateAction<number[]>>;
  subOptionsIds: number[];
  setSubOptionsIds: React.Dispatch<React.SetStateAction<number[]>>;
};

export function RoleOptionsList({
  category,
  setCategoryIds,
  optionsIds,
  setOptionsIds,
  subOptionsIds,
  setSubOptionsIds
}: Props) {
  const categoryOptionsIds = useMemo(
    () => category.options.map((v) => v.id),
    [category.options]
  );
  const anyCategoryOptionSelected = categoryOptionsIds.some((v) =>
    optionsIds.includes(v)
  );

  useEffect(() => {
    setCategoryIds((prev) => {
      if (anyCategoryOptionSelected) return [...prev, category.id];
      return prev.filter((v) => v !== category.id);
    });
  }, [anyCategoryOptionSelected, category.id, setCategoryIds]);

  const [isOpen, setOpen] = useState(anyCategoryOptionSelected);

  return (
    <div className={cn(cls.root, { [cls.root_open]: isOpen })}>
      <div className={cls.header}>
        <div className={cls.icon}>
          <RoleCategoryIcon id={category.id} />
        </div>
        <p className={cls.title}>{category.name}</p>
        <div className={cls.chevron}>
          <ChevronIcon />
        </div>
        <button
          className={cls.header_btn}
          type="button"
          onClick={() => setOpen((p) => !p)}
        />
      </div>

      <Collapse isOpened={isOpen}>
        <div className={cls.content}>
          {category.options.map((opt) => (
            <RoleOption
              key={opt.id}
              option={opt}
              optionsIds={optionsIds}
              setOptionsIds={setOptionsIds}
              subOptionsIds={subOptionsIds}
              setSubOptionsIds={setSubOptionsIds}
            />
          ))}
        </div>
      </Collapse>
    </div>
  );
}

type RoleOptionProps = {
  option: RoleTypeOption;
  optionsIds: number[];
  setOptionsIds: React.Dispatch<React.SetStateAction<number[]>>;
  subOptionsIds: number[];
  setSubOptionsIds: React.Dispatch<React.SetStateAction<number[]>>;
};

function RoleOption({
  option,
  optionsIds,
  setOptionsIds,
  subOptionsIds,
  setSubOptionsIds
}: RoleOptionProps) {
  const checked = optionsIds.includes(option.id);
  const children = useMemo(
    () => option.sub_options || [],
    [option.sub_options]
  );
  const childrenIds = useMemo(() => children.map((v) => v.id), [children]);

  const toggleOption = (id: number) => () => {
    setOptionsIds((prev) => {
      if (prev.includes(id)) {
        return prev.filter((v) => v !== id);
      }

      return [...prev, id];
    });
  };

  useEffect(() => {
    if (!checked && childrenIds.length > 0) {
      setSubOptionsIds((prev) => prev.filter((v) => !childrenIds.includes(v)));
    }
  }, [checked, childrenIds, setSubOptionsIds]);

  return (
    <>
      <div className={cls.option}>
        <Checkbox
          defaultColor={checkboxColor}
          id={`new-role-option-${option.id}`}
          checked={checked}
          onChange={toggleOption(option.id)}
        >
          {option.name}
        </Checkbox>
      </div>

      {children.length > 0 &&
        children.map((subOption) => (
          <RoleSubOption
            key={subOption.id}
            option={option}
            subOption={subOption}
            optionsIds={optionsIds}
            subOptionsIds={subOptionsIds}
            setSubOptionsIds={setSubOptionsIds}
          />
        ))}
    </>
  );
}

type RoleSubOptionProps = {
  option: RoleTypeOption;
  subOption: CommonObj;
  optionsIds: number[];
  subOptionsIds: number[];
  setSubOptionsIds: React.Dispatch<React.SetStateAction<number[]>>;
};

function RoleSubOption({
  option,
  subOption,
  optionsIds,
  subOptionsIds,
  setSubOptionsIds
}: RoleSubOptionProps) {
  const isOptionChecked = optionsIds.includes(option.id);
  const checked = subOptionsIds.includes(subOption.id);

  const toggleSubOption = (id: number) => () => {
    setSubOptionsIds((prev) =>
      prev.includes(id) ? prev.filter((v) => v !== id) : [...prev, id]
    );
  };

  return (
    <div className={cls.option}>
      <div className={cls.corner}>
        <CornerIcon />
      </div>

      <Checkbox
        defaultColor={checkboxColor}
        id={`new-role-option-${option.id}-${subOption.id}`}
        checked={checked}
        onChange={toggleSubOption(subOption.id)}
        disabled={!isOptionChecked}
      >
        {subOption.name}
      </Checkbox>
    </div>
  );
}
