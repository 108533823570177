import { useMemo } from 'react';

import { AdvertStatusType } from '@/modules/showroom/advert/types';
import { CreateVrpStore } from '@/modules/showroom/advert/vrp/CreateVrp/store';
import { useUpdateVrpStore } from '@/modules/showroom/advert/vrp/UpdateVrp/store';

type StoreType = CreateVrpStore;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const disableCoreChangesFn = (_store: StoreType) => {
  return false;
};

const isPublishedFn = (store: StoreType) => {
  const status = store.detail?.status.title;
  return status === AdvertStatusType.published;
};

const showPromoFn = (store: StoreType) => {
  const status = store.detail?.status.title;
  const showPromo = ![
    AdvertStatusType.published,
    AdvertStatusType.moderation,
    AdvertStatusType.rejected
  ].includes(status as AdvertStatusType);
  return showPromo;
};

export function useVrpAdvertUpdateFlags() {
  const store = useUpdateVrpStore((s) => s);
  const disableCoreChanges = disableCoreChangesFn(store);
  const isPublished = isPublishedFn(store);
  const showPromo = showPromoFn(store);

  return useMemo(
    () => ({ disableCoreChanges, isPublished, showPromo }),
    [disableCoreChanges, isPublished, showPromo]
  );
}
