import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useLang } from '@/hooks/useLang';
import { LANGS_MAP, LANG_NAME_MAP, saveLangInCookie } from '@/modules/i18n';
import { useDarkmode } from '@/modules/theme/useTheme';
import { Button } from '@/ui/Button/Button';
import { Dropdown, DropdownItem } from '@/ui/Dropdown/Dropdown';
import { CheckIcon } from '@/ui/layouts/SidebarLayout/Sidebar/User/icons';

import cls from './HeaderActions.module.scss';
import { LightIcon, DarkIcon, LangIcon } from './icons';

export function HeaderActions() {
  const { t, i18n } = useTranslation();
  const [lang] = useLang();

  // Theme
  const { isDark, toggleDark } = useDarkmode();

  // Lang
  const [isLangOpen, setLangOpen] = useState(false);
  const onLangClick = (loc: string) => {
    i18n.changeLanguage(loc);
    saveLangInCookie(loc);
    setLangOpen(false);
  };

  return (
    <ul className={cls.root}>
      <li>
        <div className={cls.wrap}>
          <Button
            onClick={toggleDark}
            variant="outline"
            size="s"
            color="black"
            gap={8}
          >
            {isDark ? <DarkIcon /> : <LightIcon />}
            <span>{t(isDark ? 'theme.dark' : 'theme.light')}</span>
          </Button>
        </div>
      </li>
      <li>
        <div className={cls.wrap}>
          <Button
            onClick={() => setLangOpen(true)}
            variant="outline"
            size="s"
            color="black"
            gap={8}
          >
            <LangIcon />
            <span>{LANG_NAME_MAP[lang]}</span>
          </Button>

          <Dropdown
            name="header-lang-select"
            isOpen={isLangOpen}
            close={() => setLangOpen(false)}
            alwaysOnTop
          >
            {Object.values(LANGS_MAP).map((loc) => (
              <DropdownItem key={loc}>
                <button
                  className={cls.lang_btn}
                  onClick={() => onLangClick(loc)}
                  type="button"
                >
                  <span
                    style={{ visibility: loc === lang ? 'visible' : 'hidden' }}
                  >
                    <CheckIcon />
                  </span>
                  {LANG_NAME_MAP[loc]}
                </button>
              </DropdownItem>
            ))}
          </Dropdown>
        </div>
      </li>
    </ul>
  );
}
