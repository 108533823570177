import cn from 'classnames';
import { useContext, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useOnceTrue } from '@/hooks/useOnceTrue';
import { MyAdvertsContext } from '@/modules/showroom/advert/my/MyAdverts/my-adverts-ctx';
import { RentAdvertExistedStatus } from '@/modules/showroom/advert/rent/my/types';
import { EMPTY_BRAND_ID } from '@/modules/showroom/advert/search/consts';
import { FilterBtn } from '@/modules/showroom/advert/search/SearchAdverts/Filters/FilterBtn/FilterBtn';
import { FiltersList } from '@/modules/showroom/advert/search/SearchAdverts/Filters/FiltersList/FiltersList';
import { FilterType } from '@/modules/showroom/advert/search/SearchAdverts/Filters/FiltersList/helpers';
import { GenerationsFilters } from '@/modules/showroom/advert/search/SearchAdverts/Filters/GenerationsFilters';
import { ModelFilters } from '@/modules/showroom/advert/search/SearchAdverts/Filters/ModelFilters';
import { AutoFilterIds } from '@/modules/showroom/advert/search/types';
import { AdvertExistedStatus } from '@/modules/showroom/advert/types';
import {
  useMyRentTransportBrands,
  useMyRentTransportGenerations,
  useMyRentTransportModels,
  useMyTransportBrands,
  useMyTransportGenerations,
  useMyTransportModels
} from '@/modules/transport/api';
import {
  TransportBrand,
  TransportGeneration,
  TransportModel
} from '@/modules/transport/types';
import { Dropdown } from '@/ui/Dropdown/Dropdown';
import { MAX_PG_LIMIT_REQ, MAX_PG_LIMIT } from '@/utils/consts';

import cls from './Filters.module.scss';

type Props = {
  autoFilters: AutoFilterIds[];
  updateAutoFilter: (v: AutoFilterIds) => void;
  rent?: boolean;
};

export function AutoFilter({ updateAutoFilter, autoFilters, rent }: Props) {
  const { t } = useTranslation();

  const { status: _status } = useContext(MyAdvertsContext);
  const status = _status as AdvertExistedStatus | null;
  const autoFilter = autoFilters.filter(
    (a) => a.brand_id !== EMPTY_BRAND_ID
  )[0];

  // Brand
  const [isBrandOpen, setBrandOpen] = useState(false);
  const [searchBrand, setSearchBrand] = useState('');
  const onceBrandOpened = useOnceTrue(isBrandOpen);

  const brandsReq = useMemo(() => {
    if (rent) return null;
    if (onceBrandOpened || !!autoFilter?.brand_id) {
      return {
        ...MAX_PG_LIMIT_REQ,
        status: status ? status.status : null
      };
    }
    return null;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onceBrandOpened, status, rent]);
  const [brandsList, brandsListLoading] = useMyTransportBrands(brandsReq);

  const rentBrandsReq = useMemo(() => {
    if (!rent) return null;
    if (onceBrandOpened || !!autoFilter?.brand_id) {
      const s = status as RentAdvertExistedStatus | null;
      return {
        ...MAX_PG_LIMIT_REQ,
        status_id: s ? s.id : null
      };
    }
    return null;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onceBrandOpened, status, rent]);
  const [rentBrandsList, rentBrandsLoading] =
    useMyRentTransportBrands(rentBrandsReq);
  const brands = rent ? rentBrandsList : brandsList;
  const brandsLoading = rent ? rentBrandsLoading : brandsListLoading;

  const brand = brands?.find((b) => b.id === autoFilter?.brand_id);

  const onBrandChange = (v: FilterType) => {
    const b = v as TransportBrand;
    const isSame = b.id === brand?.id;

    setSearchBrand('');
    if (!isSame) {
      updateAutoFilter({ brand_id: b.id, model_id: null, generation_id: null });
    } else {
      updateAutoFilter({ ...autoFilter, brand_id: b.id });
    }
  };

  // Models
  const [isModelOpen, setModelOpen] = useState(false);
  const [searchModel] = useState('');

  const modelsReq = useMemo(
    () =>
      !rent && brand
        ? {
            limit: MAX_PG_LIMIT,
            brand_id: brand.id,
            status: status ? status.status : null
          }
        : null,
    [brand, status, rent]
  );
  const [modelsList, modelsListLoading] = useMyTransportModels(modelsReq);

  const modelsRentReq = useMemo(() => {
    const s = status as RentAdvertExistedStatus | null;
    if (rent && brand)
      return {
        limit: MAX_PG_LIMIT,
        brand_id: brand.id,
        status_id: s ? s.id : null
      };
    return null;
  }, [brand, status, rent]);
  const [rentModelsList, rentModelsLoading] =
    useMyRentTransportModels(modelsRentReq);
  const models = rent ? rentModelsList : modelsList;
  const modelsLoading = rent ? rentModelsLoading : modelsListLoading;
  const model = models?.find((b) => b.id === autoFilter?.model_id);

  const onModelChange = (v: FilterType) => {
    const m = v as TransportModel;
    const isSame = m.id === model?.id;

    if (!isSame) {
      updateAutoFilter({ ...autoFilter, model_id: m.id, generation_id: null });
    } else {
      updateAutoFilter({ ...autoFilter, model_id: m.id });
    }
    if (isModelOpen) setModelOpen(false);
  };

  const onModelReset = () => {
    setBrandOpen(false);
    setModelOpen(false);
    updateAutoFilter({ ...autoFilter, model_id: null, generation_id: null });
  };

  // Gens
  const gensReq = useMemo(
    () =>
      model && !rent
        ? {
            status: status ? status.status : null,
            year_of_issue_id: null,
            model_id: model.id,
            limit: MAX_PG_LIMIT
          }
        : null,
    [model, rent, status]
  );
  const [allGenerationsList, generationsListLoading] =
    useMyTransportGenerations(gensReq);

  const gensRentReq = useMemo(() => {
    const s = status as RentAdvertExistedStatus | null;
    if (model && rent)
      return {
        status_id: s ? s.id : null,
        year_of_issue_id: null,
        model_id: model.id,
        limit: MAX_PG_LIMIT
      };
    return null;
  }, [model, rent, status]);
  const [allGenerationsRentList, generationsRentListLoading] =
    useMyRentTransportGenerations(gensRentReq);

  const allGenerations = rent ? allGenerationsRentList : allGenerationsList;
  const generationsLoading = rent
    ? generationsRentListLoading
    : generationsListLoading;

  const generations = allGenerationsList?.filter((g) => {
    if (Array.isArray(autoFilter?.generation_id)) {
      return autoFilter?.generation_id?.includes(g.id);
    }
    return g.id === autoFilter?.generation_id;
  });

  const onGenerationsChange = (gen: TransportGeneration) => {
    const ids = generations?.map((g) => g.id) || [];

    updateAutoFilter({
      ...autoFilter,
      generation_id: ids.includes(gen.id)
        ? ids.filter((v) => v !== gen.id)
        : [...ids, gen.id]
    });
  };

  const onGenerationsReset = () => {
    setBrandOpen(false);
    updateAutoFilter({ ...autoFilter, generation_id: null });
  };

  // Value text
  const valueText = useMemo(() => {
    if (!brand) return '';

    if (brand && model && generations && generations.length > 0) {
      return t('common.commaSeparated', {
        first: `${brand.name} ${model.name}`,
        second: generations.map((g) => g.name).join(', ')
      });
    }

    if (brand && model) {
      return `${brand.name} ${model.name}`;
    }

    return brand.name;
  }, [brand, generations, model, t]);

  // Reset
  const onResetClick = () => {
    setBrandOpen(false);
    updateAutoFilter({
      brand_id: EMPTY_BRAND_ID,
      model_id: null,
      generation_id: null
    });
  };

  return (
    <>
      <FilterBtn
        text={t('brandModel')}
        onClick={() => setBrandOpen(true)}
        active={isBrandOpen}
        showSearch={isBrandOpen}
        onResetClick={onResetClick}
        value={valueText}
        searchValue={searchBrand}
        onSearchChange={setSearchBrand}
        searchPlaceholder={t('brandSearch')}
      />

      <Dropdown
        className={cn(cls.dropdown, cls.dropdown_imp)}
        name="search-auto-filters-brand"
        isOpen={isBrandOpen}
        close={() => setBrandOpen(false)}
        withModal
        toLeft
      >
        <li className="hide-scrollbar">
          <FiltersList
            showImage={onceBrandOpened}
            label={t('brands')}
            activeId={brand?.id || null}
            list={brands || []}
            loading={brandsLoading}
            onClick={onBrandChange}
            search={searchBrand}
            showArrow
          />
        </li>
        {brand && (
          <li className="hide-scrollbar">
            <ModelFilters
              brand={brand}
              model={model || null}
              models={models}
              onClick={onModelChange}
              onAllClick={onModelReset}
              search={searchModel}
              loading={modelsLoading}
              showArrow
            />
          </li>
        )}
        {model && (
          <li className={cn('hide-scrollbar', cls.generations)}>
            <GenerationsFilters
              model={model}
              generations={generations || []}
              allGenerations={allGenerations}
              onClick={onGenerationsChange}
              onAllClick={onGenerationsReset}
              loading={generationsLoading}
            />
          </li>
        )}
      </Dropdown>
    </>
  );
}
