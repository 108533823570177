import { Nullable, PhotoType } from '@/app/types';
import { ChatBlockStatus } from '@/modules/chat/types';

export enum AccountIdType {
  showroom = 2,
  privatePerson = 1
}

export interface AccountType {
  id: AccountIdType;
  name: string;
}

export interface AccountInfo {
  id: number;
  email: string;
  is_email_confirmed: boolean;
  showroom_created: boolean;
  showroom_created_at: number;
}

export interface AccountDetailRes {
  id: number;
  avatar: Nullable<PhotoType>;
  name: Nullable<string>;
}

export type NullableAccountInfo = Nullable<AccountInfo>;

export interface BalanceReq {
  period_start: number;
  period_end: number;
}

export interface BalanceRes {
  balance: number;
  avg_expense_per_day: number;
  remaining_days: Nullable<string>;
  last_date: Nullable<string>;
}

// Block
export interface BlockUserViaChatReq {
  advertisement_id: number;
}

export type BlockUserViaChatRes = ChatBlockStatus;

// Count view
export interface PeriodCount {
  days: { day: number; count: number }[];
  summary: number;
}
export interface GetViewsCountRes {
  views: PeriodCount;
  calls: PeriodCount;
}

// Payment
export interface MakePaymentReq {
  sessionId: string;
  outletRef: string;
  amount: {
    currencyCode: string;
    value: number;
  };
}
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type MakePaymentRes = Record<string, any>;

export interface TopUpBalanceReq {
  amount: number;
  orderReference: string;
  outletRef: string;
}
