import { useTranslation } from 'react-i18next';

import { Button } from '@/ui/Button/Button';
import { Nav } from '@/ui/layouts/SidebarLayout/Sidebar/Nav/Nav';
// import { Onboarding } from '@/ui/layouts/SidebarLayout/Sidebar/Onboarding/Onboarding';
import { User } from '@/ui/layouts/SidebarLayout/Sidebar/User/User';

import { CloseIcon } from './icons';
import cls from './Sidebar.module.scss';

type Props = {
  closeSidebarModal?: VoidFunction;
};

export function Sidebar({ closeSidebarModal }: Props) {
  const { t } = useTranslation();
  const isSidebarModal = !!closeSidebarModal;

  return (
    <div className={cls.root}>
      <User isSidebarModal={isSidebarModal} />

      <div className={cls.container}>
        <Nav closeSidebarModal={closeSidebarModal} />

        {isSidebarModal && (
          <div className={cls.close_btn}>
            <Button
              color="black"
              variant="secondary"
              size="compact"
              gap={4}
              onClick={closeSidebarModal}
              fullWidth
            >
              <CloseIcon />
              {t('common.close')}
            </Button>
          </div>
        )}

        {/* <div className={cls.onboarding}>
          <Onboarding />
        </div> */}
      </div>
    </div>
  );
}
