import { AuthLayout } from '@/ui/layouts/AuthLayout/AuthLayout';
import { BaseLayout } from '@/ui/layouts/BaseLayout/BaseLayout';
import { EditorLayout } from '@/ui/layouts/EditorLayout/EditorLayout';
import { SidebarLayout } from '@/ui/layouts/SidebarLayout/SidebarLayout';

export const AUTH_LAYOUT = [
  '/signin',
  '/signup',
  '/confirm-email',
  '/password/restore',
  '/dealers/employee/register'
];
const BASE_LAYOUT = ['/showroom/create'];
export const EDITOR_LAYOUT = ['/cars/edit', '/vrp/edit', '/cars/rent/edit'];

export function getLayoutByPath(pathname: string, authed: boolean) {
  if (
    AUTH_LAYOUT.includes(pathname) ||
    AUTH_LAYOUT.some((v) => pathname.startsWith(v) && pathname !== '/')
  ) {
    return AuthLayout;
  }

  if (
    EDITOR_LAYOUT.includes(pathname) ||
    EDITOR_LAYOUT.some((v) => pathname.startsWith(v) && pathname !== '/')
  ) {
    return EditorLayout;
  }

  if (
    BASE_LAYOUT.includes(pathname) ||
    BASE_LAYOUT.some((v) => pathname.startsWith(v) && pathname !== '/')
  ) {
    return BaseLayout;
  }

  return authed ? SidebarLayout : BaseLayout;
}
