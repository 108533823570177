import cn from 'classnames';

import { ImgSkeleton } from '@/ui/ImgSkeleton/ImgSkeleton';
import { Skeleton } from '@/ui/Skeleton';

import cls from './MyAdvertCardMob.module.scss';

const images = new Array(3).fill(0);

export function MyAdvertCardMobSkeleton() {
  return (
    <>
      <div className={cls.root}>
        <div className={cls.images_wrap}>
          <ul className={cn(cls.images, 'hide-scrollbar')}>
            {images.map((_, i) => (
              <li key={i}>
                <ImgSkeleton className={cls.img} showSkeleton />
              </li>
            ))}
          </ul>
        </div>

        <div className={cls.info}>
          <Skeleton
            width={128}
            height={24}
            style={{ display: 'block', marginBottom: 4 }}
          />
          <Skeleton width={156} height={20} />
        </div>

        <ul className={cls.list}>
          <li>
            <Skeleton width={80} height={26} borderRadius={12} />
          </li>
          <li>
            <Skeleton width={56} height={26} borderRadius={12} />
          </li>
          <li>
            <Skeleton width={56} height={26} borderRadius={12} />
          </li>
          <li>
            <Skeleton width={56} height={26} borderRadius={12} />
          </li>
          <li>
            <Skeleton width={56} height={26} borderRadius={12} />
          </li>
        </ul>

        <Skeleton
          width="100%"
          height={36}
          borderRadius={8}
          style={{ marginTop: 8 }}
        />
      </div>
    </>
  );
}
