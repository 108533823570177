import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { useLang } from '@/hooks/useLang';
import { useAccountBalance } from '@/modules/accounts/balance/useAccountBalance';
import { useAccountStore } from '@/modules/accounts/store';
import { usePermissionAccess } from '@/modules/roles/helpers';
import { CategoryDropdown } from '@/modules/showroom/advert/create/CategoryDropdown/CategoryDropdown';
import { useShowroom } from '@/modules/showroom/api';
import { Button } from '@/ui/Button/Button';
import { ButtonLink } from '@/ui/Button/ButtonLink';
import { WalletIcon } from '@/ui/layouts/SidebarLayout/Sidebar/Nav/icons';
import { useLayoutHeaderStore } from '@/ui/layouts/SidebarLayout/store';
import { Skeleton } from '@/ui/Skeleton';
import { ADV_CUR, APP_URL } from '@/utils/consts';
import { numberWithSpaces } from '@/utils/format';

import cls from './Header.module.scss';
import { AddIcon, ArrowIcon, PlusIcon } from './icons';

export function Header() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [lang] = useLang();

  const permissions = usePermissionAccess();
  const showAdCreate =
    permissions.carSale.control ||
    permissions.plateSale.control ||
    permissions.rent.ads.control;

  const { title, buttonText, onButtonClick } = useLayoutHeaderStore((s) => s);
  const { balanceAmount, balanceLoading } = useAccountBalance();

  const accountInfo = useAccountStore((s) => s.accountInfo);
  const [showroom, showroomLoading, fetchShowroom] = useShowroom();
  const showroomUrl = useMemo(() => {
    if (showroom) {
      const { username, id } = showroom;
      if (username) return `${APP_URL}/${username}`;
      return `${APP_URL}/profile/${id}`;
    }

    return '';
  }, [showroom]);

  useEffect(() => {
    if (accountInfo?.showroom_created) {
      fetchShowroom();
    }
  }, [accountInfo?.showroom_created, fetchShowroom]);

  const [isCreateOpen, setCreateOpen] = useState(false);

  return (
    <header className={cls.root}>
      <div className={cls.left}>
        <h1 className={cls.title}>{title}</h1>
        {buttonText && (
          <Button
            onClick={onButtonClick}
            size="s"
            variant="secondary"
            color="black"
            gap={8}
          >
            <AddIcon />
            {buttonText}
          </Button>
        )}
      </div>

      <div className={cls.right}>
        {permissions.wallet.access && (
          <button
            className={cls.balance}
            onClick={() => navigate('/payment/wallet')}
          >
            <WalletIcon />
            <b>
              {!balanceLoading ? (
                t('priceCurrency', {
                  price: numberWithSpaces(balanceAmount, lang),
                  currency: ADV_CUR
                })
              ) : (
                <Skeleton width={64} height={22} />
              )}
            </b>
          </button>
        )}

        <div className={cls.showroom_link}>
          <ButtonLink
            to={showroomUrl || APP_URL}
            loading={showroomLoading}
            externalLink
            color="black"
            size="compact"
            variant="secondary"
            gap={4}
          >
            <ArrowIcon />
            <span>{t('showroom.page')}</span>
          </ButtonLink>
        </div>

        {showAdCreate && (
          <div className={cls.dropdown_wrap}>
            <Button
              onClick={() => setCreateOpen(true)}
              color="green"
              size="compact"
              gap={4}
            >
              <PlusIcon />
              <span>{t('addAdvert')}</span>
            </Button>
            <CategoryDropdown
              isOpen={isCreateOpen}
              close={() => setCreateOpen(false)}
            />
          </div>
        )}
      </div>
    </header>
  );
}
