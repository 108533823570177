'use client';

import { useTranslation } from 'react-i18next';

import { useAvailablePathByRole } from '@/ui/layouts/SidebarLayout/Sidebar/Nav/helpers';
import { Placeholder } from '@/ui/Placeholder/Placeholder';

import cls from './NotFound.module.scss';
import notFoundImg from './notfound.png';

export function NotFound() {
  const { t } = useTranslation();
  const path = useAvailablePathByRole();

  return (
    <div className={cls.root}>
      <Placeholder
        imgSrc={notFoundImg}
        title={t('notFound.title')}
        text={t('notFound.text')}
        buttonText={t('notFound.action')}
        to={path}
        buttonColor="blue"
        buttonVariant="secondary"
      />
    </div>
  );
}
