import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Nullable } from '@/app/types';
import { useLang } from '@/hooks/useLang';
import { usePermissionAccess } from '@/modules/roles/helpers';
import {
  ExpenseCategory,
  getCategoriesWithTotal
} from '@/modules/wallet/expense-helpers';
import { ExpensesCardGraph } from '@/modules/wallet/ExpensesCard/ExpensesCardGraph';
import { ExpensesDetailStatisticsRes } from '@/modules/wallet/types';
import { ButtonLink } from '@/ui/Button/ButtonLink';
import { Card } from '@/ui/Card/Card';
import { ADV_CUR } from '@/utils/consts';
import { numberWithSpaces } from '@/utils/format';

import cls from './ExpensesCard.module.scss';

const cardStyle = {
  height: '100%'
};

type Props = {
  from: Date;
  to: Date;
  expenses: ExpensesDetailStatisticsRes | undefined;
};

export function ExpensesCard({ from, to, expenses }: Props) {
  const { t } = useTranslation();
  const [lang] = useLang();

  const permissions = usePermissionAccess();

  const listData = useMemo<Nullable<ExpenseCategory[]>>(() => {
    if (!expenses) return null;
    return getCategoriesWithTotal(expenses);
  }, [expenses]);

  const totalCount = listData
    ? listData.reduce((acc, cur) => acc + cur.total, 0)
    : 0;

  return (
    <Card flat style={cardStyle}>
      <div className={cls.root}>
        <h2 className={cls.title}>
          <span>{t('wallet.writeOffs')}</span>
          <span>
            {t('priceCurrency', {
              price: numberWithSpaces(totalCount, lang),
              currency: ADV_CUR
            })}
          </span>
        </h2>

        <div>
          {expenses && (
            <ExpensesCardGraph expenses={expenses} from={from} to={to} />
          )}

          {listData && (
            <ul className={cls.list}>
              {listData.map((v) => (
                <li key={v.id}>
                  <div className={cls.expense_row}>
                    <span
                      className={cls.expense_color}
                      style={{ background: v.color }}
                    />
                    <span>{v.name}</span>
                    <span className={cls.expense_count}>
                      {t('priceCurrency', {
                        price: numberWithSpaces(v.total, lang),
                        currency: ADV_CUR
                      })}
                    </span>
                  </div>
                </li>
              ))}
            </ul>
          )}

          {permissions.wallet.access && (
            <ButtonLink
              to="/payment/wallet"
              variant="secondary"
              color="black"
              size="s"
              fullWidth
            >
              {t('common.detailed')}
            </ButtonLink>
          )}
        </div>
      </div>
    </Card>
  );
}
