import cn from 'classnames';
import Tooltip from 'rc-tooltip';
import { useEffect, useState } from 'react';
import { Collapse } from 'react-collapse';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';

import { useCommonStore } from '@/app/common-store';
import { useClientComm } from '@/modules/client-comm/hooks';
import { rcTooltipProps } from '@/utils/consts';
import { getFromStorage, saveInStorage } from '@/utils/local-storage';

import { MappedNavList, useNavList } from './helpers';
import { ArrowIcon, DotIcon } from './icons';
import cls from './Nav.module.scss';

const OPENED_SECTIONS_STORAGE_KEY = 'opened-nav-sections';

const getOpenedSections = (navList: MappedNavList) => {
  const openedSections = getFromStorage<number[]>(OPENED_SECTIONS_STORAGE_KEY);
  return openedSections || navList.map((_navItem, index) => index);
};

type Props = {
  closeSidebarModal?: VoidFunction;
};

export function Nav({ closeSidebarModal }: Props) {
  const { t } = useTranslation();
  const clientComm = useClientComm();
  const navList = useNavList(clientComm);

  const [openedSections, setOpenedSections] = useState<number[]>(
    getOpenedSections(navList)
  );

  const toggleSection = (index: number) => {
    const newOpenedSections = openedSections.includes(index)
      ? [...openedSections.filter((openedIndex) => openedIndex !== index)]
      : [...openedSections, index];
    setOpenedSections(newOpenedSections);
    saveInStorage(OPENED_SECTIONS_STORAGE_KEY, newOpenedSections);
  };

  const restoreOpenedSections = () => {
    setOpenedSections(getOpenedSections(navList));
  };

  useEffect(() => {
    restoreOpenedSections();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const isSidebarModal = !!closeSidebarModal;
  const windowWidth = useCommonStore((s) => s.windowWidth);
  const isSidebarMinimized = useCommonStore((s) => s.isSidebarMinimized);
  const setSidebarMinimized = useCommonStore((s) => s.setSidebarMinimized);
  const isMinimized = isSidebarModal ? false : isSidebarMinimized;

  useEffect(() => {
    if (windowWidth <= 1300) {
      setSidebarMinimized(true, false);
    }
  }, [setSidebarMinimized, windowWidth]);

  const onLinkClick = () => {
    if (isSidebarModal) {
      closeSidebarModal();
    }

    if (windowWidth <= 1300 && !isSidebarMinimized) {
      setSidebarMinimized(true);
    }
  };

  return (
    <nav
      className={cn(cls.root, {
        [cls.root_minimized]: isMinimized
      })}
    >
      <ul className={cls.root_list}>
        {navList.map((nav, index) => {
          const isSectionOpened = openedSections.includes(index);
          const sectionCounter = nav.children.reduce(
            (s, i) => (s = s + (i?.count || 0)),
            0
          );

          return (
            <li key={nav.label}>
              <button
                className={cn(cls.collapse_btn, {
                  [cls.collapse_btn_opened]: isSectionOpened
                })}
                onClick={() => toggleSection(index)}
              >
                {isMinimized ? (
                  <div className={cls.label_dot}>
                    <DotIcon />
                  </div>
                ) : (
                  <>
                    <span className={cls.label}>{t(nav.label)}</span>
                    {!isSectionOpened && !!sectionCounter && (
                      <span className={cls.counter}>{sectionCounter}</span>
                    )}
                  </>
                )}

                {!isMinimized && <ArrowIcon />}
              </button>

              <Collapse isOpened={isSectionOpened}>
                <ul className={cls.list}>
                  {nav.children.map((c) => {
                    const showCount =
                      'count' in c && !!c.count && typeof c.count === 'number';

                    return (
                      <li key={c.path}>
                        <Tooltip
                          placement="right"
                          visible={isMinimized ? undefined : false}
                          overlay={t(c.label)}
                          overlayStyle={{
                            pointerEvents: 'none',
                            userSelect: 'none'
                          }}
                          {...rcTooltipProps}
                        >
                          <NavLink
                            className={({ isActive }) =>
                              cn(cls.list_link, {
                                [cls.list_link_active]: isActive
                              })
                            }
                            to={c.path}
                            end={c.end}
                            onClick={onLinkClick}
                          >
                            <span className={cls.icon}>{c.icon}</span>
                            {!isMinimized ? (
                              <>
                                <span>{t(c.label)}</span>
                                {showCount && (
                                  <span className={cls.count}>{c.count}</span>
                                )}
                              </>
                            ) : (
                              <>
                                {showCount && (
                                  <span className={cls.count_min} />
                                )}
                              </>
                            )}
                          </NavLink>
                        </Tooltip>
                      </li>
                    );
                  })}
                </ul>
              </Collapse>
            </li>
          );
        })}
      </ul>
    </nav>
  );
}
