import cn from 'classnames';
import TextareaAutosize from 'react-textarea-autosize';

import cls from './Textarea.module.scss';
import { TextareaProps as Props } from './TextareaProps';

export const Textarea: React.FC<Props> = (props: Props) => {
  const {
    value,
    onChange,
    onBlur,
    disabled,
    placeholder,
    name,
    errorText,
    minRows,
    maxRows,
    bottomLabel,
    bottomLabelRight
  } = props;

  return (
    <div className={cls.root}>
      <div className={cls.textarea_wrap}>
        <TextareaAutosize
          minRows={minRows}
          maxRows={maxRows}
          className={cn(cls.textarea, {
            [cls.textarea_error]: !!errorText
          })}
          value={value}
          onChange={onChange}
          onBlur={onBlur}
          placeholder={placeholder || ''}
          disabled={disabled}
          name={name}
        />
        <span className={cls.border} />
      </div>

      <span
        className={cn(cls.error_wrap, {
          [cls.error_wrap_active]: !!errorText
        })}
      >
        {errorText && <span className={cls.error}>{errorText}</span>}
      </span>

      {bottomLabel && (
        <span
          className={cn(cls.bottom_label_wrap, {
            [cls.bottom_label_right]: !!bottomLabelRight
          })}
        >
          <span className={cls.bottom_label}>{bottomLabel}</span>
        </span>
      )}
    </div>
  );
};

Textarea.displayName = 'Textarea';
