import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { CommonObj } from '@/app/types';
import { createRole, updateRole } from '@/modules/roles/api';
import { useRoleOptionsCategories } from '@/modules/roles/hooks';
import { RoleOptionsList } from '@/modules/roles/RolesPage/RoleForm/RoleOptionsList/RoleOptionsList';
import { CreateRoleReq, MyRole, RoleTypeOption } from '@/modules/roles/types';
import { Button } from '@/ui/Button/Button';
import { Input } from '@/ui/Input/Input';
import { AsideModal } from '@/ui/modals/AsideModal/AsideModal';
import { Spinner } from '@/ui/Spinner/Spinner';
import { maxLength } from '@/utils/format';
import { showAlert } from '@/utils/network';

import cls from './RoleForm.module.scss';

const MAX_NAME_LEN = 100;

type Props = {
  isOpen: boolean;
  close: () => void;
  onSave: () => void;
  type: 'create' | 'edit';
  role?: MyRole;
};

export function RoleForm({ isOpen, close, onSave, type, role }: Props) {
  const { t } = useTranslation();

  const isCreate = type === 'create';

  const [categories, categoriesLoading] = useRoleOptionsCategories(!isOpen);

  const [loading, setLoading] = useState(false);
  const [name, setName] = useState('');
  const [categoryIds, setCategoryIds] = useState<number[]>([]);
  const [optionsIds, setOptionsIds] = useState<number[]>([]);
  const [subOptionsIds, setSubOptionsIds] = useState<number[]>([]);

  useEffect(() => {
    if (role) {
      setName(role.name);
      setCategoryIds(role.permissions.map((c) => c.category_id));

      const roleOptions = role.permissions.reduce(
        (acc, cur) => [...acc, ...cur.options],
        [] as RoleTypeOption[]
      );
      setOptionsIds(roleOptions.map((opt) => opt.id));

      const roleSubOptIds = roleOptions.reduce((acc, cur) => {
        const subs = cur.sub_options || [];
        return [...acc, ...subs.map((s) => s.id)];
      }, [] as number[]);

      setSubOptionsIds(roleSubOptIds);
    } else {
      setName('');
      setCategoryIds([]);
      setOptionsIds([]);
      setSubOptionsIds([]);
    }
  }, [role]);

  const selectedCategories = useMemo(
    () => categories?.filter((c) => categoryIds.includes(c.id)) || [],
    [categories, categoryIds]
  );

  const isValid = !!name && selectedCategories.length > 0;
  const onSaveClick = async () => {
    setLoading(true);

    try {
      const permissions = selectedCategories.reduce((acc, cat) => {
        const catOptions = cat.options;
        const catOptionsIds = catOptions.map((o) => o.id);
        const catSubOptionsIds = catOptions
          .reduce(
            (subOptions, v) => [...subOptions, ...(v.sub_options || [])],
            [] as CommonObj[]
          )
          .map((s) => s.id);

        const catObj = {
          category_id: cat.id,
          option_ids: catOptionsIds.filter((v) => optionsIds.includes(v)),
          sub_option_ids: catSubOptionsIds.filter((v) =>
            subOptionsIds.includes(v)
          )
        };

        return [...acc, catObj];
      }, [] as CreateRoleReq['permissions']);

      if (isCreate) {
        await createRole({ name, permissions });
      }

      if (!isCreate && role) {
        await updateRole(role.id, { name, permissions });
      }

      showAlert({
        type: 'success',
        text: t(isCreate ? 'roles.roleCreated' : 'common.saved')
      });
      onSave();
    } catch (error) {
      showAlert({ error });
    } finally {
      setLoading(false);
    }
  };

  return (
    <AsideModal
      name={!isCreate && role ? `edit-role-${role.id}-modal` : 'new-role-modal'}
      title={t(isCreate ? 'roles.newRole' : 'roles.editRole')}
      isOpen={isOpen}
      close={close}
      containerClass={cls.modal_container}
    >
      <div className={cls.root}>
        <Input
          value={name}
          onChange={(e) =>
            setName(maxLength(e.currentTarget.value, MAX_NAME_LEN))
          }
          placeholder={t('roles.roleName')}
          disabled={categoriesLoading}
        />

        {!!categories && !categoriesLoading && (
          <>
            {categories.map((c) => (
              <RoleOptionsList
                key={c.id}
                category={c}
                categoryIds={categoryIds}
                setCategoryIds={setCategoryIds}
                optionsIds={optionsIds}
                setOptionsIds={setOptionsIds}
                subOptionsIds={subOptionsIds}
                setSubOptionsIds={setSubOptionsIds}
              />
            ))}
          </>
        )}

        {categoriesLoading && <Spinner color="var(--clr-blue)" centered />}

        <Button
          onClick={onSaveClick}
          loading={loading}
          disabled={loading || categoriesLoading || !isValid}
          color="green"
          fullWidth
        >
          {t('common.save')}
        </Button>
      </div>
    </AsideModal>
  );
}
