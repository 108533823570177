import cn from 'classnames';
import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { OptionI } from '@/app/types';
import { useOnceTrue } from '@/hooks/useOnceTrue';
import { updateEmployeeRole } from '@/modules/roles/api';
import { UseMyRoles } from '@/modules/roles/hooks';
import { RoleForm } from '@/modules/roles/RolesPage/RoleForm/RoleForm';
import { Button } from '@/ui/Button/Button';
import { AsideModal } from '@/ui/modals/AsideModal/AsideModal';
import { Select } from '@/ui/Select/Select';
import { showAlert } from '@/utils/network';

import { AddIcon, CheckIcon } from './InviteEmployee/icons';
import cls from './InviteEmployee/InviteEmployee.module.scss';

type Props = {
  roles: UseMyRoles;
  roleId: number;
  employeeId: number;
  onSave: () => void;
  isOpen: boolean;
  close: () => void;
};

export function EditEmployeeRole({
  roles,
  roleId,
  employeeId,
  onSave,
  isOpen,
  close
}: Props) {
  const { t } = useTranslation();

  // New role
  const [isNewRoleOpen, setNewRoleOpen] = useState(false);
  const newRoleOpenedOnce = useOnceTrue(isNewRoleOpen);

  const [rolesList, , refetchRoles] = roles;
  const closeNewRole = () => {
    setNewRoleOpen(false);
  };
  const onRoleCreate = () => {
    refetchRoles();
    closeNewRole();
  };

  // Role
  const [selectedRoleId, setSelectedRoleId] = useState<number>(roleId);

  // Select
  const createOption = useMemo<OptionI>(
    () => ({ id: 'create', text: t('roles.newRole') }),
    [t]
  );

  const options = useMemo<OptionI[]>(
    () => [
      ...(rolesList?.map((r) => ({
        id: String(r.id),
        text: r.name
      })) || []),
      createOption
    ],
    [createOption, rolesList]
  );

  const option = options.find((opt) => opt.id === String(selectedRoleId));

  const selectOptionRender = useCallback(
    (opt: OptionI, closeDropdown: () => void) => {
      const isActive = option?.id === opt.id;
      const isCreate = opt.id === 'create';

      return (
        <div
          className={cn(cls.select_btn, {
            [cls.select_btn_active]: isActive
          })}
          style={{
            background: isActive ? '0' : undefined
          }}
        >
          <button
            className={cls.select_btn_inner}
            type="button"
            onClick={() => {
              closeDropdown();
              if (isCreate) {
                setNewRoleOpen(true);
              } else {
                setSelectedRoleId(Number(opt.id));
              }
            }}
          />

          <div className={cls.select_btn_icon}>
            {isCreate ? <AddIcon /> : <>{isActive && <CheckIcon />}</>}
          </div>
          <span
            className={cls.select_btn_text}
            style={{ color: isCreate ? 'var(--clr-blue)' : undefined }}
          >
            <span>{opt.text}</span>
          </span>
        </div>
      );
    },
    [option?.id]
  );

  // Save
  const [loading, setLoading] = useState(false);
  const onSaveClick = async () => {
    setLoading(true);

    try {
      await updateEmployeeRole(employeeId, {
        role_id: selectedRoleId
      });
      showAlert({ type: 'success', text: t('common.saved') });
      onSave();
      close();
    } catch (error) {
      showAlert({ error });
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <AsideModal
        name={`edit-employee-${employeeId}-modal`}
        title={t('common.edit')}
        isOpen={isOpen}
        close={close}
        containerClass={cls.modal_container}
      >
        <div className={cls.root}>
          <div className={cls.list}>
            <div className={cls.row}>
              <Select
                name={`edit-employee-role-${employeeId}`}
                label={t('roles.role')}
                value={option}
                onChange={(e) => setSelectedRoleId(Number(e.id))}
                options={options}
                renderOption={selectOptionRender}
                disabled={loading}
              />
            </div>
          </div>

          <Button
            onClick={onSaveClick}
            loading={loading}
            disabled={loading}
            color="green"
            fullWidth
          >
            {t('common.save')}
          </Button>
        </div>
      </AsideModal>

      {newRoleOpenedOnce && (
        <RoleForm
          isOpen={isNewRoleOpen}
          close={closeNewRole}
          onSave={onRoleCreate}
          type="create"
        />
      )}
    </>
  );
}
