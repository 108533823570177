import { nanoid } from 'nanoid';
import { create, StateCreator } from 'zustand';

import { Nullable } from '@/app/types';
import { makeSpecsSlice } from '@/modules/showroom/advert/create/specs-slice';
import { makeCreateAdvertSlice } from '@/modules/showroom/advert/create/store-slice';
import {
  CreateAdvertStore,
  SpecStep
} from '@/modules/showroom/advert/create/types';
import {
  getRentAuthorDetail,
  updateRentAdvertisement
} from '@/modules/showroom/advert/rent/api';
import { RentAdvertAuthorDetail } from '@/modules/showroom/advert/rent/detail/types';
import { createRentTariff } from '@/modules/showroom/advert/rent/tariffs/api';
import { StoreRentTariff } from '@/modules/showroom/advert/rent/tariffs/types';
import { DEFAULT_VALUES } from '@/modules/showroom/advert/rent/update/consts';
import {
  getRentDetailFilledMaps,
  makeRentUpdateAdvertReq,
  sortTariffs
} from '@/modules/showroom/advert/rent/update/helpers';
import { RentUpdateAdvertStore } from '@/modules/showroom/advert/rent/update/store-type';
import { _isEqual } from '@/modules/showroom/advert/update/compare-req';
import { TransportColor } from '@/modules/transport/types';
import { showAlert } from '@/utils/network';

const makeRentUpdateAdvertStore: StateCreator<
  Omit<CreateAdvertStore, 'resetAll'> & RentUpdateAdvertStore,
  [],
  [],
  RentUpdateAdvertStore
> = (set, get) => ({
  bodyColor: null,
  setBodyColor: (bodyColor: Nullable<TransportColor>) => {
    set({ bodyColor });
  },
  interiorColor: null,
  setInteriorColor: (interiorColor: Nullable<TransportColor>) =>
    set({ interiorColor }),
  deposit: null,
  setDeposit: (deposit: Nullable<number>) => set({ deposit }),
  depositReturnDays: null,
  setDepositReturnDays: (depositReturnDays: Nullable<number>) =>
    set({ depositReturnDays }),
  isDepositFilled: false,
  setDepositFilled: (isDepositFilled: boolean) => set({ isDepositFilled }),
  age: DEFAULT_VALUES.age,
  setAge: (age: Nullable<number>) => set({ age }),
  driveExp: DEFAULT_VALUES.driveExp,
  setDriveExp: (driveExp: Nullable<number>) => set({ driveExp }),
  isAgeFilled: false,
  setAgeFilled: (isAgeFilled: boolean) => set({ isAgeFilled }),
  payMethodsIds: [],
  setPayMethodsIds: (payMethodsIds: number[]) => set({ payMethodsIds }),
  isPayMethodIdsFilled: false,
  setPayMethodIdsFilled: (isPayMethodIdsFilled: boolean) =>
    set({ isPayMethodIdsFilled }),
  docsIds: null,
  setDocsIds: (docsIds: Nullable<number[]>) => set({ docsIds }),
  isDocsIdsFilled: false,
  setDocsIdsFilled: (isDocsIdsFilled: boolean) => set({ isDocsIdsFilled }),
  deliveryToCustomer: false,
  setDeliveryToCustomer: (deliveryToCustomer: boolean) =>
    set({ deliveryToCustomer }),
  deliveryToAirport: false,
  setDeliveryToAirport: (deliveryToAirport: boolean) =>
    set({ deliveryToAirport }),
  tariffs: [],
  setTariffs: (tariffs: StoreRentTariff[]) => set({ tariffs }),
  isTariffsFilled: false,
  setTariffsFilled: (isTariffsFilled: boolean) => set({ isTariffsFilled }),
  isBookingAvailable: true,
  setBookingAvailable: (isBookingAvailable: boolean) =>
    set({ isBookingAvailable }),
  isBookingAvailableFilled: false,
  setBookingAvailableFilled: (isBookingAvailableFilled: boolean) =>
    set({ isBookingAvailableFilled }),
  resetAll: (resetBrand?: boolean) => {
    const state = get();

    const brandKeys = resetBrand
      ? { brand: null, resetBrandKey: state.resetBrandKey + 1 }
      : {};

    set({
      ...brandKeys,
      model: null,
      year: null,
      generation: null,
      body: null,
      engine: null,
      drive: null,
      transmission: null,
      modification: null,
      bodyColor: null,
      interiorColor: null,
      isSpecsFilled: false,
      step: SpecStep.year,
      photos: [],
      isPhotosFilled: false,
      description: '',
      isDescrGenerating: false,
      isDescriptionFilled: false,
      deposit: null,
      depositReturnDays: null,
      isDepositFilled: false,
      age: DEFAULT_VALUES.age,
      driveExp: DEFAULT_VALUES.driveExp,
      isAgeFilled: false,
      payMethodsIds: [],
      isPayMethodIdsFilled: false,
      docsIds: null,
      isDocsIdsFilled: false,
      deliveryToCustomer: false,
      deliveryToAirport: false,
      isCustomComplectation: false,
      complectationId: null,
      complectationOptionsItemsIds: [],
      isComplectationFilled: false,
      contacts: null,
      isPhoneFilled: false,
      disableChat: true,
      addressId: null,
      isAddressFilled: false,
      isBookingAvailable: true,
      isBookingAvailableFilled: false,
      tariffs: [],
      isTariffsFilled: false,
      stickers: [],
      promotionPackageId: null,
      autoExtension: false,
      lastSectionVisible: false,
      showPageLeaveWarn: true
    });
  },

  detail: null,
  handleDetail: (detail: RentAdvertAuthorDetail) => {
    const complectationId = detail.complectation?.id || null;
    const complectationOptionsItemsIds = detail.complectation
      ? detail.complectation.complectation_options.reduce<number[]>(
          (acc, cat) => {
            const itemsIds = cat.options.reduce<number[]>((acc2, opt) => {
              return [...acc2, ...opt.items.map((item) => item.id)];
            }, []);

            return [...acc, ...itemsIds];
          },
          []
        )
      : [];

    const isCustomComplectation =
      !complectationId &&
      !detail.complectation?.name &&
      complectationOptionsItemsIds.length > 0;

    const { initialMap, newMap } = getRentDetailFilledMaps(detail);

    set({
      showPageLeaveWarn: true,
      detail,
      advertId: detail.id,
      complectationId,
      isCustomComplectation,
      complectationOptionsItemsIds,
      isComplectationFilled: newMap.isComplectationFilled,
      brand: detail.brand,
      model: detail.model,
      year: detail.year_of_issue,
      generation: detail.generation,
      body: detail.body,
      engine: detail.engine,
      drive: detail.drive_unit,
      transmission: detail.transmission,
      modification: detail.modification,
      bodyColor: detail.body_colour,
      interiorColor: detail.interior_colour,
      isSpecsFilled: newMap.isSpecsFilled,
      step: initialMap.isSpecsFilled ? null : SpecStep.year,
      photos: detail.photos
        ? detail.photos.map((p) => ({
            photo_url: p.photo_url,
            thumbnail_url: p.thumbnail_url,
            // todo: use medium_url
            medium_url: p.photo_url
          }))
        : [],
      isPhotosFilled: newMap.isPhotosFilled,
      description: detail.description || '',
      isDescriptionFilled: newMap.isDescriptionFilled,
      deposit: detail.deposit,
      depositReturnDays: detail.deposit_return_days,
      isDepositFilled: newMap.isDepositFilled,
      age: typeof detail.age === 'number' ? detail.age : DEFAULT_VALUES.age,
      driveExp:
        typeof detail.driving_experience === 'number'
          ? detail.driving_experience
          : DEFAULT_VALUES.driveExp,
      isAgeFilled: newMap.isAgeFilled,
      payMethodsIds: detail.pay_methods
        ? detail.pay_methods.map((p) => p.id)
        : [],
      isPayMethodIdsFilled: newMap.isPayMethodIdsFilled,
      docsIds: detail.docs ? detail.docs.map((p) => p.id) : null,
      isDocsIdsFilled: newMap.isDocsIdsFilled,
      deliveryToCustomer: !!detail.delivery_to_customer,
      deliveryToAirport: !!detail.delivery_to_airport,
      addressId: detail.address?.id || null,
      isAddressFilled: newMap.isAddressFilled,
      disableChat:
        detail.available_chat === null ? true : !detail.available_chat,
      isPhoneFilled: newMap.isPhoneFilled,
      contacts:
        detail.communication_method
          ?.filter((c) => !!c.showroom_contact)
          ?.map((c) => ({
            id: c.showroom_contact?.id as number,
            communication_method: {
              phone: {
                available: !!c.available_phone,
                call_after: c.call_after,
                call_before: c.call_before
              },
              whatsapp: {
                available: !!c.available_whatsapp
              }
            }
          })) || [],
      isBookingAvailable: detail.available_booking,
      isBookingAvailableFilled: true,
      stickers: detail.stickers?.map((s) => s.id) || [],
      tariffs: detail.tariffs
        ? sortTariffs(detail.tariffs).map((tf) => {
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            const { id, ...rest } = tf;
            return { ...rest, localId: nanoid() };
          })
        : [],
      isTariffsFilled: newMap.isTariffsFilled
    });
  },
  saveDetail: (id: number) => {
    return new Promise((resolve, reject) => {
      const state = get();

      getRentAuthorDetail(id).then(async (r) => {
        const detail = r.data;
        const tariffsSame = _isEqual(
          sortTariffs(detail.tariffs || []),
          sortTariffs(state.tariffs)
        );
        let tariff_ids = undefined;
        if (!tariffsSame) {
          const promises = state.tariffs.map((tf) => createRentTariff(tf));
          const results = await Promise.all(promises);
          tariff_ids = results.map((r) => r.data).map((tf) => tf.id);
        }

        const req = { ...makeRentUpdateAdvertReq(state, detail), tariff_ids };
        updateRentAdvertisement(id, req)
          .then(() => resolve())
          .catch((error) => {
            showAlert({ error });
            reject();
          });
      });
    });
  }
});

export const useRentUpdateAdvertStore = create<
  CreateAdvertStore & RentUpdateAdvertStore
>()((...rest) => ({
  ...makeSpecsSlice(...rest),
  ...makeCreateAdvertSlice(...rest),
  ...makeRentUpdateAdvertStore(...rest)
}));
