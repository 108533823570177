import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useLang } from '@/hooks/useLang';
import { useAccountBalancePeriod } from '@/modules/accounts/balance/useAccountBalance';
import { BalanceReq, BalanceRes } from '@/modules/accounts/types';
import { usePermissionAccess } from '@/modules/roles/helpers';
import { TopUpModal } from '@/modules/showroom/tariff/TopUpModal/TopUpModal';
import { AutoReplenishment } from '@/modules/wallet/AutoReplenishment/AutoReplenishment';
import { Button } from '@/ui/Button/Button';
import { ButtonProps } from '@/ui/Button/ButtonProps';
import { Card } from '@/ui/Card/Card';
import { Skeleton } from '@/ui/Skeleton';
import { ADV_CUR } from '@/utils/consts';
import { getDateString } from '@/utils/date';
import { numberWithSpaces, pluralKey } from '@/utils/format';

import cls from './BalanceCard.module.scss';

const MIN_AVG_EXPENSE_PER_DAY = '<1';
const cardStyle = {
  minHeight: 350
};

type Props = {
  fromPeriod: number;
  toPeriod: number;
  flatCard?: boolean;
  buttonSize?: ButtonProps['size'];
  balanceInfo?: BalanceRes;
  loading?: boolean;
  style?: React.CSSProperties;
};

export function BalanceCard({
  fromPeriod,
  toPeriod,
  flatCard,
  buttonSize,
  balanceInfo: balanceInfoProp,
  loading,
  style
}: Props) {
  const { t } = useTranslation();
  const [lang] = useLang();

  const permissions = usePermissionAccess();

  const balancePeriod = useMemo<BalanceReq>(
    () => ({
      period_start: fromPeriod,
      period_end: toPeriod
    }),
    [fromPeriod, toPeriod]
  );

  const {
    balanceInfo: balanceInfoFromHook,
    balanceAmount,
    balanceLoading: balanceLoadingFromHook
  } = useAccountBalancePeriod(balancePeriod, !!loading);
  const balanceInfo = balanceInfoProp || balanceInfoFromHook;
  const balanceLoading = loading || balanceLoadingFromHook;

  const [isTopUpOpen, setTopUpOpen] = useState(false);

  const averageConsumption = useMemo(() => {
    if (!balanceInfo || balanceInfo.avg_expense_per_day <= 0)
      return MIN_AVG_EXPENSE_PER_DAY;

    return Math.round(balanceInfo.avg_expense_per_day);
  }, [balanceInfo]);

  const daysLeftValue = useMemo(() => {
    const daysLeft =
      balanceInfo && balanceInfo.remaining_days
        ? Number(balanceInfo.remaining_days)
        : 0;

    const formatted = numberWithSpaces(daysLeft, lang);
    return daysLeft
      ? pluralKey(
          daysLeft,
          t('days.plural.one', {
            value: formatted
          }),
          t('days.plural.few', {
            value: formatted
          }),
          t('days.plural.many', {
            value: formatted
          })
        )
      : '';
  }, [balanceInfo, lang, t]);

  const balanceRest = useMemo(() => {
    if (!balanceInfo || !balanceInfo.last_date || !daysLeftValue) return '';
    const leftUntilDate = new Date(Number(balanceInfo.last_date) * 1000);
    return t('wallet.balanceRest', {
      daysLeft: daysLeftValue,
      leftUntilDate: getDateString(leftUntilDate, lang, {
        year: 'numeric'
      })
    });
  }, [balanceInfo, daysLeftValue, lang, t]);

  return (
    <>
      <Card style={{ ...cardStyle, ...style }} flat={flatCard}>
        <div className={cls.wallet}>
          <header>
            <h2>{t('wallet.title')}</h2>
            {balanceLoading ? (
              <Skeleton width={276} height={20} />
            ) : (
              <p>
                {t('wallet.averageConsumption')}
                <span className={cls.blue}>
                  {t('priceCurrency', {
                    price: averageConsumption,
                    currency: ADV_CUR
                  })}
                </span>
              </p>
            )}
          </header>

          <div className={cls.wallet_amount}>
            {balanceLoading ? (
              <Skeleton width={136} height={41} />
            ) : (
              <b>
                {t('priceCurrency', {
                  price: numberWithSpaces(balanceAmount, lang),
                  currency: ADV_CUR
                })}
              </b>
            )}

            {balanceLoading ? (
              <Skeleton width={300} height={20} />
            ) : (
              <>{balanceRest && <p>{balanceRest}</p>}</>
            )}
          </div>

          {permissions.wallet.control && (
            <div className={cls.popup}>
              <AutoReplenishment />

              <Button
                onClick={() => setTopUpOpen(true)}
                color="green"
                size={buttonSize}
                fullWidth
              >
                {t('wallet.topUp')}
              </Button>
            </div>
          )}
        </div>
      </Card>

      <TopUpModal isOpen={isTopUpOpen} setOpen={setTopUpOpen} />
    </>
  );
}
