import cn from 'classnames';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { InView } from 'react-intersection-observer';
import { LongPressEventType, useLongPress } from 'use-long-press';

import { Nullable } from '@/app/types';
import { useLang } from '@/hooks/useLang';
import { MAX_MEDIA_CONTENT_LEN } from '@/modules/chat/Detail/Messages/helpers';
import {
  Actions,
  ActionType
} from '@/modules/chat/Detail/Messages/Message/Actions/Actions';
import { ContentDoc } from '@/modules/chat/Detail/Messages/Message/ContentDoc/ContentDoc';
import { ContentLoc } from '@/modules/chat/Detail/Messages/Message/ContentLoc/ContentLoc';
import { ContentMedia } from '@/modules/chat/Detail/Messages/Message/ContentMedia/ContentMedia';
import { MessageHighlight } from '@/modules/chat/Detail/Messages/Message/MessageHighlight';
import { RepliedMsg } from '@/modules/chat/Detail/Messages/Message/RepliedMsg/RepliedMsg';
import { useChatDetailStore } from '@/modules/chat/Detail/store';
import { useQuickRepliesStore } from '@/modules/chat/QuickReplies/store';
import {
  ChatMessageAuthor,
  ChatMessageWithReplied
} from '@/modules/chat/types';
import { Lottie } from '@/ui/Lottie';
import { ConfirmModal } from '@/ui/modals/ConfirmModal/ConfirmModal';
import { diffInMinutes, formatDate } from '@/utils/date';
import { isAndroid } from '@/utils/detect';
import { showAlert } from '@/utils/network';

import {
  handleText,
  isDocContent,
  isImageContent,
  isLocationContent,
  isVideoContent,
  MAX_EDIT_TIME_MIN
} from './helpers';
import { MyTailIcon, TailIcon } from './icons';
import cls from './Message.module.scss';

let highlightTimer = -1;

const detect = isAndroid()
  ? LongPressEventType.Mouse
  : LongPressEventType.Touch;

type Props = {
  message: ChatMessageWithReplied;
  showTail?: boolean;
  hasMargin?: boolean;
  largeRadius?: boolean;
};

export function Message({ message, showTail, hasMargin, largeRadius }: Props) {
  const { t } = useTranslation();
  const [lang] = useLang();

  const scrollToMsg = useChatDetailStore((s) => s.scrollToMsg);
  const penultMsgId = useChatDetailStore((s) => s.penultMsgId);
  const lastMsgId = useChatDetailStore((s) => s.lastMsgId);
  const actionsMessageId = useChatDetailStore((s) => s.actionsMessageId);
  const addUnreadMessage = useChatDetailStore((s) => s.addUnreadMessage);
  const setActionsMessageId = useChatDetailStore((s) => s.setActionsMessageId);
  const openMsgEdit = useChatDetailStore((s) => s.openMsgEdit);
  const openMsgReply = useChatDetailStore((s) => s.openMsgReply);
  const deleteMessage = useChatDetailStore((s) => s.deleteMessage);
  const saveQuickReply = useQuickRepliesStore((s) => s.saveQuickReply);

  // Flags
  const isPenult = message.id === penultMsgId();
  const isLast = message.id === lastMsgId();
  const isOwn = message.author === ChatMessageAuthor.my;
  const isSystem = message.author === ChatMessageAuthor.system;
  const mustBeRead = !isOwn && !message.is_read;

  // Content
  const mediaContent = message.content?.filter(
    (c) => isImageContent(c.type) || isVideoContent(c.type)
  );
  const locContent = message.content?.filter((c) => isLocationContent(c.type));
  const docContent = message.content?.filter((c) => isDocContent(c.type));

  // Date
  const createdDate = useMemo(
    () => new Date(message.created_at * 1000),
    [message.created_at]
  );
  const time = formatDate(createdDate, 'p', lang);

  const updatedDate = useMemo(
    () => (message.updated_at ? new Date(message.updated_at * 1000) : null),
    [message.updated_at]
  );
  const updatedTime = updatedDate ? formatDate(updatedDate, 'p', lang) : '';

  const isFloatBottom = useMemo(() => {
    if (message.text || (docContent && docContent.length > 0)) return false;
    return true;
  }, [docContent, message.text]);

  // Actions
  const rootRef = useRef<HTMLDivElement>(null);
  const msgRef = useRef<HTMLDivElement>(null);
  const [isActionsOpen, setActionsOpen] = useState(false);
  const isActionsOpened = isActionsOpen && actionsMessageId === message.id;
  const [msgRect, setMsgRect] = useState<DOMRect | null>(null);
  const [rootTop, setRootTop] = useState<number | null>(null);

  const openActions = () => {
    if (rootRef && rootRef.current && msgRef && msgRef.current) {
      setActionsMessageId(message.id);
      setActionsOpen((prev) => !prev);
      setMsgRect(msgRef.current.getBoundingClientRect());
      setRootTop(rootRef.current.offsetTop);
    }
  };

  const onCtxMenuClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.preventDefault();
    openActions();
  };

  const longPressBind = useLongPress(
    (e) => {
      e.preventDefault();
      openActions();
    },
    {
      detect
    }
  );

  // Edit
  const isEdited =
    !!message.updated_at && message.updated_at > message.created_at;
  const canEdit = isOwn && !!message.text;

  const onEditClick = () => {
    if (!canEdit) return;
    const now = new Date();

    if (updatedDate && diffInMinutes(now, updatedDate) >= MAX_EDIT_TIME_MIN) {
      showAlert({
        type: 'error',
        text: t('chat.messages.editExpired')
      });
    } else {
      openMsgEdit(message);
    }

    setActionsOpen(false);
  };

  // Reply
  const onReplyClick = () => {
    openMsgReply(message);
    setActionsOpen(false);
  };

  // Delete
  const canDelete = isOwn;
  const [isDeleteOpen, setDeleteOpen] = useState(false);
  const onDeleteSubmit = () => {
    setDeleteOpen(false);
    deleteMessage(message.id, t('chat.messages.actions.deleted'));
  };

  const onDeleteClick = () => {
    setActionsOpen(false);
    setDeleteOpen(true);
  };

  const onAddQuickReply = (text?: Nullable<string>) => {
    setActionsOpen(false);
    if (!text) return;

    saveQuickReply(text);
  };

  const handleActionClick = (type: ActionType, text?: Nullable<string>) => {
    if (type === 'reply') {
      onReplyClick();
    }

    if (type === 'edit') {
      onEditClick();
    }

    if (type === 'delete') {
      onDeleteClick();
    }

    if (type === 'addQuickReply') {
      onAddQuickReply(text);
    }
  };

  // Highlight message
  const highlightMessageId = useChatDetailStore((s) => s.highlightMessageId);
  const setHighlightMessageId = useChatDetailStore(
    (s) => s.setHighlightMessageId
  );
  useEffect(() => {
    if (highlightMessageId === message.id) {
      if (msgRef.current) setMsgRect(msgRef.current.getBoundingClientRect());
      if (rootRef.current) setRootTop(rootRef.current.offsetTop);
      window.clearTimeout(highlightTimer);
      highlightTimer = window.setTimeout(() => {
        setHighlightMessageId(null);
      }, 800);
    }
  }, [highlightMessageId, message.id, setHighlightMessageId]);

  useEffect(() => {
    return () => {
      window.clearTimeout(highlightTimer);
    };
  }, []);

  return (
    <>
      {actionsMessageId === message.id &&
        !isSystem &&
        !!msgRect &&
        rootTop !== null && (
          <Actions
            isOpen={isActionsOpened}
            close={() => setActionsOpen(false)}
            own={isOwn}
            top={rootTop}
            rect={msgRect}
            listTop={isLast || isPenult}
            // showEdit={canEdit}
            // showDelete={canDelete}
            showEdit={false}
            showDelete={false}
            onActionClick={handleActionClick}
            messageText={message.text}
          />
        )}

      {rootTop && msgRect && (
        <MessageHighlight
          active={highlightMessageId === message.id}
          top={rootTop}
          height={msgRect.height + 2}
        />
      )}

      <div
        ref={rootRef}
        data-msg-id={message.id}
        className={cn(cls.root, {
          [cls.root_own]: isOwn,
          [cls.root_companion]: !isOwn,
          [cls.root_media]: mediaContent && mediaContent.length > 0,
          [cls.root_loc]: locContent && locContent.length > 0,
          [cls.root_system]: isSystem,
          [cls.root_margin]: hasMargin,
          [cls.root_edited]: isEdited,
          [cls.root_tail]: showTail,
          [cls.root_radius]: largeRadius,
          [cls.root_last]: isLast,
          [cls.root_actions]: isActionsOpened
        })}
      >
        {isSystem ? (
          <p
            className={cls.system_text}
            dangerouslySetInnerHTML={{
              __html: message.text ? handleText(message.text) : ''
            }}
          />
        ) : (
          <div
            className={cls.message}
            ref={msgRef}
            onContextMenu={onCtxMenuClick}
            {...longPressBind()}
          >
            {/* Replied msg */}
            {message.replied_message && (
              <RepliedMsg
                msg={message.replied_message}
                isOwn={isOwn}
                onClick={() => {
                  if (message.replied_message)
                    scrollToMsg(message.replied_message.id);
                }}
              />
            )}

            {/* Location */}
            {locContent && locContent[0] && locContent[0].coords && (
              <ContentLoc
                lat={locContent[0].coords.lat}
                long={locContent[0].coords.long}
              />
            )}

            {/* Doc / file */}
            {docContent && docContent[0] && (
              <ContentDoc doc={docContent[0]} own={isOwn} />
            )}

            {/* Photos / videos */}
            {mediaContent && mediaContent.length > 0 && (
              <ContentMedia
                content={mediaContent.slice(0, MAX_MEDIA_CONTENT_LEN)}
              />
            )}

            {/* Message text */}
            {message.text && (
              <p
                className={cn(cls.text, {
                  [cls.text_media]: mediaContent && mediaContent.length > 0
                })}
                dangerouslySetInnerHTML={{
                  __html: message.text
                    ? handleText(message.text, false, true)
                    : ''
                }}
              />
            )}

            {/* Time, read icon */}
            <div
              className={cn(cls.bottom, {
                [cls.bottom_float]: isFloatBottom
              })}
            >
              {isEdited && (
                <i className={cls.edited}>{t('chat.messages.edited')}</i>
              )}

              <i className={cls.time}>{isEdited ? updatedTime : time}</i>

              {!!message.is_local && (
                <div className={cls.wait_icon}>
                  <Lottie
                    src={isFloatBottom ? 'sending_white' : 'sending_blue'}
                    loop
                    autoplay
                  />
                </div>
              )}

              {!message.is_local && isOwn && (
                <div className={cls.read_icon}>
                  {message.is_read ? (
                    <Lottie
                      src={isFloatBottom ? 'read_white' : 'read_blue'}
                      autoplay
                      loop={false}
                    />
                  ) : (
                    <Lottie
                      src={isFloatBottom ? 'unread_white' : 'unread_blue'}
                      autoplay
                      loop={false}
                    />
                  )}
                </div>
              )}
            </div>

            {/* Message tail */}
            {showTail && (
              <div className={cls.tail}>
                {isOwn ? <MyTailIcon /> : <TailIcon />}
              </div>
            )}
          </div>
        )}

        {mustBeRead && (
          <InView
            as="div"
            onChange={(visible) => {
              if (visible) {
                addUnreadMessage(message.id);
              }
            }}
          >
            <div className={cls.observer} />
          </InView>
        )}
      </div>

      {canDelete && (
        <ConfirmModal
          name="message-delete-confirm"
          isOpen={isDeleteOpen}
          close={() => setDeleteOpen(false)}
          onButtonClick={(confirmed) => {
            if (confirmed) {
              onDeleteSubmit();
            } else {
              setDeleteOpen(false);
            }
          }}
          title={t('chat.messages.actions.deleteConfirm')}
          confirmText={t('common.delete')}
          cancelText={t('common.cancel')}
        />
      )}
    </>
  );
}
