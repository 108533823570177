import { Dispatch, SetStateAction, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { useInterval } from '@/hooks/useInterval';
import { useRefState } from '@/hooks/useRefState';
import { CODE_LEN, COUNTER } from '@/modules/auth/helpers';
import { SplittedInput } from '@/ui/Input/SplittedInput';
import { Lottie } from '@/ui/Lottie';
import { CloseIcon } from '@/ui/PhoneConfirm/PhoneCodeConfirm/icons';
import { SUPPORT_EMAIl } from '@/utils/consts';
import { showAlert } from '@/utils/network';

import cls from './EmailCodeConfirm.module.scss';

type Props = {
  email: string;
  confirmEmailSendCode: (email: string) => Promise<unknown>;
  confirmEmail: (email: string, code: string) => Promise<unknown>;
  isEmailConfirmed: boolean;
  setEmailConfirmed: Dispatch<SetStateAction<boolean>>;
  close?: () => void;
  isEdit?: boolean;
};

export function EmailCodeConfirm({
  email,
  confirmEmailSendCode,
  confirmEmail,
  isEmailConfirmed,
  setEmailConfirmed,
  close,
  isEdit
}: Props) {
  const { t } = useTranslation();

  const [isLoading, setLoading] = useState(false);
  const [needEmailSupport, setEmailSupport] = useState(false);
  const [code, setCode] = useState('');

  // Counter
  const [counter, setCounter, counterRef] = useRefState<number>(COUNTER);
  useInterval(
    () => {
      const v = counterRef.current;
      if (v > 0) setCounter(v - 1);
    },
    counter > 0 ? 1000 : null
  );

  const resendCode = async () => {
    await confirmEmailSendCode(email);

    setCounter(COUNTER);
    setEmailSupport(true);
  };

  const onResendClick = async () => {
    setLoading(true);

    try {
      await resendCode();
    } catch (error) {
      showAlert({ error });
    } finally {
      setLoading(false);
    }
  };

  // Submit
  const isValid = !!email && code.length === CODE_LEN;

  const submit = async (codeValue: string) => {
    if (!codeValue || !email || isLoading) return;
    setLoading(true);

    try {
      await confirmEmail(email, codeValue);
      setEmailConfirmed(true);
    } catch (error) {
      showAlert({ error });
    } finally {
      setLoading(false);
    }
  };

  const onKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    const isOnlyEnter = e.key === 'Enter' && !e.shiftKey;
    if (isValid && !isLoading && isOnlyEnter) {
      submit(code);
    }
  };

  const handleCodeChange = (v: string) => {
    setCode(v);

    const isValid = !!email && v.length === CODE_LEN;
    if (isValid && !isLoading) {
      submit(v);
    }
  };

  return (
    <div className={cls.root}>
      {!isEmailConfirmed && (
        <>
          <div className={cls.icon}>
            <Lottie src="mail" />
          </div>

          <h1 className={cls.title}>{t('auth.enterCode')}</h1>

          <h2 className={cls.code_title}>
            <Trans
              t={t}
              i18nKey={`auth.emailConfirm`}
              components={{
                emailWrap: <span />
              }}
              values={{ email }}
            />
          </h2>

          <div className={cls.fields}>
            <SplittedInput
              value={code}
              onChange={handleCodeChange}
              length={CODE_LEN}
              onLastKeyDown={onKeyDown}
              disabled={isLoading}
              numbersOnly
            />
          </div>

          {!needEmailSupport && (
            <div className={cls.resend}>
              {counter > 0 ? (
                <p>
                  {t('auth.resendCode', {
                    seconds: counter > 9 ? counter : `0${counter}`
                  })}
                </p>
              ) : (
                <button
                  className={cls.resend_btn}
                  type="button"
                  onClick={onResendClick}
                  disabled={!email || isLoading}
                >
                  {t('auth.sendAnotherCode')}
                </button>
              )}
            </div>
          )}

          {needEmailSupport && (
            <div className={cls.resend}>
              <a
                className={cls.resend_btn}
                href={
                  t('auth.supportEmail', {
                    email: SUPPORT_EMAIl,
                    phone: email
                  }) || ''
                }
                target="_blank"
                rel="noreferrer"
              >
                {t('auth.didntGetCode')}
              </a>
            </div>
          )}
        </>
      )}

      {isEmailConfirmed && (
        <>
          <div className={cls.icon}>
            <Lottie src="congrats" />
          </div>

          <h1 className={cls.title}>{t('auth.done')}</h1>

          <h2 className={cls.subtitle}>
            {isEdit ? t('auth.emailEdited') : t('auth.emailAdded')}
          </h2>
        </>
      )}

      <button className={cls.close_btn} onClick={close}>
        <CloseIcon />
      </button>
    </div>
  );
}
