import {
  createBrowserRouter,
  Navigate,
  RouterProvider
} from 'react-router-dom';

import { AuthRoute } from '@/app/router/AuthRoute';
import { PrivateRoute } from '@/app/router/PrivateRoute';
import { ConfirmEmail } from '@/modules/auth/ConfirmEmail/ConfirmEmail';
import { RestorePassword } from '@/modules/auth/RestorePassword/RestorePassword';
import { RestorePasswordConfirm } from '@/modules/auth/RestorePassword/RestorePasswordConfirm';
import { SigninPage } from '@/modules/auth/Signin/SigninPage';
import { SignupPage } from '@/modules/auth/Signup/SignupPage';
import { MyBookingsPage } from '@/modules/booking/MyBookings/MyBookingsPage';
import { CallbackPageWrap } from '@/modules/callback/CallbackPage/CallbackPageWrap';
import { ChatPage } from '@/modules/chat/ChatPage';
import { ChatDetailWrap } from '@/modules/chat/Detail/Detail';
import { CreditRequestsPageWrap } from '@/modules/credit/CreditRequests/CreditRequestsPageWrap';
import { LeadsPage } from '@/modules/leads/LeadsPage/LeadsPage';
import { NotificationsPage } from '@/modules/notifications/NotificationsPage';
import { Overview } from '@/modules/overview/Overview';
import { PaymentTopUpRoute } from '@/modules/payment/PaymentTopUp/PaymentTopUpRoute';
import { ReviewsPage } from '@/modules/reviews/ReviewsPage/ReviewsPage';
import { EmployeeAcceptInvite } from '@/modules/roles/RolesPage/EmployeeAcceptInvite/EmployeeAcceptInvite';
import { RolesPage } from '@/modules/roles/RolesPage/RolesPage';
import { AddressPage } from '@/modules/showroom/address/AddressPage/AddressPage';
import {
  CARS_ADVERTS_URL,
  RENT_ADVERTS_URL,
  VRP_ADVERTS_URL
} from '@/modules/showroom/advert/my/helpers';
import { MyAdvertsCars } from '@/modules/showroom/advert/my/MyAdverts/MyAdvertsCars';
import { MyAdvertsPlates } from '@/modules/showroom/advert/my/MyAdverts/MyAdvertsPlates';
import { MyRentAdverts } from '@/modules/showroom/advert/rent/my/MyRentAdverts';
import { UpdateRentAdvertWrap } from '@/modules/showroom/advert/rent/update/UpdateRentAdvert/UpdateRentAdvertWrap';
import { UpdateAdvertWrap } from '@/modules/showroom/advert/update/UpdateAdvert/UpdateAdvertWrap';
import { UpdateVrpWrap } from '@/modules/showroom/advert/vrp/UpdateVrp/UpdateVrpWrap';
import { ContactsPage } from '@/modules/showroom/contact/ContactsPage';
import { ShowroomAbout } from '@/modules/showroom/CreateShowroom/About';
import { ShowroomContact } from '@/modules/showroom/CreateShowroom/Contact';
import { CreateShowroomPage } from '@/modules/showroom/CreateShowroom/CreateShowroomPage';
import { ShowroomDesign } from '@/modules/showroom/CreateShowroom/Design';
import { ShowroomPhoto } from '@/modules/showroom/CreateShowroom/Photo';
import { ShowroomSuccess } from '@/modules/showroom/CreateShowroom/Success';
import { ShowroomUsername } from '@/modules/showroom/CreateShowroom/Username';
import { SettingsPage } from '@/modules/showroom/settings/SettingsPage';
import { TariffsPage } from '@/modules/showroom/tariff/TariffsPage/TariffsPage';
import { UpdateShowroom } from '@/modules/showroom/UpdateShowroom/UpdateShowroom';
import { WalletPage } from '@/modules/wallet/WalletPage';
import { NotFound } from '@/ui/NotFound/NotFound';

import { ErrorRoute } from './Error';
import { RootLayout } from './RootLayout';

const router = createBrowserRouter([
  {
    path: '/',
    element: <RootLayout />,
    errorElement: <ErrorRoute />,
    children: [
      {
        path: '/',
        element: <PrivateRoute />,
        children: [
          { index: true, element: <Overview /> },
          { path: '/cars/edit/:id', element: <UpdateAdvertWrap /> },
          { path: '/vrp/edit/:id', element: <UpdateVrpWrap /> },
          { path: '/cars/rent/edit/:id', element: <UpdateRentAdvertWrap /> },
          {
            path: '/chat',
            element: <ChatPage />,
            children: [{ path: ':id', element: <ChatDetailWrap /> }]
          },
          { path: '/callback', element: <CallbackPageWrap /> },
          { path: '/credits/requests', element: <CreditRequestsPageWrap /> },
          { path: '/leads', element: <LeadsPage /> },
          {
            path: '/adverts',
            element: <Navigate to={CARS_ADVERTS_URL} replace />
          },
          { path: CARS_ADVERTS_URL, element: <MyAdvertsCars /> },
          { path: VRP_ADVERTS_URL, element: <MyAdvertsPlates /> },
          { path: RENT_ADVERTS_URL, element: <MyRentAdverts /> },
          { path: '/booking/rent', element: <MyBookingsPage /> },
          {
            path: '/showroom/create/',
            element: <CreateShowroomPage />,
            children: [
              {
                index: true,
                element: <Navigate to="/showroom/create/about" replace />
              },
              { path: 'about', element: <ShowroomAbout /> },
              { path: 'design', element: <ShowroomDesign /> },
              { path: 'username', element: <ShowroomUsername /> },
              { path: 'photo', element: <ShowroomPhoto /> },
              { path: 'contact', element: <ShowroomContact /> },
              { path: 'success', element: <ShowroomSuccess /> }
            ]
          },
          { path: '/showroom/general', element: <UpdateShowroom /> },
          { path: '/showroom/address', element: <AddressPage /> },
          { path: '/showroom/contacts', element: <ContactsPage /> },
          { path: '/showroom/contacts/:tabId', element: <ContactsPage /> },
          { path: '/showroom/reviews', element: <ReviewsPage /> },
          { path: '/showroom/notifications', element: <NotificationsPage /> },
          { path: '/showroom/roles', element: <RolesPage /> },
          { path: '/payment/tariffs', element: <TariffsPage /> },
          { path: '/payment/wallet', element: <WalletPage /> },
          { path: '/payment/topup', element: <PaymentTopUpRoute /> },
          { path: '/settings', element: <SettingsPage /> },
          { path: '/settings/:tabId', element: <SettingsPage /> }
        ]
      },
      {
        path: '/',
        element: <AuthRoute />,
        children: [
          {
            path: '/signin',
            element: <SigninPage />
          },
          {
            path: '/signup',
            element: <SignupPage />
          },
          {
            path: '/confirm-email',
            element: <ConfirmEmail />
          },
          {
            path: '/password/restore',
            element: <RestorePassword />
          },
          {
            path: '/password/restore/do/:token',
            element: <RestorePasswordConfirm />
          },
          {
            path: '/dealers/employee/register/:token',
            element: <EmployeeAcceptInvite />
          }
        ]
      },
      {
        path: '*',
        element: <NotFound />
      }
    ]
  }
]);

export function AppRouter() {
  return <RouterProvider router={router} />;
}
