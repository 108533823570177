import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useImgPlaceholder } from '@/hooks/useImgPlaceholder';
import { useLang } from '@/hooks/useLang';
import { useWhatsAppUrl } from '@/hooks/useWhatsAppUrl';
import { markCreditRequestsAsViewed } from '@/modules/credit/api';
import {
  MailIcon,
  PhoneIcon
} from '@/modules/credit/CreditRequests/CreditRequest/icons';
import { CreditRequestType } from '@/modules/credit/types';
import { WhatsAppIcon } from '@/modules/leads/LeadsPage/Leads/LeadRow/icons';
import { useAdvertName } from '@/modules/showroom/advert/hooks';
import { Avatar } from '@/ui/Avatar/Avatar';
import { ButtonLink } from '@/ui/Button/ButtonLink';
import { ImgSkeleton } from '@/ui/ImgSkeleton/ImgSkeleton';
import { ADV_CUR, APP_URL } from '@/utils/consts';
import { isThisYear, isToday, isYesterday } from '@/utils/date';
import { numberWithSpaces } from '@/utils/format';
import { showAlert } from '@/utils/network';

import cls from './CreditRequestMob.module.scss';

type Props = {
  data: CreditRequestType;
  firstPayPercent: number;
};

export function CreditRequestMob({ data, firstPayPercent }: Props) {
  const { t } = useTranslation();
  const [lang] = useLang();

  const ad = data.ad_info;
  const mod = ad.modification;

  const createdStr = useMemo(() => {
    const createdDate = new Date(data.created_at * 1000);
    const today = isToday(createdDate);
    const yesterday = isYesterday(createdDate);
    const thisYear = isThisYear(createdDate);

    const dateStr = createdDate.toLocaleDateString(lang, {
      day: '2-digit',
      month: '2-digit',
      year: thisYear ? undefined : '2-digit',
      hour: '2-digit',
      minute: '2-digit'
    });

    if (today || yesterday) {
      const str = today ? t('common.today') : t('common.yesterday');
      return `${str}, ${dateStr}`;
    }

    return dateStr;
  }, [data.created_at, lang, t]);

  const advertUrl = ad.url ? `${APP_URL}/${ad.url}` : '';
  const adName = useAdvertName(ad);
  const { email, phone } = data;
  const whatsAppUrl = useWhatsAppUrl(phone);

  const hasActions = !!email || !!phone || !!whatsAppUrl;

  const imgPlaceholder = useImgPlaceholder();

  const [viewed, setViewed] = useState(data.has_been_viewed);
  const [markLoading, setMarkLoading] = useState(false);

  const onHover = async () => {
    if (markLoading || viewed) return;
    setMarkLoading(true);

    try {
      await markCreditRequestsAsViewed({ ids: [data.id] });
      setViewed(true);
    } catch (error) {
      showAlert({ error });
    } finally {
      setMarkLoading(false);
    }
  };

  useEffect(() => {
    setViewed(data.has_been_viewed);
  }, [data.has_been_viewed]);

  return (
    <div className={cls.root} onPointerDown={onHover}>
      {!viewed && <span className={cls.view_badge} />}

      <div className={cls.row}>
        <a
          className={cls.link}
          href={advertUrl || ''}
          target="_blank"
          rel="noreferrer"
        />

        <div className={cls.img}>
          <ImgSkeleton
            className={cls.img_inner}
            src={data.ad_info.thumbnail_url || imgPlaceholder}
            alt=""
            imgProxyWidth={85}
            imgProxyHeight={66}
          />
        </div>

        <div>
          <p className={cls.name}>{adName}</p>

          {ad.price && (
            <p className={cls.price}>
              {t('priceCurrency', {
                price: numberWithSpaces(ad.price, lang),
                currency: ADV_CUR
              })}
            </p>
          )}

          {mod && (
            <p className={cls.mod}>
              {t('common.commaSeparated', {
                first: t('horsepower', {
                  value: mod.horse_power?.name || ''
                }),
                second: `${mod.volume?.short_name} ${mod.transmission.short_name}`
              })}
            </p>
          )}
        </div>
      </div>

      <div className={cls.loan}>
        <div className={cls.loan_row}>
          <p>{t('credits.req.amount')}</p>
          <b>
            {t('priceCurrency', {
              price: numberWithSpaces(Number(data.amount), lang),
              currency: ADV_CUR
            })}
          </b>
        </div>
        <div className={cls.loan_row}>
          <p>{t('credits.req.firstPay')}</p>
          <b>
            {t('priceCurrency', {
              price: numberWithSpaces(Number(data.down_payment), lang),
              currency: ADV_CUR
            })}{' '}
            ({firstPayPercent}%)
          </b>
        </div>
        <div className={cls.loan_row}>
          <p>{t('credits.req.monthlyPay')}</p>
          <b>
            {t('credits.req.monthlyPayValue', {
              value: t('priceCurrency', {
                price: numberWithSpaces(Number(data.payment), lang),
                currency: ADV_CUR
              })
            })}
          </b>
        </div>
      </div>

      <div className={cls.footer}>
        <Avatar size={36} user={{ id: data.id, name: data.name || '' }} />

        <div>
          <p
            className={cls.customer_name}
            style={{ maxWidth: hasActions ? undefined : 200 }}
          >
            {data.name}
          </p>
          <p className={cls.date}>{createdStr}</p>
        </div>

        {hasActions && (
          <div className={cls.actions}>
            {!!email && (
              <ButtonLink
                externalLink
                to={`mailto:${email}`}
                variant="outline"
                color="black"
                size="compact"
              >
                <MailIcon />
              </ButtonLink>
            )}

            {whatsAppUrl && (
              <ButtonLink
                externalLink
                to={whatsAppUrl}
                variant="outline"
                color="black"
                size="compact"
              >
                <WhatsAppIcon />
              </ButtonLink>
            )}

            {phone && (
              <div className={cls.phone}>
                <ButtonLink
                  externalLink
                  to={`tel:${phone}`}
                  variant="outline"
                  color="black"
                  size="compact"
                  gap={4}
                >
                  <PhoneIcon />
                  <span>{t('common.call')}</span>
                </ButtonLink>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
}
