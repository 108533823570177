import { nanoid } from 'nanoid';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useOnceTrue } from '@/hooks/useOnceTrue';
import { inviteEmployee } from '@/modules/roles/api';
import { UseMyRoles } from '@/modules/roles/hooks';
import { InviteRow } from '@/modules/roles/RolesPage/InviteEmployee/InviteRow';
import { RoleForm } from '@/modules/roles/RolesPage/RoleForm/RoleForm';
import { InviteEmployeeReq } from '@/modules/roles/types';
import { Button } from '@/ui/Button/Button';
import { AsideModal } from '@/ui/modals/AsideModal/AsideModal';
import { showAlert } from '@/utils/network';
import { validateEmail } from '@/utils/validate';

import { AddIcon } from './icons';
import cls from './InviteEmployee.module.scss';

const EMPTY_ROLE_ID = -1;
const getInitialInviteObj = () => {
  return { id: nanoid(), email: '', role_id: EMPTY_ROLE_ID };
};

type Props = {
  roles: UseMyRoles;
  isOpen: boolean;
  close: () => void;
};

export function InviteEmployee({ roles, isOpen, close }: Props) {
  const { t } = useTranslation();

  // New role
  const [isNewRoleOpen, setNewRoleOpen] = useState(false);
  const newRoleOpenedOnce = useOnceTrue(isNewRoleOpen);

  const [, , refetchRoles] = roles;
  const closeNewRole = () => {
    setNewRoleOpen(false);
  };
  const onRoleCreate = () => {
    refetchRoles();
    closeNewRole();
  };

  // Invites
  const [invites, setInvites] = useState<InviteEmployeeReq['invites']>([
    getInitialInviteObj()
  ]);

  useEffect(() => {
    if (isOpen) {
      setInvites([getInitialInviteObj()]);
    }
  }, [isOpen]);

  const addInvite = () => {
    setInvites((prev) => [...prev, getInitialInviteObj()]);
  };

  const onRowChange = useCallback(
    (id: string, v: { email: string; role_id: number }) => {
      setInvites((prev) =>
        prev.map((invite) => (invite.id === id ? { ...invite, ...v } : invite))
      );
    },
    []
  );

  const isValid =
    invites.length > 0 &&
    invites.every((v) => validateEmail(v.email) && v.role_id !== EMPTY_ROLE_ID);

  const [loading, setLoading] = useState(false);
  const onInviteClick = async () => {
    setLoading(true);

    try {
      await inviteEmployee({
        invites: invites.map(({ role_id, email }) => ({ role_id, email }))
      });
      showAlert({ type: 'success', text: t('roles.invite.sent') });
      close();
    } catch (error) {
      showAlert({ error });
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <AsideModal
        name="invite-employee-modal"
        title={t('roles.invite.members')}
        isOpen={isOpen}
        close={close}
        containerClass={cls.modal_container}
      >
        <div className={cls.root}>
          <div className={cls.list}>
            {invites.map((v) => (
              <InviteRow
                key={v.id}
                id={v.id || ''}
                email={v.email}
                roleId={v.role_id}
                onChange={onRowChange}
                roles={roles}
                onNewRoleClick={() => setNewRoleOpen(true)}
                disabled={loading}
              />
            ))}
          </div>

          <Button
            onClick={addInvite}
            gap={4}
            size="compact"
            variant="secondary"
            color="black"
            disabled={loading}
          >
            <AddIcon />
            {t('roles.invite.more')}
          </Button>

          <Button
            onClick={onInviteClick}
            loading={loading}
            disabled={loading || !isValid}
            color="green"
            fullWidth
          >
            {t('roles.invite.label')}
          </Button>
        </div>
      </AsideModal>

      {newRoleOpenedOnce && (
        <RoleForm
          isOpen={isNewRoleOpen}
          close={closeNewRole}
          onSave={onRoleCreate}
          type="create"
        />
      )}
    </>
  );
}
