import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useDebounce } from '@/hooks/useDebounce';
import { useAuthStore } from '@/modules/auth/store';
import {
  changeCallbackStatus,
  markCallbackAsViewed
} from '@/modules/callback/api-req';
import { CallbackOffState } from '@/modules/callback/CallbackPage/CallbackOffState/CallbackOffState';
import { CallbackPage } from '@/modules/callback/CallbackPage/CallbackPage';
import { CallbackStatus } from '@/modules/callback/CallbackPage/CallbackStatus/CallbackStatus';
import { useCallbackStatus } from '@/modules/callback/hooks';
import { MarkIcon, SettingsIcon } from '@/modules/credit/CreditRequests/icons';
import { Button } from '@/ui/Button/Button';
import { InputSearch } from '@/ui/InputSearch/InputSearch';
import { LayoutHeader } from '@/ui/layouts/SidebarLayout/LayoutHeader';
import { AsideModal } from '@/ui/modals/AsideModal/AsideModal';
import { PageHead } from '@/ui/PageHead/PageHead';
import { showAlert } from '@/utils/network';

import cls from './CallbackPage.module.scss';

export function CallbackPageWrap() {
  const { t } = useTranslation();

  const auth = useAuthStore((s) => s.auth);
  const isOwner = !!auth?.is_owner;

  const [hasNotViewed, setHasNotViewed] = useState(false);
  const [markAllLoading, setMarkAllLoading] = useState(false);
  const [isSettingsOpen, setSettingsOpen] = useState(false);
  const [updateKey, setUpdateKey] = useState(0);

  const [search, setSearch] = useState('');
  const debouncedSearch = useDebounce(search, 800);

  const [saving, setSaving] = useState(false);
  const [enabled, setEnabled] = useState(false);

  const [statusData, statusDataLoading, loadStatusData] = useCallbackStatus();

  const onMarkAllClick = async () => {
    setMarkAllLoading(true);

    try {
      await markCallbackAsViewed(undefined, { mark_all: true });
      setHasNotViewed(false);
      setUpdateKey((p) => p + 1);
    } catch (error) {
      showAlert({ error });
    } finally {
      setMarkAllLoading(false);
    }
  };

  useEffect(() => {
    if (statusData) setEnabled(statusData.result);
  }, [statusData, setEnabled]);

  const saveStatusOnClickHandler = async () => {
    setSaving(true);

    try {
      await changeCallbackStatus(enabled);
      setSettingsOpen(false);
      loadStatusData();
    } catch (error) {
      showAlert({ error });
    } finally {
      setSaving(false);
    }
  };

  return (
    <>
      <LayoutHeader title={t('callback.title')} />
      <PageHead title={t('pageTitle', { title: t('callback.title') })} />

      {isOwner && !statusDataLoading && !!statusData && !statusData?.result && (
        <CallbackOffState openSettings={() => setSettingsOpen(true)} />
      )}

      <div className={cls.toolbar}>
        <div className={cls.search_field}>
          <InputSearch
            value={search}
            onChange={setSearch}
            placeholder={t('common.search')}
            showClear
            compact
            outlined
          />
        </div>

        <div className={cls.toolbar_row}>
          {hasNotViewed && (
            <Button
              onClick={onMarkAllClick}
              loading={markAllLoading}
              disabled={markAllLoading}
              variant="outline"
              color="black"
              size="compact"
              gap={4}
            >
              <MarkIcon />
              {t('credits.req.markAll')}
            </Button>
          )}
          {isOwner && (
            <Button
              variant="outline"
              color="black"
              size="compact"
              gap={4}
              onClick={() => setSettingsOpen(true)}
            >
              <SettingsIcon />
              {t('callback.settings')}
            </Button>
          )}
        </div>
      </div>

      <div className={cls.wrap}>
        <CallbackPage
          key={updateKey + debouncedSearch}
          search={debouncedSearch}
          setHasNotViewed={setHasNotViewed}
        />
      </div>

      {isOwner && (
        <AsideModal
          name="callback-settings-modal"
          title={t('callback.title')}
          isOpen={isSettingsOpen}
          close={() => setSettingsOpen(false)}
        >
          <CallbackStatus
            value={enabled}
            disabled={saving || !statusData}
            onSwitch={() => setEnabled((prev) => !prev)}
          />
          <div className={cls.saveBtn}>
            <Button
              onClick={saveStatusOnClickHandler}
              disabled={saving || statusDataLoading}
              loading={saving || statusDataLoading}
              fullWidth
              variant="primary"
              color="green"
              size="l"
              gap={4}
            >
              {t('common.save')}
            </Button>
          </div>
        </AsideModal>
      )}
    </>
  );
}
