import { Nullable } from '@/app/types';
import { Model } from '@/modules/showroom/advert/create/Model/Model';
import { useAdvertUpdateFlags } from '@/modules/showroom/advert/update/hooks';
import { useUpdateAdvertStore } from '@/modules/showroom/advert/update/store';
import { TransportBrand, TransportModel } from '@/modules/transport/types';

type Props = {
  brand: TransportBrand;
};

export function ModelWrap({ brand }: Props) {
  const { disableCoreChanges } = useAdvertUpdateFlags();
  const model = useUpdateAdvertStore((s) => s.model);
  const setModel = useUpdateAdvertStore((s) => s.setModel);
  const resetAll = useUpdateAdvertStore((s) => s.resetAll);

  const onModelChange = (m: Nullable<TransportModel>) => {
    if (!m) {
      resetAll();
    } else {
      setModel(m);
    }
  };

  return (
    <Model
      brand={brand}
      model={model}
      onChange={onModelChange}
      disabled={disableCoreChanges}
    />
  );
}
