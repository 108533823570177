import { axios } from '@/app/api';
import { CHAT_API_ROUTES } from '@/modules/chat/api';
import {
  ChatGreetingConfigReq,
  ChatStartConfigReq,
  GetQuickReplyReq,
  GetQuickReplyRes,
  SaveQuickReplyReq,
  SaveQuickReplyRes
} from '@/modules/chat/types';

// Quick replies
export function getQuickReplies(params?: GetQuickReplyReq) {
  return axios.get<GetQuickReplyRes>(CHAT_API_ROUTES.quickReplies, { params });
}

export function saveQuickReply(req: SaveQuickReplyReq) {
  return axios.post<SaveQuickReplyRes>(CHAT_API_ROUTES.quickReplies, req);
}

export function deleteQuickReply(quick_reply_id: number) {
  return axios.delete<unknown>(CHAT_API_ROUTES.quickReplies, {
    params: { quick_reply_id }
  });
}

// Chat settings
export function editStartPage(req: ChatStartConfigReq) {
  return axios.post<SaveQuickReplyRes>(CHAT_API_ROUTES.editStartPage, req);
}

export function editGreetingMessage(req: ChatGreetingConfigReq) {
  return axios.post<SaveQuickReplyRes>(
    CHAT_API_ROUTES.editGreetingMessage,
    req
  );
}
