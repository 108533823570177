import cn from 'classnames';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useOnceTrue } from '@/hooks/useOnceTrue';
import { EMPTY_BRAND_ID } from '@/modules/showroom/advert/search/consts';
import { FilterBtn } from '@/modules/showroom/advert/search/SearchAdverts/Filters/FilterBtn/FilterBtn';
import cls from '@/modules/showroom/advert/search/SearchAdverts/Filters/Filters.module.scss';
import { FiltersList } from '@/modules/showroom/advert/search/SearchAdverts/Filters/FiltersList/FiltersList';
import { FilterType } from '@/modules/showroom/advert/search/SearchAdverts/Filters/FiltersList/helpers';
import { ModelFilters } from '@/modules/showroom/advert/search/SearchAdverts/Filters/ModelFilters';
import { AutoFilterIds } from '@/modules/showroom/advert/search/types';
import {
  useMyTransportBrands,
  useMyTransportModels
} from '@/modules/transport/api';
import { TransportBrand, TransportModel } from '@/modules/transport/types';
import { Dropdown } from '@/ui/Dropdown/Dropdown';
import { MAX_PG_LIMIT_REQ, MAX_PG_LIMIT } from '@/utils/consts';

type Props = {
  autoFilters: AutoFilterIds[];
  updateAutoFilter: (v: AutoFilterIds) => void;
};

export function AutoFilter({ updateAutoFilter, autoFilters }: Props) {
  const { t } = useTranslation();

  const autoFilter = autoFilters.filter(
    (a) => a.brand_id !== EMPTY_BRAND_ID
  )[0];

  // Brand
  const [isBrandOpen, setBrandOpen] = useState(false);
  const [searchBrand, setSearchBrand] = useState('');
  const onceBrandOpened = useOnceTrue(isBrandOpen);

  const brandsReq = useMemo(() => {
    if (onceBrandOpened || !!autoFilter?.brand_id) {
      return {
        ...MAX_PG_LIMIT_REQ,
        status: null
      };
    }
    return null;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onceBrandOpened]);
  const [brands, brandsLoading] = useMyTransportBrands(brandsReq);

  const brand = brands?.find((b) => b.id === autoFilter?.brand_id);
  const onBrandChange = (v: FilterType) => {
    const b = v as TransportBrand;
    const isSame = b.id === brand?.id;

    setSearchBrand('');
    if (!isSame) {
      updateAutoFilter({ brand_id: b.id, model_id: null, generation_id: null });
    } else {
      updateAutoFilter({ ...autoFilter, brand_id: b.id });
    }
  };

  // Models
  const [, setModelOpen] = useState(false);
  const [searchModel] = useState('');

  const modelsReq = useMemo(
    () =>
      brand
        ? {
            limit: MAX_PG_LIMIT,
            brand_id: brand.id,
            status: null
          }
        : null,
    [brand]
  );
  const [models, modelsLoading] = useMyTransportModels(modelsReq);

  const model = models?.find((b) => b.id === autoFilter?.model_id);
  const onModelChange = (v: FilterType) => {
    const m = v as TransportModel;
    const isSame = m.id === model?.id;

    if (!isSame) {
      updateAutoFilter({ ...autoFilter, model_id: m.id, generation_id: null });
    } else {
      updateAutoFilter({ ...autoFilter, model_id: m.id });
    }
    setBrandOpen(false);
    setModelOpen(false);
  };

  const onModelReset = () => {
    setBrandOpen(false);
    setModelOpen(false);
    updateAutoFilter({ ...autoFilter, model_id: null, generation_id: null });
  };

  // Value text
  const valueText = useMemo(() => {
    if (!brand) return '';

    if (brand && model) {
      return `${brand.name} ${model.name}`;
    }

    return brand.name;
  }, [brand, model]);

  // Reset
  const onResetClick = () => {
    setBrandOpen(false);
    updateAutoFilter({
      brand_id: EMPTY_BRAND_ID,
      model_id: null,
      generation_id: null
    });
  };

  return (
    <>
      <FilterBtn
        text={t('brandModel')}
        onClick={() => setBrandOpen(true)}
        active={isBrandOpen}
        showSearch={isBrandOpen}
        onResetClick={onResetClick}
        value={valueText}
        searchValue={searchBrand}
        onSearchChange={setSearchBrand}
        searchPlaceholder={t('brandSearch')}
        solidBorder
      />

      <Dropdown
        className={cn(cls.dropdown, cls.dropdown_imp)}
        name="search-auto-filters-brand"
        isOpen={isBrandOpen}
        close={() => setBrandOpen(false)}
        withModal
        toLeft
      >
        <li className="hide-scrollbar">
          <FiltersList
            showImage={onceBrandOpened}
            label={t('brands')}
            activeId={brand?.id || null}
            list={brands || []}
            loading={brandsLoading}
            onClick={onBrandChange}
            search={searchBrand}
            showArrow
          />
        </li>
        {brand && (
          <li className="hide-scrollbar">
            <ModelFilters
              brand={brand}
              model={model || null}
              models={models}
              onClick={onModelChange}
              onAllClick={onModelReset}
              search={searchModel}
              loading={modelsLoading}
            />
          </li>
        )}
      </Dropdown>
    </>
  );
}
