import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { InView } from 'react-intersection-observer';

import { useDesktop } from '@/app/common-store';
import { CreditRequest } from '@/modules/credit/CreditRequests/CreditRequest/CreditRequest';
import { CreditRequestSkeleton } from '@/modules/credit/CreditRequests/CreditRequest/CreditRequestSkeleton';
import { CreditRequestMob } from '@/modules/credit/CreditRequests/CreditRequestMob/CreditRequestMob';
import { CreditRequestMobSkeleton } from '@/modules/credit/CreditRequests/CreditRequestMob/CreditRequestMobSkeleton';
import {
  UseCreditRequestsPg,
  useCreditRequestsPg
} from '@/modules/credit/hooks';
import { GetCreditRequestsReq } from '@/modules/credit/types';
import { DESKTOP_LG_BREAKPOINT } from '@/utils/consts';

import cls from './CreditRequests.module.scss';

const skeletonsArr = new Array(4).fill(null);

type Props = {
  search: string;
  onDataChange: (data: UseCreditRequestsPg['data']) => void;
  orderBy?: GetCreditRequestsReq['order_by'];
};

export function CreditRequests({ search, onDataChange, orderBy }: Props) {
  const { t } = useTranslation();

  const isDesktop = useDesktop(DESKTOP_LG_BREAKPOINT);

  const {
    isAllLoaded,
    isLoading,
    response,
    data: list,
    load
  } = useCreditRequestsPg(search, orderBy);

  const fetchData = () => {
    if (isAllLoaded || isLoading) return;
    load(list ? list.length : 0);
  };

  useEffect(() => {
    onDataChange(list);
  }, [onDataChange, list]);

  return (
    <>
      {response && list && list.length > 0 && (
        <ul className={cls.list}>
          {list.map((c) => (
            <li key={c.ad_url + c.name}>
              {isDesktop ? (
                <CreditRequest
                  data={c}
                  firstPayPercent={response.first_pay_in_percent}
                />
              ) : (
                <CreditRequestMob
                  data={c}
                  firstPayPercent={response.first_pay_in_percent}
                />
              )}
            </li>
          ))}
        </ul>
      )}

      {!isLoading && response && list && list.length <= 0 && (
        <div className={cls.empty}>
          {search ? (
            <p>{t('credits.req.searchEmpty', { search })}</p>
          ) : (
            <>
              <b>{t('credits.req.message')}</b>
              <p>{t('credits.req.empty')}</p>
            </>
          )}
        </div>
      )}

      {isLoading && (
        <ul className={cls.list}>
          {skeletonsArr.map((_s, i) => (
            <li key={i}>
              {isDesktop ? (
                <CreditRequestSkeleton />
              ) : (
                <CreditRequestMobSkeleton />
              )}
            </li>
          ))}
        </ul>
      )}

      {!isAllLoaded && (
        <InView
          as="div"
          onChange={(visible) => {
            if (visible && !isLoading) {
              fetchData();
            }
          }}
        >
          <div className={cls.observer} />
        </InView>
      )}
    </>
  );
}
