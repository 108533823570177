import cn from 'classnames';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Outlet, useNavigate, useParams } from 'react-router-dom';

import { useDebounce } from '@/hooks/useDebounce';
import { ChatList } from '@/modules/chat/ChatList/ChatList';
import { Header } from '@/modules/chat/ChatModal/Header/Header';
import { ChatSettings } from '@/modules/chat/ChatSettings/ChatSettings';
import { ChatsSearch } from '@/modules/chat/ChatsSearch/ChatsSearch';
import { useChatBgImage, useMyChats } from '@/modules/chat/hooks';
import {
  ChatAdCategory,
  ChatSimple,
  ChatStatus,
  ChatWithFoundedMessage,
  GetMyChatsReq
} from '@/modules/chat/types';
import { useChatUtilsStore } from '@/modules/chat/utils-store';
import { LayoutHeader } from '@/ui/layouts/SidebarLayout/LayoutHeader';
import { PageHead } from '@/ui/PageHead/PageHead';
import { MAX_PG_LIMIT_REQ } from '@/utils/consts';

import cls from './ChatPage.module.scss';

export function ChatPage() {
  const { t } = useTranslation();

  const navigate = useNavigate();
  const setScrollToChatMsgId = useChatUtilsStore((s) => s.setScrollToChatMsgId);
  const bgImg = useChatBgImage();

  const { id } = useParams();
  const chatId = id ? Number(id) : null;

  // Filters
  const [adCategory, setAdCategory] = useState<ChatAdCategory>(
    ChatAdCategory.all
  );
  const [chatStatus, setChatStatus] = useState<ChatStatus>(ChatStatus.all);
  const chatsReq = useMemo<GetMyChatsReq>(
    () => ({
      ...MAX_PG_LIMIT_REQ,
      ad_category: adCategory,
      chat_status: chatStatus
    }),
    [adCategory, chatStatus]
  );

  // Chats
  const [chats, chatsLoading] = useMyChats(chatsReq);
  const selectedChat = chats?.find((c) => c.id === chatId) || null;
  const showEmpty = !chatsLoading && chats && chats.length <= 0;

  const onChatClick = (c: ChatSimple) => {
    navigate(`/chat/${c.id}`);
  };

  // Search
  const [searchText, setSearchText] = useState('');
  const debouncedSearch = useDebounce(searchText, 800);
  const onSearchChatClick = (c: ChatSimple) => {
    onChatClick(c);
  };
  const onSearchChatMsgClick = (c: ChatWithFoundedMessage) => {
    const chat = chats ? chats.find((v) => v.id === c.id) : null;
    if (chat && c.founded_message) {
      onChatClick(chat);
      setScrollToChatMsgId(c.founded_message.id);
    }
  };

  return (
    <div className={cls.root}>
      <LayoutHeader title={t('chat.one')} />
      <PageHead title={t('pageTitle', { title: t('chat.one') })} />

      <Header
        searchText={searchText}
        setSearchText={setSearchText}
        searchDisabled={showEmpty}
        adCategory={adCategory}
        setAdCategory={setAdCategory}
        chatStatus={chatStatus}
        setChatStatus={setChatStatus}
      />

      <div className={cls.inner}>
        <div className={cn(cls.left, 'hide-scrollbar')}>
          {showEmpty ? (
            <div className={cls.placeholder}>
              <b>{t('chat.empty.title')}</b>
              <p>{t('chat.empty.text')}</p>
            </div>
          ) : (
            <>
              {!debouncedSearch ? (
                <div className={cls.chats}>
                  <ChatList
                    chats={chats || []}
                    loading={chatsLoading}
                    selectedChat={selectedChat}
                    onChatClick={onChatClick}
                    chatLink={false}
                  />
                </div>
              ) : (
                <ChatsSearch
                  searchText={debouncedSearch}
                  setSearchText={setSearchText}
                  onChatClick={onSearchChatClick}
                  onChatMessageClick={onSearchChatMsgClick}
                />
              )}
            </>
          )}
        </div>

        <div className={cn(cls.right, { [cls.right_chat]: !!selectedChat })}>
          {!selectedChat && (
            <div className={cls.chat_settings}>
              <ChatSettings />
            </div>
          )}

          {!!selectedChat && (
            <div
              className={cls.right_bg}
              style={{ backgroundImage: `url(${bgImg})` }}
            />
          )}

          <Outlet />
        </div>
      </div>
    </div>
  );
}
