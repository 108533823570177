import { useDesktop } from '@/app/common-store';
import { useCurrencyRate } from '@/modules/currency/hooks';
import { useDiscountTypes } from '@/modules/discount/api';
import { useStickers } from '@/modules/emojis/api';
import { usePromotionPackages } from '@/modules/promotion/hooks';
import { NO_PROMO_ID } from '@/modules/showroom/advert/create/helpers';
import { MyAdvertCard } from '@/modules/showroom/advert/my/MyAdvertsList/MyAdvertCard/MyAdvertCard';
import { MyAdvertCardSkeleton } from '@/modules/showroom/advert/my/MyAdvertsList/MyAdvertCard/MyAdvertCardSkeleton';
import { MyAdvertCardMob } from '@/modules/showroom/advert/my/MyAdvertsList/MyAdvertCardMob/MyAdvertCardMob';
import { MyAdvertCardMobSkeleton } from '@/modules/showroom/advert/my/MyAdvertsList/MyAdvertCardMob/MyAdvertCardMobSkeleton';
import { MyAdvert } from '@/modules/showroom/advert/my/types';
import { LOAD_COUNT } from '@/modules/showroom/advert/search/hooks';
import { CURRENCIES, DESKTOP_BREAKPOINT } from '@/utils/consts';

import cls from './MyAdvertsList.module.scss';

type Props = {
  list: MyAdvert[];
  onPublishToggle?: () => void;
};

export function MyAdvertsList({ list, onPublishToggle }: Props) {
  const isDesktop = useDesktop(DESKTOP_BREAKPOINT);

  const [promotionsData] = usePromotionPackages();
  const [allDiscTypes] = useDiscountTypes();
  const packagesList = promotionsData?.filter((p) => p.id !== NO_PROMO_ID);

  const [stickersList] = useStickers();

  const euroRate = useCurrencyRate(CURRENCIES.euro, CURRENCIES.aed);
  const usdRate = useCurrencyRate(CURRENCIES.usd, CURRENCIES.aed);

  return (
    <ul className={cls.root}>
      {list.map((advert, i) => (
        <li key={advert.id}>
          {isDesktop ? (
            <MyAdvertCard
              advert={advert}
              onPublishToggle={onPublishToggle}
              promotionsList={packagesList}
              stickersList={stickersList}
              discountTypes={allDiscTypes}
              euroRate={euroRate}
              usdRate={usdRate}
              lastChild={i === list.length - 1}
            />
          ) : (
            <MyAdvertCardMob
              advert={advert}
              onPublishToggle={onPublishToggle}
              promotionsList={packagesList}
              stickersList={stickersList}
            />
          )}
        </li>
      ))}
    </ul>
  );
}

const skeletons = new Array(LOAD_COUNT).fill(0);
export function MyAdvertsListSkeleton() {
  const isDesktop = useDesktop(DESKTOP_BREAKPOINT);

  return (
    <ul className={cls.root}>
      {skeletons.map((_, i) => (
        <li key={i}>
          {isDesktop ? <MyAdvertCardSkeleton /> : <MyAdvertCardMobSkeleton />}
        </li>
      ))}
    </ul>
  );
}
