import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { confirmEmailSendCode, createAccount } from '@/modules/auth/api';
import { Button } from '@/ui/Button/Button';
import { ButtonLink } from '@/ui/Button/ButtonLink';
import { Input } from '@/ui/Input/Input';
import { Lottie } from '@/ui/Lottie';
import { MIN_PASSWORD_LENGTH } from '@/utils/consts';
import { showAlert } from '@/utils/network';
import { MAX_EMAIL_LENGTH, validateEmail } from '@/utils/validate';

import cls from '../Signin/Signin.module.scss';

type Props = {
  onSubmit: (email: string, password: string) => void;
};

export function Signup({ onSubmit }: Props) {
  const { t } = useTranslation();

  // Fields
  const [emailTouched, setEmailTouched] = useState<boolean>(false);
  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [repeatPassword, setRepeatPassword] = useState<string>('');

  // Submit
  const isEmailValid = email ? validateEmail(email) : true;
  const isValid =
    isEmailValid &&
    !!password &&
    !!repeatPassword &&
    password.length >= MIN_PASSWORD_LENGTH &&
    password === repeatPassword;

  const [loading, setLoading] = useState(false);

  const submit = async () => {
    if (!isValid) return;

    setLoading(true);

    try {
      const r = await createAccount({ email, password });
      const createdEmail = r.data.email;
      await confirmEmailSendCode({ email: createdEmail });
      onSubmit(createdEmail, password);
    } catch (error) {
      showAlert({ error });
    } finally {
      setLoading(false);
    }
  };

  const onKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (isValid && e.key === 'Enter') {
      submit();
    }
  };

  return (
    <>
      <div className={cls.icon}>
        <Lottie src="signin" />
      </div>
      <h1 className={cls.title}>{t('sellersCabinet')}</h1>
      <p className={cls.subtitle}>{t('auth.signinText')}</p>

      <div className={cls.field_email}>
        <Input
          value={email}
          onChange={(e) => setEmail(e.currentTarget.value.trim())}
          onBlur={() => setEmailTouched(true)}
          label={t('auth.email')}
          disabled={loading}
          errorText={
            !isEmailValid && emailTouched ? t('auth.invalidEmail') : ''
          }
          maxLength={MAX_EMAIL_LENGTH}
        />
      </div>

      <div>
        <Input
          value={password}
          onChange={(e) => setPassword(e.currentTarget.value.trim())}
          label={t('auth.password')}
          onKeyDown={onKeyDown}
          disabled={loading}
          type="password"
        />
      </div>

      <div className={cls.field_password}>
        <Input
          value={repeatPassword}
          onChange={(e) => setRepeatPassword(e.currentTarget.value.trim())}
          label={t('auth.repeatPassword')}
          onKeyDown={onKeyDown}
          disabled={loading}
          type="password"
        />
      </div>

      <div className={cls.buttons}>
        <Button
          onClick={submit}
          loading={loading}
          disabled={loading || !isValid}
          fullWidth
        >
          {t('auth.signup')}
        </Button>

        <ButtonLink color="black" to="/signin" variant="secondary" fullWidth>
          {t('auth.signinAction')}
        </ButtonLink>
      </div>
    </>
  );
}
