import { useLang } from '@/hooks/useLang';
import { CreateReply } from '@/modules/reviews/ReviewsList/ReviewCard/CreateReply';
import { ReplyText } from '@/modules/reviews/ReviewsList/ReviewCard/ReplyText';
import { ReviewAuthor } from '@/modules/reviews/ReviewsList/ReviewCard/ReviewAuthor';
import { ReviewText } from '@/modules/reviews/ReviewsList/ReviewCard/ReviewText';
import { MyReview } from '@/modules/reviews/types';
import { usePermissionAccess } from '@/modules/roles/helpers';
import { useAdvertName } from '@/modules/showroom/advert/hooks';
import { formatDate } from '@/utils/date';

import cls from './ReviewCard.module.scss';

type Props = {
  review: MyReview;
};

export function ReviewCard({ review }: Props) {
  const [lang] = useLang();

  const permissions = usePermissionAccess();

  const advertName = useAdvertName(review.advertisement, true);

  const comment = review.comments.reviewer;
  const createdDate = comment.created_at
    ? new Date(comment.created_at * 1000)
    : null;
  const createdDateStr = createdDate
    ? formatDate(createdDate, 'd MMMM', lang)
    : '';

  return (
    <div className={cls.root}>
      <div className={cls.header}>
        <ReviewAuthor review={review} />
        <div className={cls.info}>
          {createdDateStr && <p className={cls.date}>{createdDateStr}</p>}
          {advertName && <p className={cls.advert_name}>{advertName}</p>}
        </div>
      </div>

      {review.comments.reviewer.comment && (
        <ReviewText text={review.comments.reviewer.comment} />
      )}

      {permissions.reviews.control && <CreateReply review={review} />}

      <ReplyText review={review} />
    </div>
  );
}
