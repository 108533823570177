import cn from 'classnames';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

import { useAccountDetail } from '@/modules/accounts/hooks';
import signinCls from '@/modules/auth/Signin/Signin.module.scss';
import { employeeAcceptInvite } from '@/modules/roles/api';
import { Avatar } from '@/ui/Avatar/Avatar';
import { Button } from '@/ui/Button/Button';
import { Input } from '@/ui/Input/Input';
import { MIN_PASSWORD_LENGTH } from '@/utils/consts';
import { showAlert } from '@/utils/network';
import { useQuery } from '@/utils/query';

import cls from './EmployeeAcceptInvite.module.scss';

export function EmployeeAcceptInvite() {
  const { t } = useTranslation();

  const { token } = useParams();
  const query = useQuery();
  const email = query.email as string;
  const showroomId = query.showroom_id as string;
  const [showroomAcc] = useAccountDetail(Number(showroomId));

  const navigate = useNavigate();

  const showroomName = showroomAcc?.name || '';
  const iconSrc =
    showroomAcc?.avatar?.thumbnail_url || showroomAcc?.avatar?.photo_url || '';
  const [showForm, setShowForm] = useState(false);

  const [name, setName] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [repeatPassword, setRepeatPassword] = useState<string>('');

  const isValid =
    !!token &&
    !!name &&
    !!password &&
    !!repeatPassword &&
    password.length >= MIN_PASSWORD_LENGTH &&
    password === repeatPassword;

  const [loading, setLoading] = useState(false);

  const submit = async () => {
    if (!isValid) return;

    setLoading(true);
    try {
      await employeeAcceptInvite({
        invite_token: token,
        password,
        name
      });
      navigate('/signin');
    } catch (error) {
      showAlert({ error });
    } finally {
      setLoading(false);
    }
  };

  const onKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (isValid && e.key === 'Enter') {
      submit();
    }
  };

  return (
    <div className="box">
      <div className={cls.icon}>
        <Avatar size={90} src={iconSrc} />
      </div>
      <h1 className={cn(signinCls.title, cls.title)}>{showroomName}</h1>
      <p className={cn(signinCls.subtitle, cls.subtitle)}>
        {t(
          showForm
            ? 'roles.invite.accept.createPass'
            : 'roles.invite.accept.subtitle'
        )}
      </p>

      {showForm && (
        <>
          <div className={signinCls.field_email}>
            <Input value={email} label={t('auth.email')} disabled />
          </div>

          <div className={cls.field_name}>
            <Input
              value={name}
              onChange={(e) => setName(e.currentTarget.value.trim())}
              label={t('contacts.name')}
              disabled={loading}
            />
          </div>

          <div>
            <Input
              value={password}
              onChange={(e) => setPassword(e.currentTarget.value.trim())}
              label={t('auth.password')}
              disabled={loading}
              type="password"
            />
          </div>

          <div className={signinCls.field_password}>
            <Input
              value={repeatPassword}
              onChange={(e) => setRepeatPassword(e.currentTarget.value.trim())}
              label={t('auth.repeatPassword')}
              onKeyDown={onKeyDown}
              disabled={loading}
              type="password"
            />
          </div>

          <div className={signinCls.buttons}>
            <Button
              onClick={submit}
              loading={loading}
              disabled={loading || !isValid}
              fullWidth
            >
              {t('auth.signup')}
            </Button>
          </div>
        </>
      )}

      {!showForm && (
        <div className={signinCls.buttons}>
          <Button onClick={() => setShowForm(true)} color="red" fullWidth>
            {t('roles.invite.accept.join')}
          </Button>
        </div>
      )}
    </div>
  );
}
