import cn from 'classnames';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';

import {
  CARS_ADVERTS_URL,
  RENT_ADVERTS_URL,
  VRP_ADVERTS_URL
} from '@/modules/showroom/advert/my/helpers';
import { deleteRentAdvertisement } from '@/modules/showroom/advert/rent/api';
import { useRentUpdateAdvertStore } from '@/modules/showroom/advert/rent/update/store';
import { AdvertStatusType } from '@/modules/showroom/advert/types';
import { deleteAdvertisement } from '@/modules/showroom/advert/update/api';
import { useUpdateAdvertStore } from '@/modules/showroom/advert/update/store';
import { useUpdateVrpStore } from '@/modules/showroom/advert/vrp/UpdateVrp/store';
import { Button } from '@/ui/Button/Button';
import { Dropdown, DropdownItem } from '@/ui/Dropdown/Dropdown';
import { showAlert } from '@/utils/network';

import cls from './BaseLayout.module.scss';

export function CloseEditBtn() {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const [isOpen, setOpen] = useState(false);
  const onCloseClick = () => {
    setOpen(true);
  };

  const isRent = pathname.startsWith('/cars/rent');
  const isVrp = pathname.includes('vrp');
  const advertsUrl = isRent
    ? RENT_ADVERTS_URL
    : isVrp
    ? VRP_ADVERTS_URL
    : CARS_ADVERTS_URL;

  const carDetail = useUpdateAdvertStore((s) => s.detail);
  const plateDetail = useUpdateVrpStore((s) => s.detail);
  const rentDetail = useRentUpdateAdvertStore((s) => s.detail);

  const detail = isRent ? rentDetail : isVrp ? plateDetail : carDetail;
  const detailId = detail?.id;
  const isDraft = !!detail && detail.status.title === AdvertStatusType.draft;

  const setShowPageLeaveWarnRent = useRentUpdateAdvertStore(
    (s) => s.setShowPageLeaveWarn
  );
  const setShowPageLeaveWarnCar = useUpdateAdvertStore(
    (s) => s.setShowPageLeaveWarn
  );
  const setShowPageLeaveWarnPlate = useUpdateVrpStore(
    (s) => s.setShowPageLeaveWarn
  );
  const setShowPageLeaveWarn = isRent
    ? setShowPageLeaveWarnRent
    : isVrp
    ? setShowPageLeaveWarnPlate
    : setShowPageLeaveWarnCar;

  const saveRent = useRentUpdateAdvertStore((s) => s.saveDetail);
  const saveCar = useUpdateAdvertStore((s) => s.saveDetail);
  const savePlate = useUpdateVrpStore((s) => s.saveDetail);
  const save = isRent ? saveRent : isVrp ? savePlate : saveCar;

  const [loading, setLoading] = useState(false);
  const onSaveClick = () => {
    if (!detailId) return;

    setOpen(false);
    setLoading(true);
    setShowPageLeaveWarn(false);

    save(detailId)
      .then(() => {
        navigate(advertsUrl);
      })
      .catch(() => {
        setShowPageLeaveWarn(true);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const onCloseWithoutSaveClick = () => {
    setOpen(false);
    setShowPageLeaveWarn(false);
    window.setTimeout(() => {
      navigate(advertsUrl);
    }, 500);
  };

  const onDeleteClick = async () => {
    if (!detailId) return;

    setOpen(false);
    setLoading(true);
    setShowPageLeaveWarn(false);

    try {
      const deleteAd = isRent ? deleteRentAdvertisement : deleteAdvertisement;
      await deleteAd(detailId);
      showAlert({
        type: 'success',
        text: t('advertisements.deleted')
      });
      navigate(advertsUrl);
    } catch (error) {
      showAlert({ error });
      setShowPageLeaveWarn(true);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className={cls.exit_btn}>
      <Button
        onClick={onCloseClick}
        loading={loading}
        variant="outline"
        color="red"
        size="s"
      >
        {t('common.close')}
      </Button>

      <Dropdown
        name="close-advert-edit"
        isOpen={isOpen}
        close={() => setOpen(false)}
        alwaysOnTop
        fixed
      >
        <DropdownItem>
          <button
            className={cls.dropdown_btn}
            type="button"
            disabled={loading}
            onClick={onSaveClick}
          >
            {t('saveAndClose')}
          </button>
        </DropdownItem>
        <DropdownItem>
          <button
            className={cls.dropdown_btn}
            type="button"
            disabled={loading}
            onClick={onCloseWithoutSaveClick}
          >
            {t('closeWithoutSave')}
          </button>
        </DropdownItem>

        {isDraft && (
          <DropdownItem>
            <button
              className={cn(cls.dropdown_btn, cls.dropdown_btn_red)}
              type="button"
              disabled={loading}
              onClick={onDeleteClick}
            >
              {t('deleteDraft')}
            </button>
          </DropdownItem>
        )}
      </Dropdown>
    </div>
  );
}
