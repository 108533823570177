import { useCallback, useEffect, useState } from 'react';

import { Nullable } from '@/app/types';
import {
  getAutoReplenishmentInfo,
  getSavedPaymentMethods
} from '@/modules/wallet/AutoReplenishment/api';
import {
  AutoReplenishmentRes,
  SavedPaymentMethod
} from '@/modules/wallet/AutoReplenishment/types';
import { showAlert } from '@/utils/network';

export function useAutoReplenishmentInfo(
  skip?: boolean
): [Nullable<AutoReplenishmentRes>, boolean, () => Promise<void>] {
  const [data, setData] = useState<Nullable<AutoReplenishmentRes>>(null);
  const [loading, setLoading] = useState(true);

  const load = useCallback(async () => {
    setLoading(true);

    try {
      const r = await getAutoReplenishmentInfo();
      setData(r.data);
    } catch (error) {
      showAlert({ error });
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    if (skip !== true) load();
  }, [load, skip]);

  return [data, loading, load];
}

export function useSavedPaymentMethods(
  skip?: boolean
): [SavedPaymentMethod[], boolean] {
  const [data, setData] = useState<SavedPaymentMethod[]>([]);
  const [loading, setLoading] = useState(true);

  const load = useCallback(async () => {
    setLoading(true);

    try {
      const r = await getSavedPaymentMethods();
      setData(r.data);
    } catch (error) {
      showAlert({ error });
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    if (skip !== true) load();
  }, [load, skip]);

  return [data, loading];
}
