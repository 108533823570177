import { Nullable, PaginationReq, PhotoType, SortType } from '@/app/types';
import { EmirateType } from '@/modules/emirate/types';
import { AdvertStatus } from '@/modules/showroom/advert/types';
import { VrpPlateCode, VrpType } from '@/modules/showroom/advert/vrp/types';
import { ShowroomContactSmall } from '@/modules/showroom/contact/types';
import {
  TransportBrand,
  TransportGeneration,
  TransportModel,
  TransportModification,
  TransportYear
} from '@/modules/transport/types';

export enum LeadType {
  phone = 'phone',
  chat = 'chat',
  whatsapp = 'whatsapp'
}

export interface GetLeadsQueryReq extends PaginationReq {
  order_by: Nullable<SortType>;
  search: Nullable<string>;
}

export interface LeadsPeriod {
  start: number;
  end: number;
}

export interface GetLeadsReq {
  brand_ids: Nullable<number[]>;
  model_ids: Nullable<number[]>;
  showroom_contact_ids: Nullable<number[]>;
  lead_type: Nullable<LeadType>;
  period: Nullable<LeadsPeriod>;
}

export type GetLeadsRes = {
  leads: Lead[];
};

interface LeadCustomer {
  id: number;
  phone_number: Nullable<string>;
  email: Nullable<string>;
  name: Nullable<string>;
  avatar: Nullable<PhotoType>;
}

export interface LeadAd {
  id: number;
  url: string;
  price: Nullable<number>;
  photos: Nullable<PhotoType[]>;
  brand: Nullable<TransportBrand>;
  model: Nullable<TransportModel>;
  generation: Nullable<TransportGeneration>;
  year_of_issue: Nullable<TransportYear>;
  modification: Nullable<TransportModification>;
  vrp_type: Nullable<VrpType>;
  vrp_plate_number: Nullable<string>;
  vrp_image_url: Nullable<string>;
  vrp_plate_code: Nullable<VrpPlateCode>;
  vrp_plate_emirate: Nullable<EmirateType>;
  status: Nullable<AdvertStatus>;
}

export interface Lead {
  id: number;
  has_been_viewed: boolean;
  lead_type: LeadType;
  created_at: number;
  ad: LeadAd;
  showroom_contact: Nullable<ShowroomContactSmall>;
  // if customer null then lead by unathed user
  customer: Nullable<LeadCustomer>;
}

export interface MarkLeadsAsViewedReq {
  ids: number[];
}
