import { axios } from '@/app/api';
import {
  CreateRoleReq,
  CreateRoleRes,
  EmployeeAcceptInviteReq,
  EmployeeAcceptInviteRes,
  EmployeeType,
  GetEmployeesReq,
  GetEmployeesRes,
  InviteEmployeeReq,
  MyRole,
  RoleOptionsCategory,
  UpdateEmployeeRoleReq,
  UpdateRoleReq
} from '@/modules/roles/types';

// Roles
export function getMyRoles() {
  return axios.get<MyRole[]>(`/dealers/roles/my`);
}

export function getRoleOptionsCategories() {
  return axios.get<RoleOptionsCategory[]>('/dealers/roles');
}

export function createRole(req: CreateRoleReq) {
  return axios.post<CreateRoleRes>('/dealers/roles', req);
}

export function updateRole(roleId: number, req: UpdateRoleReq) {
  return axios.patch<unknown>(`/dealers/roles/${roleId}`, req);
}

export function deleteRole(roleId: number) {
  return axios.delete<unknown>(`/dealers/roles/${roleId}`);
}

// Employees
export function inviteEmployee(req: InviteEmployeeReq) {
  return axios.post<unknown>('/dealers/employee/invite/send', req);
}

export function getEmployees(params: GetEmployeesReq) {
  return axios.get<GetEmployeesRes>('/dealers/employees', { params });
}

export function getMeAsEmployee() {
  return axios.get<EmployeeType>('/dealers/employees/me');
}

export function deleteEmployee(employee_id: number) {
  return axios.delete<unknown>(`/dealers/employees/${employee_id}`);
}

export function employeeAcceptInvite(req: EmployeeAcceptInviteReq) {
  return axios.post<EmployeeAcceptInviteRes>('/dealers/employee/register', req);
}

export function updateEmployeeRole(
  employeeId: number,
  req: UpdateEmployeeRoleReq
) {
  return axios.patch<unknown>(`/dealers/employees/${employeeId}/role`, req);
}
