import { BaseEmoji, Picker } from 'emoji-mart';
import { useState } from 'react';

import { useDarkmode } from '@/modules/theme/useTheme';
import { Dropdown } from '@/ui/Dropdown/Dropdown';
import { EmojiIcon } from '@/ui/EmojiPicker/icons';

import cls from './EmojiPicker.module.scss';

type EmojiPickerProps = {
  onSelect?: (emojiStr: string) => void;
};

export function EmojiPicker({ onSelect }: EmojiPickerProps) {
  const { isDark } = useDarkmode();

  const [isEmojiOpen, setEmojiOpen] = useState(false);

  const handleSelect = (e: BaseEmoji) => {
    if (onSelect && e.native) onSelect(e.native);
  };

  return (
    <div className={cls.emoji_wrap} style={{ position: 'relative' }}>
      <button onClick={() => setEmojiOpen(true)}>
        <EmojiIcon />
      </button>
      <Dropdown
        name="emoji-select"
        isOpen={isEmojiOpen}
        close={() => setEmojiOpen(false)}
        position="top-right"
        horizontalOffset={24}
        verticalOffset={0}
      >
        <li>
          <Picker
            set="apple"
            theme={isDark ? 'dark' : 'light'}
            color="var(--clr-blue)"
            showPreview={false}
            onSelect={handleSelect}
          />
        </li>
      </Dropdown>
    </div>
  );
}
