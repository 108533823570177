export function MenuIcon() {
  return (
    <svg
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.7516 21.1089H26.2398C26.8935 21.1089 27.4166 20.5647 27.4166 19.9049C27.4166 19.2489 26.8959 18.7248 26.2398 18.7248H1.7516C1.09789 18.7248 0.583252 19.2535 0.583252 19.9049C0.583252 20.5575 1.10025 21.1089 1.7516 21.1089Z"
        fill="currentColor"
      />
      <path
        d="M1.7516 15.1953H26.2398C26.8935 15.1953 27.4166 14.6642 27.4166 14.0152C27.4166 13.3484 26.8959 12.8112 26.2398 12.8112H1.7516C1.09789 12.8112 0.583252 13.3531 0.583252 14.0152C0.583252 14.6571 1.10025 15.1953 1.7516 15.1953Z"
        fill="currentColor"
      />
      <path
        d="M1.7516 9.29727H26.2398C26.8935 9.29727 27.4166 8.76613 27.4166 8.11713C27.4166 7.46107 26.8959 6.92627 26.2398 6.92627H1.7516C1.09789 6.92627 0.583252 7.46579 0.583252 8.11713C0.583252 8.75905 1.10025 9.29727 1.7516 9.29727Z"
        fill="currentColor"
      />
    </svg>
  );
}
