import cn from 'classnames';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Nullable } from '@/app/types';
import { useOnceTrue } from '@/hooks/useOnceTrue';
import { useRentSearchStore } from '@/modules/showroom/advert/rent/my/store';
import { EMPTY_BRAND_ID } from '@/modules/showroom/advert/search/consts';
import { smartAutoFiltersIds } from '@/modules/showroom/advert/search/helpers';
import { FilterBtn } from '@/modules/showroom/advert/search/SearchAdverts/Filters/FilterBtn/FilterBtn';
import cls from '@/modules/showroom/advert/search/SearchAdverts/Filters/Filters.module.scss';
import { FiltersList } from '@/modules/showroom/advert/search/SearchAdverts/Filters/FiltersList/FiltersList';
import { FilterType } from '@/modules/showroom/advert/search/SearchAdverts/Filters/FiltersList/helpers';
import { useTransportBodies } from '@/modules/transport/api';
import {
  GetTransportBodiesReq,
  TransportBody
} from '@/modules/transport/types';
import { Dropdown } from '@/ui/Dropdown/Dropdown';
import { MAX_PG_LIMIT } from '@/utils/consts';

export function BodyFilter() {
  const { t } = useTranslation();

  const [isBodyOpen, setBodyOpen] = useState(false);
  const onceOpened = useOnceTrue(isBodyOpen);

  const autoFilters = useRentSearchStore((s) => s.autoFilters);
  const bodiesIds = useRentSearchStore((s) => s.bodiesIds);
  const setBodiesIds = useRentSearchStore((s) => s.setBodiesIds);

  const bodiesReq = useMemo<Nullable<GetTransportBodiesReq>>(() => {
    if (!onceOpened) return null;

    const filters = autoFilters.filter((f) => f.brand_id !== EMPTY_BRAND_ID);
    const idsReq = smartAutoFiltersIds(filters);
    return { limit: MAX_PG_LIMIT, ...idsReq };
  }, [autoFilters, onceOpened]);

  const [allBodies, bodiesLoading] = useTransportBodies(bodiesReq);
  const selectedBodies = allBodies?.filter((b) => bodiesIds?.includes(b.id));

  const onBodiesChange = (v: FilterType) => {
    const b = v as TransportBody;
    if (bodiesIds && bodiesIds.includes(b.id)) {
      setBodiesIds(bodiesIds.filter((bodyId) => bodyId !== b.id));
    } else {
      setBodiesIds(bodiesIds ? [...bodiesIds, b.id] : [b.id]);
    }
  };

  const onBodiesReset = () => {
    setBodyOpen(false);
    setBodiesIds(null);
  };

  return (
    <>
      <FilterBtn
        text={t('body')}
        onClick={() => setBodyOpen(true)}
        active={isBodyOpen}
        onResetClick={onBodiesReset}
        value={selectedBodies
          ?.map((b) => b.name)
          .join(t('common.listSeparator') as string)}
      />

      <Dropdown
        className={cn(cls.dropdown, cls.dropdown_select)}
        name="search-filters-body"
        isOpen={isBodyOpen}
        close={() => setBodyOpen(false)}
        toLeft
      >
        <li className="hide-scrollbar">
          <FiltersList
            activeId={bodiesIds}
            list={allBodies || []}
            loading={bodiesLoading}
            onClick={onBodiesChange}
            search=""
            allButtonText={t('common.anyN')}
            onAllClick={onBodiesReset}
            selectStyle
            multiple
          />
        </li>
      </Dropdown>
    </>
  );
}
