import { useTranslation } from 'react-i18next';

import { AdCategoryFilter } from '@/modules/chat/ChatModal/Header/AdCategoryFilter';
import { ChatStatusFilter } from '@/modules/chat/ChatModal/Header/ChatStatusFilter';
import { ChatAdCategory, ChatStatus } from '@/modules/chat/types';
import { InputSearch } from '@/ui/InputSearch/InputSearch';

import cls from './Header.module.scss';

type Props = {
  searchText: string;
  setSearchText: (searchText: string) => void;
  searchDisabled?: boolean;
  adCategory: ChatAdCategory;
  setAdCategory: (v: ChatAdCategory) => void;
  chatStatus: ChatStatus;
  setChatStatus: (v: ChatStatus) => void;
};

export function Header({
  searchText,
  setSearchText,
  searchDisabled,
  adCategory,
  setAdCategory,
  chatStatus,
  setChatStatus
}: Props) {
  const { t } = useTranslation();

  return (
    <header className={cls.root}>
      <div className={cls.search_field}>
        <InputSearch
          value={searchText}
          onChange={setSearchText}
          placeholder={t('chat.search')}
          disabled={searchDisabled}
          compact
          showClear
        />
      </div>

      <ul className={cls.list}>
        <li className={cls.li}>
          <AdCategoryFilter
            adCategory={adCategory}
            setAdCategory={setAdCategory}
          />
        </li>
        <li className={cls.li}>
          <ChatStatusFilter
            chatStatus={chatStatus}
            setChatStatus={setChatStatus}
          />
        </li>
      </ul>
    </header>
  );
}
