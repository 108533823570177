import { useCallback, useEffect, useState } from 'react';

import { axios } from '@/app/api';
import { getAccountDetail } from '@/modules/accounts/api';
import { AccountDetailRes, GetViewsCountRes } from '@/modules/accounts/types';
import { showAlert } from '@/utils/network';

export function useAccountDetail(
  accountId: number
): [AccountDetailRes | undefined, boolean] {
  const [isLoading, setLoading] = useState<boolean>(false);
  const [data, setData] = useState<AccountDetailRes>();

  const load = useCallback(async () => {
    setLoading(true);

    try {
      const r = await getAccountDetail(accountId);
      setData(r.data);
    } catch (error) {
      showAlert({ error });
    } finally {
      setLoading(false);
    }
  }, [accountId]);

  useEffect(() => {
    load();
  }, [load]);

  return [data, isLoading];
}

export function useAccountViewsCount(
  period_start: number,
  period_end: number
): [GetViewsCountRes | undefined, boolean] {
  const [isLoading, setLoading] = useState<boolean>(false);
  const [count, setCount] = useState<GetViewsCountRes>();

  const load = useCallback(async () => {
    setLoading(true);

    try {
      const r = await axios.get<GetViewsCountRes>('/accounts/count-view', {
        params: { period_start, period_end }
      });
      setCount(sortViewsData(r.data));
    } catch (error) {
      showAlert({ error });
    } finally {
      setLoading(false);
    }
  }, [period_end, period_start]);

  useEffect(() => {
    load();
  }, [load]);

  return [count, isLoading];
}

function sortViewsData(data: GetViewsCountRes): GetViewsCountRes {
  return {
    ...data,
    views: {
      days: [
        ...data.views.days.sort((a, b) => {
          return a.day - b.day;
        })
      ],
      summary: data.views.summary
    },
    calls: {
      days: [
        ...data.calls.days.sort((a, b) => {
          return a.day - b.day;
        })
      ],
      summary: data.calls.summary
    }
  };
}
