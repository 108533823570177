import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { editGreetingMessage } from '@/modules/chat/api-req';
import { ChatSetting } from '@/modules/chat/ChatSettings/ChatSetting/ChatSetting';
import { ChatSettingsRes } from '@/modules/chat/types';
import { Textarea } from '@/ui/Textarea/Textarea';
import { maxLength } from '@/utils/format';
import { showAlert } from '@/utils/network';

import cls from './ChatSettings.module.scss';
import { GreetIcon, CheckIcon } from './icons';

const MAX_MSG_LEN = 500;

type Props = {
  settings: ChatSettingsRes['greeting_message'];
};

export function GreetSetting({ settings }: Props) {
  const { t } = useTranslation();

  const [saving, setSaving] = useState(false);

  const [enabled, setEnabled] = useState(!!settings);
  const toggle = async () => {
    setSaving(true);

    const oldValue = enabled;
    const newValue = !oldValue;
    setEnabled(newValue);

    try {
      await editGreetingMessage({ detach: !newValue });
      setSaving(false);
    } catch (error) {
      setEnabled(oldValue);
      showAlert({ error });
      setSaving(false);
    }
  };

  const [message, setMessage] = useState(settings?.message || '');
  const [isAlwaysSend, setAlwaysSend] = useState(!!settings?.send_always);

  const onBlur = async () => {
    setSaving(true);

    try {
      await editGreetingMessage({
        detach: message ? false : true,
        cfg: {
          message,
          send_always: isAlwaysSend,
          outside_business_hours: !isAlwaysSend
        }
      });
      setSaving(false);
    } catch (error) {
      showAlert({ error });
      setSaving(false);
    }
  };

  const toggleSchedule = async () => {
    setSaving(true);

    const oldValue = isAlwaysSend;
    const newValue = !oldValue;
    setAlwaysSend(newValue);

    try {
      await editGreetingMessage({
        cfg: {
          message,
          send_always: newValue,
          outside_business_hours: !newValue
        }
      });
      setSaving(false);
    } catch (error) {
      showAlert({ error });
      setAlwaysSend(oldValue);
      setSaving(false);
    }
  };

  useEffect(() => {
    setEnabled(!!settings);
    setMessage(settings?.message || '');
    setAlwaysSend(!!settings?.send_always);
  }, [settings]);

  return (
    <ChatSetting
      enabled={enabled}
      icon={<GreetIcon />}
      title={t('chat.settings.greet.title')}
      subtitle={t('chat.settings.greet.subtitle')}
      disabled={saving}
      toggle={toggle}
    >
      <div className={cls.field}>
        <Textarea
          value={message}
          onChange={(e) =>
            setMessage(maxLength(e.currentTarget.value, MAX_MSG_LEN))
          }
          onBlur={onBlur}
          disabled={saving}
          placeholder={t('chat.settings.enterMessage')}
          bottomLabel={t('common.slashSeparated', {
            first: message.length,
            second: MAX_MSG_LEN
          })}
          bottomLabelRight
          minRows={3}
        />
      </div>
      <div>
        <p className={cls.check_label}>
          {t('chat.settings.greet.schedule.label')}
        </p>

        <ul className={cls.check_list}>
          <li>
            <button
              className={cls.check_btn}
              type="button"
              onClick={toggleSchedule}
              disabled={saving}
            >
              {t('chat.settings.greet.schedule.always')}
              {isAlwaysSend && <CheckIcon />}
            </button>
          </li>
          <li>
            <button
              className={cls.check_btn}
              type="button"
              onClick={toggleSchedule}
              disabled={saving}
            >
              {t('chat.settings.greet.schedule.outside')}
              {!isAlwaysSend && <CheckIcon />}
            </button>
          </li>
        </ul>
      </div>
    </ChatSetting>
  );
}
