import { useCallback, useEffect, useState } from 'react';

import {
  getEmployees,
  getMyRoles,
  getRoleOptionsCategories
} from '@/modules/roles/api';
import {
  MyRole,
  RoleOptionsCategory,
  EmployeeType,
  GetEmployeesReq
} from '@/modules/roles/types';
import { showAlert } from '@/utils/network';

// Employees
export type UseEmployees = [
  EmployeeType[] | undefined,
  boolean,
  () => Promise<void>
];

export function useEmployees(
  req: GetEmployeesReq,
  skip?: boolean
): UseEmployees {
  const [isLoading, setLoading] = useState<boolean>(false);
  const [data, setData] = useState<EmployeeType[]>();

  const load = useCallback(async () => {
    setLoading(true);

    try {
      const r = await getEmployees(req);
      setData(r.data);
    } catch (error) {
      showAlert({ error });
    } finally {
      setLoading(false);
    }
  }, [req]);

  useEffect(() => {
    if (skip !== true) load();
  }, [load, skip]);

  return [data, isLoading, load];
}

// Roles
export type UseMyRoles = [MyRole[] | undefined, boolean, () => Promise<void>];

export function useMyRoles(skip?: boolean): UseMyRoles {
  const [isLoading, setLoading] = useState<boolean>(false);
  const [data, setData] = useState<MyRole[]>();

  const load = useCallback(async () => {
    setLoading(true);

    try {
      const r = await getMyRoles();
      setData(r.data);
    } catch (error) {
      showAlert({ error });
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    if (skip !== true) load();
  }, [load, skip]);

  return [data, isLoading, load];
}

// Categories
export function useRoleOptionsCategories(
  skip?: boolean
): [RoleOptionsCategory[] | undefined, boolean, () => Promise<void>] {
  const [isLoading, setLoading] = useState<boolean>(false);
  const [data, setData] = useState<RoleOptionsCategory[]>();

  const load = useCallback(async () => {
    setLoading(true);

    try {
      const r = await getRoleOptionsCategories();
      setData(r.data);
    } catch (error) {
      showAlert({ error });
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    if (skip !== true) load();
  }, [load, skip]);

  return [data, isLoading, load];
}
