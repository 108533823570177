import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

import { restorePasswordConfirm } from '@/modules/auth/api';
import cls from '@/modules/auth/Signin/Signin.module.scss';
import { Button } from '@/ui/Button/Button';
import { ButtonLink } from '@/ui/Button/ButtonLink';
import { Input } from '@/ui/Input/Input';
import { Lottie } from '@/ui/Lottie';
import { MIN_PASSWORD_LENGTH } from '@/utils/consts';
import { showAlert } from '@/utils/network';

export function RestorePasswordConfirm() {
  const { t } = useTranslation();
  const { token } = useParams();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [password, setPassword] = useState<string>('');
  const [repeatPassword, setRepeatPassword] = useState<string>('');
  const [repeatTouched, setRepeatTouched] = useState<boolean>(false);

  const passwordValid = !!password && password.length >= MIN_PASSWORD_LENGTH;
  const passwordsMatch = repeatPassword === password;
  const isValid = !!token && passwordValid && passwordsMatch;

  const submit = async () => {
    if (!isValid) return;

    setLoading(true);
    try {
      await restorePasswordConfirm(token, password);
      showAlert({ type: 'success', text: t('auth.restore.success') });
      navigate('/signin');
    } catch (error) {
      showAlert({ error });
    } finally {
      setLoading(false);
    }
  };

  const onKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (isValid && e.key === 'Enter') {
      submit();
    }
  };

  return (
    <div className="box">
      <>
        <div className={cls.icon}>
          <Lottie src="phone" />
        </div>

        <h1 className={cls.title}>{t('auth.newPassword')}</h1>
        <p className={cls.subtitle} style={{ maxWidth: '22em' }}>
          {t('auth.restore.password')}
        </p>

        <div className={cls.field}>
          <Input
            value={password}
            onChange={(e) => setPassword(e.currentTarget.value.trim())}
            label={t('auth.password')}
            disabled={loading}
            type="password"
          />
        </div>

        <div className={cls.field}>
          <Input
            value={repeatPassword}
            onChange={(e) => setRepeatPassword(e.currentTarget.value.trim())}
            onBlur={() => setRepeatTouched(true)}
            label={t('auth.repeatPassword')}
            onKeyDown={onKeyDown}
            disabled={loading}
            type="password"
            errorText={
              repeatTouched && !passwordsMatch
                ? t('auth.passwordsNotMatch')
                : ''
            }
          />
        </div>

        <div className={cls.buttons}>
          <Button
            onClick={submit}
            loading={loading}
            disabled={loading || !isValid}
            fullWidth
          >
            {t('auth.changePassword')}
          </Button>

          <ButtonLink color="black" to="/signin" variant="secondary" fullWidth>
            {t('auth.restore.back')}
          </ButtonLink>
        </div>
      </>
    </div>
  );
}
