export function AddIcon() {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.3335 10.0001C3.3335 10.4894 3.73753 10.8884 4.22171 10.8884H9.11337V15.78C9.11337 16.2628 9.51088 16.6668 10.0001 16.6668C10.4894 16.6668 10.8949 16.2628 10.8949 15.78V10.8884H15.78C16.2628 10.8884 16.6668 10.4894 16.6668 10.0001C16.6668 9.51088 16.2628 9.1054 15.78 9.1054H10.8949V4.22171C10.8949 3.73753 10.4894 3.3335 10.0001 3.3335C9.51088 3.3335 9.11337 3.73753 9.11337 4.22171V9.1054H4.22171C3.73753 9.1054 3.3335 9.51088 3.3335 10.0001Z"
        fill="currentColor"
      />
    </svg>
  );
}

export function DeleteIcon() {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.05176 14.7581C8.3394 14.7581 8.52479 14.5758 8.51796 14.3074L8.30882 7.15129C8.30077 6.88536 8.11292 6.70871 7.83771 6.70871C7.55253 6.70871 7.36837 6.89096 7.37519 7.15812L7.57874 14.3111C7.5868 14.5827 7.77587 14.7581 8.05176 14.7581ZM10.0624 14.7581C10.3451 14.7581 10.5441 14.5783 10.5441 14.3124V7.15566C10.5441 6.88973 10.3451 6.70871 10.0624 6.70871C9.77844 6.70871 9.585 6.88973 9.585 7.15566V14.3124C9.585 14.5783 9.77844 14.7581 10.0624 14.7581ZM12.0773 14.7581C12.3489 14.7581 12.5367 14.5827 12.5447 14.3111L12.7483 7.15812C12.7551 6.89096 12.571 6.70871 12.2858 6.70871C12.0106 6.70871 11.8227 6.88536 11.8147 7.15689L11.6112 14.3074C11.6043 14.5758 11.7897 14.7581 12.0773 14.7581ZM6.96142 4.80678H8.22118V3.38906C8.22118 3.03562 8.46841 2.80886 8.84847 2.80886H11.2638C11.6439 2.80886 11.8912 3.03562 11.8912 3.38906V4.80678H13.1509V3.32571C13.1509 2.28192 12.4847 1.6665 11.3537 1.6665H8.75865C7.62762 1.6665 6.96142 2.28192 6.96142 3.32571V4.80678ZM3.93745 5.54941H16.1916C16.528 5.54941 16.7901 5.28225 16.7901 4.94588C16.7901 4.61197 16.528 4.3504 16.1916 4.3504H3.93745C3.60668 4.3504 3.33337 4.61197 3.33337 4.94588C3.33337 5.28785 3.60668 5.54941 3.93745 5.54941ZM6.92756 17.069H13.2015C14.2464 17.069 14.9251 16.4293 14.974 15.3832L15.4433 5.4185H14.1772L13.7285 15.1799C13.7149 15.5895 13.4447 15.8614 13.0555 15.8614H7.06244C6.68442 15.8614 6.41425 15.5826 6.395 15.1799L5.92389 5.4185H4.68024L5.15504 15.3888C5.20528 16.4349 5.87148 17.069 6.92756 17.069Z"
        fill="currentColor"
      />
    </svg>
  );
}
