import { Nullable } from '@/app/types';
import { Brand } from '@/modules/showroom/advert/create/Brand/Brand';
import { useAdvertUpdateFlags } from '@/modules/showroom/advert/update/hooks';
import { useUpdateAdvertStore } from '@/modules/showroom/advert/update/store';
import { TransportBrand } from '@/modules/transport/types';

type Props = {
  brands: TransportBrand[];
};

export function BrandWrap({ brands }: Props) {
  const { disableCoreChanges } = useAdvertUpdateFlags();
  const brand = useUpdateAdvertStore((s) => s.brand);
  const setBrand = useUpdateAdvertStore((s) => s.setBrand);
  const setModel = useUpdateAdvertStore((s) => s.setModel);
  const resetBrandKey = useUpdateAdvertStore((s) => s.resetBrandKey);
  const resetAll = useUpdateAdvertStore((s) => s.resetAll);

  const onBrandChange = (b: Nullable<TransportBrand>) => {
    if (!b) setModel(null);
    if (b) resetAll(true);
    setBrand(b);
  };

  return (
    <Brand
      brands={brands}
      brand={brand}
      onChange={onBrandChange}
      key={resetBrandKey}
      disabled={disableCoreChanges}
    />
  );
}
