import { Nullable, PhotoType, PaginationReq } from '@/app/types';
import { AccountType } from '@/modules/accounts/types';
import { CategoryType } from '@/modules/category/types';
import { EmirateType } from '@/modules/emirate/types';
import { AdvertStatus } from '@/modules/showroom/advert/types';
import { VrpPlateCode, VrpType } from '@/modules/showroom/advert/vrp/types';
import {
  TransportBrand,
  TransportModel,
  TransportGeneration,
  TransportYear,
  TransportModification
} from '@/modules/transport/types';

// Companion
export interface ChatCompanionOnline {
  is_online: boolean;
  last_online_time: Nullable<number>;
}

export interface ChatCompanion {
  user_id: number;
  account_type: AccountType;
  created_at: number;
  reviews_average_score: number;
  username: string;
  name: Nullable<string>;
  author_avatar_url: Nullable<string>;
  description: Nullable<string>;
}

// Advert in chat
export interface ChatAdvert {
  id: number;
  category: CategoryType;
  status: AdvertStatus;
  url: string;
  is_my: boolean;
  price: number;
  brand: Nullable<TransportBrand>;
  model: Nullable<TransportModel>;
  generation: Nullable<TransportGeneration>;
  year_of_issue: Nullable<TransportYear>;
  photos: Nullable<PhotoType[]>;
  available_chat: Nullable<boolean>;
  available_phone: Nullable<boolean>;
  lat: Nullable<number>;
  long: Nullable<number>;
  modification: Nullable<TransportModification>;
  vrp_plate_number: Nullable<string>;
  vrp_image_url: Nullable<string>;
  vrp_type: Nullable<VrpType>;
  vrp_plate_code: Nullable<VrpPlateCode>;
  vrp_plate_emirate: Nullable<EmirateType>;
  discount_offer: Nullable<ChatDiscountOffer>;
}

export interface ChatDiscountOffer {
  discount_amount_aed: Nullable<number>;
  discount_amount_percent: Nullable<number>;
  expired_at_timestamp: number;
}

// Message
export enum ChatMessageAuthor {
  system = 'system',
  my = 'my',
  companion = 'companion'
}

export enum ChatMessageContentType {
  image = 'image',
  video = 'video',
  file = 'file',
  location = 'location'
}

export interface ChatMessageCoords {
  lat: number;
  long: number;
}

export interface ChatMessageContent {
  type: ChatMessageContentType;
  upload_date: number;
  name: Nullable<string>;
  size: Nullable<number>;
  url: Nullable<string>;
  extension: Nullable<string>;
  thumbnail_url: Nullable<string>;
  duration: Nullable<number>;
  coords: Nullable<ChatMessageCoords>;
}

export interface ChatMessageContentWithAuthor extends ChatMessageContent {
  author_name: string;
}

export interface ChatMessage {
  id: number;
  created_at: number;
  is_read: boolean;
  is_deleted: boolean;
  content: ChatMessageContent[];
  author: ChatMessageAuthor;
  text: Nullable<string>;
  updated_at: Nullable<number>;
  author_name: Nullable<string>;
  author_avatar_url: Nullable<string>;
  local_id: Nullable<string>;
  // front only
  index: Nullable<number>;
  is_local?: boolean;
  is_from_pull?: boolean;
}
export interface ChatMessageWithReplied extends ChatMessage {
  replied_message: Nullable<ChatMessage>;
}

// My chats
export type GetMyChatsReq = PaginationReq & {
  ad_category?: ChatAdCategory;
  chat_status?: ChatStatus;
};
export type GetMyChatsRes = ChatSimple[];
export interface ChatSimple {
  id: number;
  is_available_to_send_messages: boolean;
  unread_count: Nullable<number>;
  companion: Nullable<ChatCompanion>;
  ad: Nullable<ChatAdvert>;
  last_message: Nullable<ChatMessage>;
}

export interface PullMyChatsReq {
  start: Nullable<number>;
}
export type PullMyChatsRes = ChatSimple[];

// Create chat
export interface CreateChatReq {
  advertisement_id: number;
}
export type CreateChatRes = ChatSimple;

// Chat detail
export enum ChatBlockStatusType {
  blocked_by_companion = 'blocked_by_companion',
  do_unblock = 'do_unblock',
  already_sold = 'already_sold',
  seller_change_his_mind = 'seller_change_his_mind',
  user_deleted = 'user_deleted'
}

export interface ChatBlockStatus {
  id: Nullable<number>;
  name: Nullable<string>;
  title: Nullable<ChatBlockStatusType>;
}

export interface ChatDetail extends Omit<ChatSimple, 'last_message'> {
  last_message: Nullable<ChatMessageWithReplied>;
  block_status: Nullable<ChatBlockStatus>;
}

// Messages
export type MessagesGroup = {
  date: string;
  messages: ChatMessageWithReplied[];
};

export enum ChatDirection {
  direct = 'direct',
  reverse = 'reverse'
}

export interface GetChatMessagesReq extends PaginationReq {
  direction?: Nullable<ChatDirection>;
  purpose_entity_id?: Nullable<number>;
  purpose_entity_area?: Nullable<number>;
}

export interface GetChatMessagesRes {
  previous: number;
  next: number;
  messages: ChatMessageWithReplied[];
}

export type PullChatMessagesReq = PullMyChatsReq;
export interface PullChatMessagesRes {
  new_messages: ChatMessageWithReplied[];
  updated_messages: ChatMessageWithReplied[];
  is_available_for_send_messages: boolean;
  last_activity_at: number;
  block_status: Nullable<ChatBlockStatus>;
}

export interface SendMessageReq {
  local_id: Nullable<string>;
  text: Nullable<string>;
  content: Nullable<ChatMessageContent[]>;
  replied_message_id: Nullable<number>;
}
export type SendMessageRes = ChatMessageWithReplied;

export interface UpdateMessageReq {
  text: string;
}
export type UpdateMessageRes = ChatMessageWithReplied;

export interface ReadMessageReq {
  ids: number[];
}

// Chat content
export type GetAllChatPhotosReq = PaginationReq;
export type GetAllChatPhotosRes = ChatMessageContentWithAuthor[];

export type GetAllChatVideosReq = PaginationReq;
export type GetAllChatVideosRes = ChatMessageContentWithAuthor[];

export type GetAllChatFilesReq = PaginationReq;
export type GetAllChatFilesRes = ChatMessageContentWithAuthor[];

export type GetAllChatPhotosVideosReq = PaginationReq;
export type GetAllChatPhotosVideosRes = ChatMessageContentWithAuthor[];

// Search
export interface SearchChatsReq extends PaginationReq {
  value: string;
}
export type SearchChatsRes = ChatSimple[];

export type ChatMessageId = { id: number };
export interface ChatMessageInSearch
  extends Omit<
    ChatMessageWithReplied,
    'created_at' | 'is_read' | 'is_deleted' | 'content' | 'author'
  > {
  created_at: Nullable<number>;
  is_read: Nullable<boolean>;
  is_deleted: Nullable<boolean>;
  content: Nullable<ChatMessageContent[]>;
  author: Nullable<ChatMessageAuthor>;
}

export interface SearchChatMessagesReq extends PaginationReq {
  value: string;
  ids_only: Nullable<boolean>;
}
export type SearchChatMessagesRes = ChatMessageInSearch[];
export type SearchChatMessagesIdsRes = ChatMessageId[];

export interface FoundedMessage {
  id: number;
  text: string;
  created_at: number;
}
export interface ChatWithFoundedMessage
  extends Omit<ChatSimple, 'replied_message'> {
  founded_message: Nullable<FoundedMessage>;
}
export interface SearchMessagesReq extends PaginationReq {
  value: string;
}
export type SearchMessagesRes = ChatWithFoundedMessage[];

// Other
export interface GetUnreadCountRes {
  count: number;
}

export type GetCompanionChatsReq = PaginationReq;
export type GetCompanionChatsRes = ChatSimple[];

// Quick replies
export interface QuickReplyType {
  id: number;
  text: string;
}

export type GetQuickReplyReq = PaginationReq;
export type GetQuickReplyRes = QuickReplyType[];

export type SaveQuickReplyReq = Omit<QuickReplyType, 'id'>;
export type SaveQuickReplyRes = {
  result: boolean;
};

// Filters
export enum ChatAdCategory {
  all = 'all',
  cars = 'cars',
  vrp = 'vrp'
}

export enum ChatStatus {
  all = 'all',
  unread_chats = 'unread_chats',
  unanswered_chats = 'unanswered_chats',
  chats_with_response = 'chats_with_response',
  not_active_chats = 'not_active_chats',
  mailing_chats_only = 'mailing_chats_only'
}

// Settings
export interface ChatStartConfigReq {
  detach?: boolean;
  cfg?: {
    title: string;
    description: string;
  };
}

export interface ChatGreetingConfigReq {
  detach?: boolean;
  cfg?: {
    message: string;
    send_always: boolean;
    outside_business_hours: boolean;
  };
}

export interface ChatSettingsRes {
  start_page: Nullable<ChatStartConfigReq['cfg']>;
  greeting_message: Nullable<ChatGreetingConfigReq['cfg']>;
}
