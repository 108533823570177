export function CloseIcon() {
  return (
    <svg
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.35791 22.6411C5.84372 23.1157 6.66308 23.1096 7.12564 22.6471L14.0021 15.7706L20.8746 22.6451C21.3452 23.1157 22.1637 23.1229 22.6403 22.6391C23.117 22.1533 23.119 21.3531 22.6484 20.8805L15.7759 13.9968L22.6484 7.12435C23.119 6.65375 23.1262 5.84444 22.6403 5.3678C22.1545 4.88199 21.3452 4.87998 20.8746 5.35975L14.0021 12.2322L7.12564 5.35774C6.66308 4.88803 5.83455 4.87081 5.35791 5.36579C4.88328 5.8516 4.88932 6.65978 5.35187 7.12234L12.2284 13.9968L5.35187 20.8845C4.88932 21.345 4.87411 22.1644 5.35791 22.6411Z"
        fill="currentColor"
      />
    </svg>
  );
}

export function AddIcon() {
  return (
    <svg
      width="17"
      height="16"
      viewBox="0 0 17 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.80957 7.99983C2.80957 8.39122 3.1328 8.71039 3.52014 8.71039H7.43347V12.6237C7.43347 13.0099 7.75147 13.3332 8.14289 13.3332C8.53429 13.3332 8.85868 13.0099 8.85868 12.6237V8.71039H12.7668C13.153 8.71039 13.4762 8.39122 13.4762 7.99983C13.4762 7.60841 13.153 7.28403 12.7668 7.28403H8.85868V3.37707C8.85868 2.98973 8.53429 2.6665 8.14289 2.6665C7.75147 2.6665 7.43347 2.98973 7.43347 3.37707V7.28403H3.52014C3.1328 7.28403 2.80957 7.60841 2.80957 7.99983Z"
        fill="currentColor"
      />
    </svg>
  );
}
