import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useImgPlaceholder } from '@/hooks/useImgPlaceholder';
import { useLang } from '@/hooks/useLang';
import { markCreditRequestsAsViewed } from '@/modules/credit/api';
import { CreditRequestType } from '@/modules/credit/types';
import { useAdvertName } from '@/modules/showroom/advert/hooks';
import { AdvertStatusType } from '@/modules/showroom/advert/types';
import { ImgSkeleton } from '@/ui/ImgSkeleton/ImgSkeleton';
import { useFormatPhone } from '@/ui/PhoneInput/helpers';
import { ADV_CUR, APP_URL } from '@/utils/consts';
import { copyToClipboard } from '@/utils/copy';
import { isThisYear, isToday, isYesterday } from '@/utils/date';
import { numberWithSpaces } from '@/utils/format';
import { showAlert } from '@/utils/network';

import cls from './CreditRequest.module.scss';
import { CopyIcon, MailIcon, PersonIcon, PhoneIcon } from './icons';

type Props = {
  data: CreditRequestType;
  firstPayPercent: number;
};

export function CreditRequest({ data, firstPayPercent }: Props) {
  const { t } = useTranslation();
  const [lang] = useLang();

  const ad = data.ad_info;
  const mod = ad.modification;

  const createdStr = useMemo(() => {
    const createdDate = new Date(data.created_at * 1000);
    const today = isToday(createdDate);
    const yesterday = isYesterday(createdDate);
    const thisYear = isThisYear(createdDate);

    if (today || yesterday) {
      const time = createdDate.toLocaleDateString(lang, {
        hour: '2-digit',
        minute: '2-digit'
      });
      const str = today ? t('common.today') : t('common.yesterday');
      return `${str}, ${time}`;
    }

    return createdDate.toLocaleDateString(lang, {
      hour: '2-digit',
      minute: '2-digit',
      day: '2-digit',
      month: 'long',
      year: thisYear ? undefined : 'numeric'
    });
  }, [data.created_at, lang, t]);

  const advertUrl = ad.url ? `${APP_URL}/${ad.url}` : '';
  const adName = useAdvertName(ad);
  const formattedPhone = useFormatPhone(data.phone);

  const imgPlaceholder = useImgPlaceholder();
  const onCopyClick = (copyStr: string) => () => {
    copyToClipboard(copyStr, t('common.copied') as string);
  };

  const [viewed, setViewed] = useState(data.has_been_viewed);
  const [markLoading, setMarkLoading] = useState(false);

  const onHover = async () => {
    if (markLoading || viewed) return;
    setMarkLoading(true);

    try {
      await markCreditRequestsAsViewed({ ids: [data.id] });
      setViewed(true);
    } catch (error) {
      showAlert({ error });
    } finally {
      setMarkLoading(false);
    }
  };

  useEffect(() => {
    setViewed(data.has_been_viewed);
  }, [data.has_been_viewed]);

  return (
    <div className={cls.root} onMouseEnter={onHover}>
      <div className={cls.col}>
        <a
          className={cls.link}
          href={advertUrl || ''}
          target="_blank"
          rel="noreferrer"
        />

        <div className={cls.img}>
          <ImgSkeleton
            className={cls.img_inner}
            src={data.ad_info.thumbnail_url || imgPlaceholder}
            alt=""
            imgProxyWidth={170}
            imgProxyHeight={128}
          />
        </div>
      </div>

      <div className={cls.col}>
        <a
          className={cls.link}
          href={advertUrl || ''}
          target="_blank"
          rel="noreferrer"
        />

        <div className={cls.advert_wrap}>
          <p className={cls.advert}>{adName}</p>
          {ad.status?.title !== AdvertStatusType.published && (
            <span className={cls.advert_status}>{ad.status?.name}</span>
          )}
          {!viewed && <span className={cls.view_badge} />}
        </div>

        {mod && (
          <p className={cls.specs}>
            {ad.price && (
              <>
                {t('priceCurrency', {
                  price: numberWithSpaces(ad.price, lang),
                  currency: ADV_CUR
                })}
                <span>{t('common.dot')}</span>
              </>
            )}
            {mod.volume?.short_name} {mod.transmission.short_name}
            <span>{t('common.dot')}</span>
            {t('common.commaSeparated', {
              first: t('horsepower', {
                value: mod.horse_power?.name || ''
              }),
              second: mod.engine.name
            })}
            <span>{t('common.dot')}</span>
            {mod.drive_unit.short_name}
          </p>
        )}

        <p className={cls.date}>{createdStr}</p>
      </div>

      <div className={cls.col}>
        <p className={cls.col_label}>{t('credits.req.aboutLoan')}</p>
        <div className={cls.row}>
          <p>{t('credits.req.amount')}</p>
          <b>
            {t('priceCurrency', {
              price: numberWithSpaces(Number(data.amount), lang),
              currency: ADV_CUR
            })}
          </b>
        </div>
        <div className={cls.row}>
          <p>{t('credits.req.firstPay')}</p>
          <b>
            {t('priceCurrency', {
              price: numberWithSpaces(Number(data.down_payment), lang),
              currency: ADV_CUR
            })}{' '}
            ({firstPayPercent}%)
          </b>
        </div>
        <div className={cls.row}>
          <p>{t('credits.req.monthlyPay')}</p>
          <b>
            {t('credits.req.monthlyPayValue', {
              value: t('priceCurrency', {
                price: numberWithSpaces(Number(data.payment), lang),
                currency: ADV_CUR
              })
            })}
          </b>
        </div>
      </div>

      <div className={cls.col}>
        <p className={cls.col_label}>{t('credits.req.aboutLead')}</p>

        <button
          className={cls.contact}
          type="button"
          onClick={onCopyClick(data.name)}
        >
          <PersonIcon />
          <span>{data.name}</span>
          <CopyIcon />
        </button>
        <button
          className={cls.contact}
          type="button"
          onClick={onCopyClick(formattedPhone)}
        >
          <PhoneIcon />
          <span>{formattedPhone}</span>
          <CopyIcon />
        </button>
        {data.email && (
          <button
            className={cls.contact}
            type="button"
            onClick={onCopyClick(data.email)}
          >
            <MailIcon />
            <span>{data.email}</span>
            <CopyIcon />
          </button>
        )}
      </div>
    </div>
  );
}
