import cn from 'classnames';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useCommonStore } from '@/app/common-store';
import { Nullable, OptionI } from '@/app/types';
import { markAllLeadsAsViewed } from '@/modules/leads/api';
import { LeadType } from '@/modules/leads/types';
import { Button } from '@/ui/Button/Button';
import { Tabs } from '@/ui/Tabs/Tabs';
import { showAlert } from '@/utils/network';

import cls from './Header.module.scss';
import { CheckIcon } from './icons';

const ALL_ID = 'all';

type Props = {
  type: Nullable<LeadType>;
  onTypeChange: (type: Nullable<LeadType>) => void;
  onMarkAll: () => void;
};

export function Header({ type, onTypeChange, onMarkAll }: Props) {
  const { t } = useTranslation();

  const clientCommunication = useCommonStore((s) => s.clientCommunication);
  const counts = clientCommunication?.count_unread_leads_by_lead_type;
  const phoneCount = counts?.find(
    (v) => v.lead_type === LeadType.phone
  )?.count_unread_leads;
  const chatCount = counts?.find(
    (v) => v.lead_type === LeadType.chat
  )?.count_unread_leads;
  const whatsappCount = counts?.find(
    (v) => v.lead_type === LeadType.whatsapp
  )?.count_unread_leads;

  const options = useMemo<OptionI[]>(
    () => [
      {
        id: ALL_ID,
        text: t('common.all')
      },
      {
        id: LeadType.phone,
        text: t('phone'),
        extra: phoneCount ? String(phoneCount) : ''
      },
      {
        id: LeadType.chat,
        text: t('chat.one'),
        extra: chatCount ? String(chatCount) : ''
      },
      {
        id: LeadType.whatsapp,
        text: t('contacts.whatsapp.title'),
        extra: whatsappCount ? String(whatsappCount) : ''
      }
    ],
    [chatCount, phoneCount, t, whatsappCount]
  );
  const option = type ? options.find((opt) => opt.id === type) : options[0];
  const onTabChange = (tab: OptionI) => {
    onTypeChange(tab.id === ALL_ID ? null : (tab.id as LeadType));
  };

  const [loading, setLoading] = useState(false);
  const onMarkAllClick = async () => {
    setLoading(true);

    try {
      await markAllLeadsAsViewed();
      onMarkAll();
    } catch (error) {
      showAlert({ error });
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className={cn(cls.root, 'hide-scrollbar')}>
      <Tabs
        variant="underline"
        tabs={options}
        active={option}
        onChange={onTabChange}
        disabled={loading}
      />
      <Button
        onClick={onMarkAllClick}
        loading={loading}
        disabled={loading}
        variant="outline"
        size="compact"
        color="black"
        gap={4}
      >
        <CheckIcon />
        {t('leads.markAll')}
      </Button>
    </div>
  );
}
