import cn from 'classnames';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';

import { useCommonStore } from '@/app/common-store';
import { CARS_ADVERTS_URL } from '@/modules/showroom/advert/my/helpers';
import {
  MappedNavChild,
  useNavList
} from '@/ui/layouts/SidebarLayout/Sidebar/Nav/helpers';
import { SidebarNavModal } from '@/ui/layouts/SidebarLayout/Sidebar/SidebarNavModal';

import { MenuIcon } from './icons';
import cls from './NavBar.module.scss';

const paths = ['/', CARS_ADVERTS_URL, '/leads', '/chat'];

export function NavBar() {
  const { t } = useTranslation();

  const [isSidebarModalOpen, setSidebarModalOpen] = useState(false);

  const clientComm = useCommonStore((s) => s.clientCommunication);
  const navList = useNavList(clientComm);
  const navListChildren = navList.reduce<MappedNavChild[]>(
    (acc, cur) => [...acc, ...cur.children],
    []
  );

  const barList = useMemo(
    () =>
      [...navListChildren.filter((v) => paths.includes(v.path))].sort(
        (a, b) => {
          return paths.indexOf(a.path) - paths.indexOf(b.path);
        }
      ),
    [navListChildren]
  );

  const menuCount = useMemo(
    () => navListChildren.reduce((acc, cur) => acc + (cur.count || 0), 0),
    [navListChildren]
  );

  return (
    <>
      <div className={cls.root}>
        <ul className={cls.list}>
          {barList.map((v) => {
            const showCount =
              'count' in v && !!v.count && typeof v.count === 'number';

            return (
              <li key={v.path}>
                <NavLink
                  className={({ isActive }) =>
                    cn(cls.list_link, {
                      [cls.list_link_active]: isActive
                    })
                  }
                  to={v.path}
                  end={v.end}
                >
                  <span className={cls.icon}>
                    {v.icon}
                    {showCount && <span className={cls.count}>{v.count}</span>}
                  </span>
                  <span className={cls.label}>{t(v.label)}</span>
                </NavLink>
              </li>
            );
          })}

          <li>
            <button
              className={cls.list_link}
              type="button"
              onClick={() => setSidebarModalOpen(true)}
            >
              <span className={cls.icon}>
                <MenuIcon />
                {!!menuCount && <span className={cls.count}>{menuCount}</span>}
              </span>
              <span className={cls.label}>{t('menu')}</span>
            </button>
          </li>
        </ul>
      </div>

      <SidebarNavModal
        isOpen={isSidebarModalOpen}
        close={() => setSidebarModalOpen(false)}
      />
    </>
  );
}
