import { CallbackRow } from '@/modules/callback/CallbackPage/CallbackRow/CallbackRow';
import { CallbackItem } from '@/modules/callback/types';

import cls from './CallbackList.module.scss';

type Props = {
  list: CallbackItem[];
};

export function CallbackList({ list }: Props) {
  return (
    <>
      {list.map((el) => (
        <div key={el.id} className={cls.li}>
          <CallbackRow data={el} />
        </div>
      ))}
    </>
  );
}
