import { OptionI } from '@/app/types';
import { usePaymentMethodInfo } from '@/modules/wallet/AutoReplenishment/SavedPaymentMethods/hooks';
import { SavedPaymentMethod } from '@/modules/wallet/AutoReplenishment/types';

import cls from './SavedPaymentMethods.module.scss';

type Props = {
  value: OptionI;
  paymentMethods: SavedPaymentMethod[];
  onChange: (value: OptionI) => void;
  closeDropdown: () => void;
};

export function PaymentMethodSelectOption({
  value,
  paymentMethods,
  onChange,
  closeDropdown
}: Props) {
  const { PaymentMethodIcon, paymentMethodName } = usePaymentMethodInfo(
    paymentMethods,
    value.id
  );

  const handleOptionClick = () => {
    onChange(value);
    closeDropdown();
  };

  return (
    <button className={cls.option_value} onClick={handleOptionClick}>
      <div className={cls.card}>
        <PaymentMethodIcon />

        <div className={cls.details}>
          <p className={cls.brand}>{paymentMethodName}</p>
          <p className={cls.number}>{`**** ${value.text}`}</p>
        </div>
      </div>
    </button>
  );
}
