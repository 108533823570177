import { useMemo } from 'react';

import {
  AmexIcon,
  DinersIcon,
  DiscoverIcon,
  EftposIcon,
  JcbIcon,
  MastercardIcon,
  UnionpayIcon,
  UnknownCardBrandIcon,
  VisaIcon
} from '@/modules/wallet/AutoReplenishment/SavedPaymentMethods/icons';
import {
  PaymentMethodBrand,
  SavedPaymentMethod
} from '@/modules/wallet/AutoReplenishment/types';

const BRAND_ICON_MAP = {
  [PaymentMethodBrand.amex]: AmexIcon,
  [PaymentMethodBrand.diners]: DinersIcon,
  [PaymentMethodBrand.discover]: DiscoverIcon,
  [PaymentMethodBrand.jcb]: JcbIcon,
  [PaymentMethodBrand.eftpos_au]: EftposIcon,
  [PaymentMethodBrand.mastercard]: MastercardIcon,
  [PaymentMethodBrand.unionpay]: UnionpayIcon,
  [PaymentMethodBrand.visa]: VisaIcon,
  [PaymentMethodBrand.unknown]: UnknownCardBrandIcon
};

const BRAND_NAME_MAP = {
  [PaymentMethodBrand.amex]: 'American Express',
  [PaymentMethodBrand.diners]: 'Diners Club International',
  [PaymentMethodBrand.discover]: 'Discover Card',
  [PaymentMethodBrand.jcb]: 'JCB',
  [PaymentMethodBrand.eftpos_au]: 'eftpos Australia',
  [PaymentMethodBrand.mastercard]: 'Mastercard',
  [PaymentMethodBrand.unionpay]: 'UnionPay',
  [PaymentMethodBrand.visa]: 'Visa',
  [PaymentMethodBrand.unknown]: 'Unknown'
};

export const usePaymentMethodInfo = (
  paymentMethods: SavedPaymentMethod[],
  selectedPaymentMethodId?: SavedPaymentMethod['payment_method_id']
) => {
  const selectedPaymentMethod = useMemo(
    () =>
      paymentMethods.find(
        (pm) => pm.payment_method_id === selectedPaymentMethodId
      ),
    [paymentMethods, selectedPaymentMethodId]
  );

  const PaymentMethodIcon = useMemo(() => {
    if (!selectedPaymentMethod)
      return BRAND_ICON_MAP[PaymentMethodBrand.unknown];

    return BRAND_ICON_MAP[selectedPaymentMethod.brand];
  }, [selectedPaymentMethod]);

  const paymentMethodName = useMemo(() => {
    if (!selectedPaymentMethod)
      return BRAND_NAME_MAP[PaymentMethodBrand.unknown];

    return BRAND_NAME_MAP[selectedPaymentMethod.brand];
  }, [selectedPaymentMethod]);

  return { PaymentMethodIcon, paymentMethodName };
};
