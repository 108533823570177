import { useTranslation } from 'react-i18next';

import { useAccountStore } from '@/modules/accounts/store';
import { GreetSetting } from '@/modules/chat/ChatSettings/GreetSetting';
import { StartSetting } from '@/modules/chat/ChatSettings/StartSetting';
import { useUserChatSettings } from '@/modules/chat/hooks';
import { Spinner } from '@/ui/Spinner/Spinner';

import cls from './ChatSettings.module.scss';

export function ChatSettings() {
  const { t } = useTranslation();

  const accountInfo = useAccountStore((s) => s.accountInfo);
  const accId = accountInfo?.id;
  const [settings, loading] = useUserChatSettings(accId || null);

  return (
    <div className={cls.root}>
      <h2 className={cls.title}>{t('chat.settings.title')}</h2>

      {settings && (
        <ul className={cls.list}>
          <li>
            <StartSetting settings={settings?.start_page} />
          </li>
          <li>
            <GreetSetting settings={settings?.greeting_message} />
          </li>
        </ul>
      )}

      {!settings && loading && (
        <Spinner color="var(--clr-blue)" size={24} centered />
      )}
    </div>
  );
}
