import cn from 'classnames';
import { useTranslation } from 'react-i18next';

import { PublishResultWrap } from '@/modules/showroom/advert/update/AdditionalInfo/PublishResultWrap';
import { useAdvertUpdateFlags } from '@/modules/showroom/advert/update/hooks';
import { useUpdateAdvertStore } from '@/modules/showroom/advert/update/store';

import emptyImg from './empty.png';
import cls from './StickyBar.module.scss';

export function StickyBar() {
  const { t } = useTranslation();

  const { showPromo: shouldShowPromo, canPublish } = useAdvertUpdateFlags();

  const isPriceFilled = useUpdateAdvertStore((s) => s.isPriceFilled);
  const lastSectionVisible = useUpdateAdvertStore((s) => s.lastSectionVisible);

  const photos = useUpdateAdvertStore((s) => s.photos);
  const brand = useUpdateAdvertStore((s) => s.brand);
  const model = useUpdateAdvertStore((s) => s.model);
  const generation = useUpdateAdvertStore((s) => s.generation);
  const body = useUpdateAdvertStore((s) => s.body);
  const modification = useUpdateAdvertStore((s) => s.modification);
  const year = useUpdateAdvertStore((s) => s.year);

  const photoSrc = photos[0]
    ? photos[0].thumbnail_url || photos[0].photo_url
    : emptyImg;

  const infoNames = [
    year?.name,
    generation?.name,
    body?.name,
    modification?.name
  ]
    .filter((v) => !!v)
    .join(` ${t('common.dot')} `);

  return (
    <div
      className={cn(cls.root, {
        [cls.root_hidden]: lastSectionVisible && shouldShowPromo
      })}
    >
      <div className="container">
        <div className={cls.inner}>
          <div className={cls.row}>
            <div className={cls.img}>
              <img className={cls.img_inner} src={photoSrc} alt="" />
            </div>

            {brand && (
              <div>
                <p className={cls.name}>
                  {brand?.name} {model?.name}
                </p>
                {year && <p className={cls.info}>{infoNames}</p>}
              </div>
            )}
          </div>

          <PublishResultWrap
            disablePublish={
              shouldShowPromo ? !isPriceFilled || !canPublish : !canPublish
            }
            hideDraft={!shouldShowPromo}
            buttonsOnly
          />
        </div>
      </div>
    </div>
  );
}
