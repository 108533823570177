import { Navigate, Outlet, useLocation } from 'react-router-dom';

import { useRedirectCheck } from '@/hooks/useRedirectCheck';
import { useAccountStore } from '@/modules/accounts/store';
import { useAuthed } from '@/modules/auth/hooks';
import { useAuthStore } from '@/modules/auth/store';
import { AuthState } from '@/modules/auth/types';
import { ROUTES_PERMISSIONS_MAP } from '@/modules/roles/helpers';
import { RoleTypeOption } from '@/modules/roles/types';
import { useAvailablePathByRole } from '@/ui/layouts/SidebarLayout/Sidebar/Nav/helpers';

export function PrivateRoute() {
  const location = useLocation();
  const { pathname } = location;
  const [isAuthed, authState] = useAuthed();
  const availablePath = useAvailablePathByRole();

  const auth = useAuthStore((s) => s.auth);
  const employeeInfo = useAccountStore((s) => s.employeeInfo);
  const permissions = employeeInfo?.permissions;

  const {
    shouldConfirmEmail,
    shouldCreateShowroom,
    shouldLeaveShowroomCreate
  } = useRedirectCheck();

  if (authState === AuthState.initial) {
    return null;
  }

  // Confirm email
  if (shouldConfirmEmail && pathname !== '/confirm-email') {
    return <Navigate to="/confirm-email" state={{ from: location }} />;
  }

  // Showroom creation
  if (shouldCreateShowroom && !pathname.startsWith('/showroom/create')) {
    return <Navigate to="/showroom/create" state={{ from: location }} />;
  }

  if (shouldLeaveShowroomCreate && pathname.startsWith('/showroom/create')) {
    return <Navigate to={availablePath} state={{ from: location }} />;
  }

  // Protect routes by role
  if (auth && !auth.is_owner) {
    if (!permissions) return null;

    const options = permissions.reduce(
      (acc, cur) => [...acc, ...cur.options],
      [] as RoleTypeOption[]
    );
    const optionsIds = options.map((opt) => opt.id);
    const subOptionsIds = options.reduce(
      (acc, cur) => [...acc, ...(cur.sub_options || []).map((v) => v.id)],
      [] as number[]
    );

    let allowed = true;

    if (pathname === '/') {
      allowed = optionsIds.some((optId) =>
        ROUTES_PERMISSIONS_MAP[pathname].options?.includes(optId)
      );
    } else {
      const permRoutes = Object.keys(ROUTES_PERMISSIONS_MAP).filter(
        (r) => r !== '/'
      );
      const permRoute = permRoutes.find(
        (r) => r === pathname || pathname.startsWith(r)
      );

      if (permRoute) {
        const cfg = ROUTES_PERMISSIONS_MAP[permRoute];
        if (cfg.options && cfg.options.length > 0) {
          allowed = optionsIds.some((optId) => cfg.options?.includes(optId));
        }

        if (cfg.sub && cfg.sub.length > 0) {
          allowed = subOptionsIds.some((optId) => cfg.sub?.includes(optId));
        }
      }
    }

    return allowed ? (
      <Outlet />
    ) : (
      <Navigate to={availablePath} state={{ from: location }} />
    );
  }

  // Auth
  return isAuthed ? (
    <Outlet />
  ) : (
    <Navigate to="/signin" state={{ from: location }} />
  );
}
