'use client';

import { AddressWrap } from '@/modules/showroom/advert/rent/update/AdditionalInfo/AddressWrap';
import { AgeWrap } from '@/modules/showroom/advert/rent/update/AdditionalInfo/Age/AgeWrap';
import { AvailableBookingWrap } from '@/modules/showroom/advert/rent/update/AdditionalInfo/AvailableBooking/AvailableBookingWrap';
import { ComplectationWrap } from '@/modules/showroom/advert/rent/update/AdditionalInfo/ComplectationWrap';
import { DepositWrap } from '@/modules/showroom/advert/rent/update/AdditionalInfo/Deposit/DepositWrap';
import { DescriptionWrap } from '@/modules/showroom/advert/rent/update/AdditionalInfo/DescriptionWrap';
import { DocsWrap } from '@/modules/showroom/advert/rent/update/AdditionalInfo/Docs/DocsWrap';
import { PayMethodsWrap } from '@/modules/showroom/advert/rent/update/AdditionalInfo/PayMethods/PayMethodsWrap';
import { PhoneWrap } from '@/modules/showroom/advert/rent/update/AdditionalInfo/PhoneWrap';
import { PhotosWrap } from '@/modules/showroom/advert/rent/update/AdditionalInfo/PhotosWrap';
// import { PromotionWrap } from '@/modules/showroom/advert/rent/update/AdditionalInfo/PromotionWrap';
import { PublishResultWrap } from '@/modules/showroom/advert/rent/update/AdditionalInfo/PublishResultWrap';
// import { StickersWrap } from '@/modules/showroom/advert/rent/update/AdditionalInfo/StickersWrap';
import { TariffsWrap } from '@/modules/showroom/advert/rent/update/AdditionalInfo/Tariffs/TariffsWrap';
import { useRentAdvertUpdateFlags } from '@/modules/showroom/advert/rent/update/hooks';
import { useRentUpdateAdvertStore } from '@/modules/showroom/advert/rent/update/store';

export function AdditionalInfo() {
  const { showPromo } = useRentAdvertUpdateFlags();

  const isPhotosFilled = useRentUpdateAdvertStore((s) => s.isPhotosFilled);
  const isComplectationFilled = useRentUpdateAdvertStore(
    (s) => s.isComplectationFilled
  );

  const isDescriptionFilled = useRentUpdateAdvertStore(
    (s) => s.isDescriptionFilled
  );
  const isTariffsFilled = useRentUpdateAdvertStore((s) => s.isTariffsFilled);
  const isDepositFilled = useRentUpdateAdvertStore((s) => s.isDepositFilled);
  const isAgeFilled = useRentUpdateAdvertStore((s) => s.isAgeFilled);
  const isAddressFilled = useRentUpdateAdvertStore((s) => s.isAddressFilled);
  const isBookingAvailableFilled = useRentUpdateAdvertStore(
    (s) => s.isBookingAvailableFilled
  );
  const isPhoneFilled = useRentUpdateAdvertStore((s) => s.isPhoneFilled);
  const isPayMethodIdsFilled = useRentUpdateAdvertStore(
    (s) => s.isPayMethodIdsFilled
  );
  const isDocsIdsFilled = useRentUpdateAdvertStore((s) => s.isDocsIdsFilled);

  return (
    <>
      <PhotosWrap />

      {isPhotosFilled && (
        <>
          <ComplectationWrap />

          {isComplectationFilled && (
            <>
              <DescriptionWrap />

              {isDescriptionFilled && (
                <>
                  <TariffsWrap />

                  {isTariffsFilled && (
                    <>
                      <DepositWrap />

                      {isDepositFilled && (
                        <>
                          <AgeWrap />

                          {isAgeFilled && (
                            <>
                              <PayMethodsWrap />

                              {isPayMethodIdsFilled && (
                                <>
                                  <DocsWrap />

                                  {isDocsIdsFilled && (
                                    <>
                                      <AddressWrap />

                                      {isAddressFilled && (
                                        <>
                                          <AvailableBookingWrap />

                                          {isBookingAvailableFilled && (
                                            <>
                                              <PhoneWrap />

                                              {isPhoneFilled && (
                                                <>
                                                  {showPromo && (
                                                    <>
                                                      {/* <StickersWrap /> */}
                                                      {/* <PromotionWrap /> */}
                                                      <PublishResultWrap />
                                                    </>
                                                  )}
                                                </>
                                              )}
                                            </>
                                          )}
                                        </>
                                      )}
                                    </>
                                  )}
                                </>
                              )}
                            </>
                          )}
                        </>
                      )}
                    </>
                  )}
                </>
              )}
            </>
          )}
        </>
      )}
    </>
  );
}
