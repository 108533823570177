import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { editStartPage } from '@/modules/chat/api-req';
import { ChatSetting } from '@/modules/chat/ChatSettings/ChatSetting/ChatSetting';
import { ChatSettingsRes } from '@/modules/chat/types';
import { Input } from '@/ui/Input/Input';
import { maxLength } from '@/utils/format';
import { showAlert } from '@/utils/network';

import cls from './ChatSettings.module.scss';
import { StartIcon } from './icons';

const MAX_TITLE_LEN = 32;
const MAX_SUBTITLE_LEN = 70;

type Props = {
  settings: ChatSettingsRes['start_page'];
};

export function StartSetting({ settings }: Props) {
  const { t } = useTranslation();

  const [saving, setSaving] = useState(false);

  const [enabled, setEnabled] = useState(!!settings);
  const toggle = async () => {
    setSaving(true);

    const oldValue = enabled;
    const newValue = !oldValue;
    setEnabled(newValue);

    try {
      await editStartPage({ detach: !newValue });
      setSaving(false);
    } catch (error) {
      setEnabled(oldValue);
      showAlert({ error });
      setSaving(false);
    }
  };

  const [title, setTitle] = useState(settings?.title || '');
  const [subtitle, setSubtitle] = useState(settings?.description || '');

  const onBlur = async () => {
    setSaving(true);

    try {
      const hasValues = !!title || !!subtitle;
      await editStartPage({
        detach: hasValues ? false : true,
        cfg: {
          title,
          description: subtitle
        }
      });
      setSaving(false);
    } catch (error) {
      showAlert({ error });
      setSaving(false);
    }
  };

  useEffect(() => {
    setEnabled(!!settings);
    setTitle(settings?.title || '');
    setSubtitle(settings?.description || '');
  }, [settings]);

  return (
    <ChatSetting
      enabled={enabled}
      icon={<StartIcon />}
      title={t('chat.settings.start.title')}
      subtitle={t('chat.settings.start.subtitle')}
      disabled={saving}
      toggle={toggle}
    >
      <div className={cls.message}>
        <b>{title || t('chat.settings.start.empty.title')}</b>
        <p>{subtitle || t('chat.settings.start.empty.subtitle')}</p>
      </div>

      <div className={cls.field}>
        <Input
          value={title}
          onChange={(e) =>
            setTitle(maxLength(e.currentTarget.value, MAX_TITLE_LEN))
          }
          onBlur={onBlur}
          disabled={saving}
          placeholder={t('chat.settings.enterTitle')}
          bottomLabel={t('common.slashSeparated', {
            first: title.length,
            second: MAX_TITLE_LEN
          })}
          bottomLabelRight
          small
        />
      </div>

      <div className={cls.field}>
        <Input
          value={subtitle}
          onChange={(e) =>
            setSubtitle(maxLength(e.currentTarget.value, MAX_SUBTITLE_LEN))
          }
          onBlur={onBlur}
          disabled={saving}
          placeholder={t('chat.settings.enterMessage')}
          bottomLabel={t('common.slashSeparated', {
            first: subtitle.length,
            second: MAX_SUBTITLE_LEN
          })}
          bottomLabelRight
          small
        />
      </div>
    </ChatSetting>
  );
}
