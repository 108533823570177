import cn from 'classnames';
import { useEffect, useRef, useState } from 'react';

import { Nullable, OptionI } from '@/app/types';
import { useWindowClick } from '@/hooks/useWindowClick';
import { Dropdown } from '@/ui/Dropdown/Dropdown';
import { escapePlus } from '@/utils/format';

import { SELECT_NO_VALUE } from './helpers';
import { ChevronIcon, CheckedIcon } from './icons';
import cls from './Select.module.scss';

interface Props {
  name: string;
  valueText?: string;
  from?: Nullable<OptionI>;
  to?: Nullable<OptionI>;
  fromOptions: Array<OptionI>;
  toOptions: Array<OptionI>;
  onFromChange: (option: OptionI) => void;
  onToChange: (option: OptionI) => void;
  onClose?: () => void;
  label?: Nullable<string>;
  disabled?: boolean;
  dropdownCls?: string;
  dropdownToLeft?: boolean;
  dropdownTop?: boolean;
  error?: boolean;
  active?: boolean;
  size?: 's' | 'default';
  fullWidth?: boolean;
}

export const SelectRange: React.FC<Props> = ({
  onClose,
  name,
  valueText,
  from,
  to,
  onFromChange,
  onToChange,
  fromOptions,
  toOptions,
  label,
  disabled,
  dropdownCls,
  error,
  active,
  dropdownToLeft,
  dropdownTop,
  size = 'default',
  fullWidth
}) => {
  const rootRef = useRef(null);
  const [isOpen, setOpen] = useState<boolean>(false);

  const closeDropdown = () => {
    setOpen(false);
    if (onClose) onClose();
  };

  const onSelectBtnClick = () => {
    if (isOpen) {
      closeDropdown();
    } else {
      setOpen(true);
    }
  };

  useWindowClick((e) => {
    const el = e.target as HTMLElement | null;
    const clickedRoot = el?.closest(`.${escapePlus(cls.root)}`);
    const thisRoot = rootRef?.current;

    if (!!clickedRoot && clickedRoot !== thisRoot) {
      closeDropdown();
    }
  });

  const filteredFrom = fromOptions.filter((v) => v.id !== SELECT_NO_VALUE);
  const filteredTo = toOptions.filter((v) => v.id !== SELECT_NO_VALUE);

  // Scroll on open
  const fromRef = useRef<HTMLDivElement>(null);
  const toRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const fromEl = fromRef.current;
    const toEl = toRef.current;
    if (isOpen && fromEl && toEl && from && to) {
      const fromLi = fromEl.querySelector(
        `[data-from-id="${from.id}"]`
      ) as HTMLElement;
      const toLi = toEl.querySelector(`[data-to-id="${to.id}"]`) as HTMLElement;

      if (fromLi) {
        fromEl.scrollTo({
          top: fromLi.offsetTop || 0,
          left: 0,
          behavior: 'smooth'
        });
      }

      if (toLi) {
        toEl.scrollTo({
          top: toLi.offsetTop || 0,
          left: 0,
          behavior: 'smooth'
        });
      }
    }
  }, [from, isOpen, to]);

  return (
    <>
      <div
        ref={rootRef}
        className={cn(cls.root, {
          [cls.root_small]: size === 's',
          [cls.root_active]: active,
          [cls.root_open]: isOpen,
          [cls.root_selected]: !!from || !!to,
          [cls.root_error]: !!error,
          [cls.root_disabled]: !!disabled,
          [cls.root_labeled]: !!label
        })}
      >
        {label && <span className={cls.label}>{label}</span>}

        <button
          className={cls.select_btn}
          type="button"
          onClick={onSelectBtnClick}
          disabled={disabled}
        >
          {valueText ? (
            <span className={cls.button_name}>{valueText}</span>
          ) : (
            <span className={cls.button_name}>
              {from?.text} {to?.text}
            </span>
          )}
          <ChevronIcon />
        </button>

        <Dropdown
          name={name}
          isOpen={isOpen}
          close={closeDropdown}
          className={cn(cls.range_dropdown, dropdownCls)}
          anchorClassName={cls.dropdown}
          toLeft={dropdownToLeft}
          listTop={dropdownTop}
          fullWidth={fullWidth}
          withModal
        >
          <li>
            <div
              className={cn(cls.range_wrap, {
                [cls.range_wrap_fullwidth]: fullWidth
              })}
            >
              <div className={cls.range_list_wrap} ref={fromRef}>
                <ul className={cls.range_list}>
                  {filteredFrom.map((v) => {
                    const isActive = from?.id === v.id;

                    return (
                      <li key={v.id} data-from-id={v.id}>
                        <button
                          className={cn(cls.list_btn, {
                            [cls.list_btn_active]: isActive
                          })}
                          type="button"
                          onClick={() => {
                            onFromChange(v);
                          }}
                        >
                          <span
                            className={cls.list_check}
                            style={{
                              visibility: isActive ? 'visible' : 'hidden'
                            }}
                          >
                            <CheckedIcon />
                          </span>
                          <span className={cls.list_text}>{v.text}</span>
                          {v.extra && (
                            <span className={cls.extra}>{v.extra}</span>
                          )}
                        </button>
                      </li>
                    );
                  })}
                </ul>
              </div>
              <div className={cls.range_list_wrap} ref={toRef}>
                <ul className={cls.range_list}>
                  {filteredTo.map((v) => {
                    const isActive = to?.id === v.id;

                    return (
                      <li key={v.id} data-to-id={v.id}>
                        <button
                          className={cn(cls.list_btn, {
                            [cls.list_btn_active]: isActive
                          })}
                          type="button"
                          onClick={() => {
                            onToChange(v);
                          }}
                        >
                          <span
                            className={cls.list_check}
                            style={{
                              visibility: isActive ? 'visible' : 'hidden'
                            }}
                          >
                            <CheckedIcon />
                          </span>
                          <span className={cls.list_text}>{v.text}</span>
                          {v.extra && (
                            <span className={cls.extra}>{v.extra}</span>
                          )}
                        </button>
                      </li>
                    );
                  })}
                </ul>
              </div>
            </div>
          </li>
        </Dropdown>
      </div>
    </>
  );
};

SelectRange.displayName = 'SelectRange';
