import { Nullable } from '@/app/types';

export type AutoReplenishmentInfo = {
  is_enabled: boolean;
  payment_method_id: Nullable<string>;
  min_balance_threshold: Nullable<number>;
  min_days_threshold: Nullable<number>;
  replenishment_amount: Nullable<number>;
  last_auto_replenishment_at: Nullable<string>;
};

export type AutoReplenishmentRes = AutoReplenishmentInfo;

export type UpdateAutoReplenishmentReq = {
  is_enabled: boolean;
  payment_method_id?: string;
  min_balance_threshold?: number;
  min_days_threshold?: number;
  replenishment_amount?: number;
};

// brands from https://docs.stripe.com/api/payment_methods/object#payment_method_object-card
export enum PaymentMethodBrand {
  amex = 'amex',
  diners = 'diners',
  discover = 'discover',
  eftpos_au = 'eftpos_au',
  jcb = 'jcb',
  mastercard = 'mastercard',
  unionpay = 'unionpay',
  visa = 'visa',
  unknown = 'unknown'
}

export type SavedPaymentMethod = {
  payment_method_id: string;
  last_four: string;
  brand: PaymentMethodBrand;
  exp_month: number;
  exp_year: number;
  currently_in_use_for_auto_replenishment: boolean;
};

export type SavedPaymentMethodsRes = SavedPaymentMethod[];
