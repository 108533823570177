import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Nullable } from '@/app/types';
import { useLang } from '@/hooks/useLang';
import { updateAutoReplenishmentSettings } from '@/modules/wallet/AutoReplenishment/api';
import { SavedPaymentMethods } from '@/modules/wallet/AutoReplenishment/SavedPaymentMethods/SavedPaymentMethods';
import {
  AutoReplenishmentInfo,
  SavedPaymentMethod,
  UpdateAutoReplenishmentReq
} from '@/modules/wallet/AutoReplenishment/types';
import { Button } from '@/ui/Button/Button';
import { Checkbox } from '@/ui/Checkbox/Checkbox';
import { Input } from '@/ui/Input/Input';
import { Spinner } from '@/ui/Spinner/Spinner';
import { Switch } from '@/ui/Switch/Switch';
import { CURRENCY_DISPLAY } from '@/utils/consts';
import { numberWithSpaces, onlyNumbers } from '@/utils/format';
import { showAlert } from '@/utils/network';

import cls from './AutoReplenishment.module.scss';

type Props = {
  replenishmentInfo: AutoReplenishmentInfo;
  isLoading?: boolean;
  updateInfo: () => Promise<void>;
  autoSave?: boolean;
};

export function AutoReplenishmentForm({
  replenishmentInfo,
  isLoading,
  updateInfo,
  autoSave
}: Props) {
  const { t } = useTranslation();
  const [lang] = useLang();

  const [isReplenishmentEnabled, setReplenishmentEnabled] = useState(
    replenishmentInfo.is_enabled
  );
  const [minBalanceThreshold, setMinBalanceThreshold] = useState(
    replenishmentInfo.min_balance_threshold?.toString() || ''
  );
  const [minDaysThreshold, setMinDaysThreshold] = useState(
    replenishmentInfo.min_days_threshold?.toString() || ''
  );
  const [maxReplenishmentAmount, setMaxReplenishmentAmount] = useState(
    replenishmentInfo.replenishment_amount?.toString() || ''
  );
  const [paymentMethodId, setPaymentMethodId] = useState<
    Nullable<SavedPaymentMethod['payment_method_id']>
  >(replenishmentInfo.payment_method_id);
  const [isMinBalanceEnabled, setMinBalanceEnabled] = useState(
    !!replenishmentInfo.min_balance_threshold
  );
  const [isMinPeriodEnabled, setMinPeriodEnabled] = useState(
    !!replenishmentInfo.min_days_threshold
  );
  const [isUpdating, setUpdating] = useState(false);
  const saveTimeout = useRef<NodeJS.Timeout | null>(null);

  const handleSaveSettings = async () => {
    setUpdating(true);
    try {
      if (!isReplenishmentEnabled) {
        await updateAutoReplenishmentSettings({ is_enabled: false });
      } else {
        const req: UpdateAutoReplenishmentReq = {
          is_enabled: true,
          payment_method_id: paymentMethodId || undefined,
          min_balance_threshold: minBalanceThreshold
            ? Number(onlyNumbers(minBalanceThreshold))
            : undefined,
          min_days_threshold: minDaysThreshold
            ? Number(onlyNumbers(minDaysThreshold))
            : undefined,
          replenishment_amount: maxReplenishmentAmount
            ? Number(onlyNumbers(maxReplenishmentAmount))
            : undefined
        };

        await updateAutoReplenishmentSettings(req);
      }
      !autoSave &&
        showAlert({ type: 'success', text: t('replenishment.settingsSaved') });
      updateInfo();
    } catch (error) {
      showAlert({ error });
    } finally {
      setUpdating(false);
    }
  };

  useEffect(() => {
    if (autoSave) {
      if (saveTimeout.current) clearTimeout(saveTimeout.current);

      saveTimeout.current = setTimeout(() => {
        handleSaveSettings();
      }, 1000);

      return () => {
        if (saveTimeout.current) clearTimeout(saveTimeout.current);
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    isReplenishmentEnabled,
    minBalanceThreshold,
    minDaysThreshold,
    maxReplenishmentAmount,
    paymentMethodId,
    autoSave
  ]);

  useEffect(() => {
    setReplenishmentEnabled(replenishmentInfo.is_enabled);
    setMinBalanceThreshold(
      replenishmentInfo.min_balance_threshold?.toString() || ''
    );
    setMinDaysThreshold(replenishmentInfo.min_days_threshold?.toString() || '');
    setMaxReplenishmentAmount(
      replenishmentInfo.replenishment_amount?.toString() || ''
    );
    setPaymentMethodId(replenishmentInfo.payment_method_id);
    setMinBalanceEnabled(!!replenishmentInfo.min_balance_threshold);
    setMinPeriodEnabled(!!replenishmentInfo.min_days_threshold);
  }, [replenishmentInfo]);

  return (
    <>
      {isLoading && (
        <div className={cls.loading}>
          <Spinner />
        </div>
      )}

      {!isLoading && (
        <div className={cls.modal_root}>
          <div className={cls.switch}>
            <div className={cls.switch_text}>
              <b>{t('replenishment.title')}</b>
              <p>{t('replenishment.aboutReplenishment')}</p>
            </div>
            <Switch
              id="auto-replenishment-toggle"
              checked={isReplenishmentEnabled}
              disabled={isUpdating}
              green
            />
            <button
              onClick={() => setReplenishmentEnabled(!isReplenishmentEnabled)}
              type="button"
              disabled={isUpdating}
            />
          </div>

          {isReplenishmentEnabled && (
            <>
              <div className={cls.setting_row}>
                <h5>{t('replenishment.card')}</h5>
                <SavedPaymentMethods
                  selectedPaymentMethodId={paymentMethodId}
                  onPaymentMethodIdChange={(paymentMethod) =>
                    setPaymentMethodId(paymentMethod)
                  }
                  disabled={isUpdating}
                />
              </div>

              <div className={cls.divider} />

              <div className={cls.setting_row}>
                <h5>{t('replenishment.terms')}</h5>
                <div className={cls.term_row}>
                  <Checkbox
                    id="minimal-balance-term"
                    checked={isMinBalanceEnabled}
                    onChange={() => setMinBalanceEnabled(!isMinBalanceEnabled)}
                    disabled={isUpdating}
                  />
                  <div className={cls.input}>
                    <p className={cls.term_name}>
                      {t('replenishment.minBalance')}
                    </p>
                    <p className={cls.term_info}>
                      {t('replenishment.minBalanceDescription')}
                    </p>
                    {isMinBalanceEnabled && (
                      <Input
                        value={
                          minBalanceThreshold
                            ? numberWithSpaces(
                                Number(minBalanceThreshold),
                                lang
                              )
                            : ''
                        }
                        onChange={(e) =>
                          setMinBalanceThreshold(
                            onlyNumbers(e.currentTarget.value)
                          )
                        }
                        label={t('replenishment.minBalanceLabel', {
                          currency: CURRENCY_DISPLAY.aed
                        })}
                        disabled={isUpdating}
                      />
                    )}
                  </div>
                </div>

                <div className={cls.term_row}>
                  <Checkbox
                    id="minimal-period-term"
                    checked={isMinPeriodEnabled}
                    onChange={() => setMinPeriodEnabled(!isMinPeriodEnabled)}
                    disabled={isUpdating}
                  />
                  <div className={cls.input}>
                    <p className={cls.term_name}>
                      {t('replenishment.minPeriod')}
                    </p>
                    <p className={cls.term_info}>
                      {t('replenishment.minPeriodDescription')}
                    </p>
                    {isMinPeriodEnabled && (
                      <Input
                        value={minDaysThreshold}
                        onChange={(e) =>
                          setMinDaysThreshold(
                            onlyNumbers(e.currentTarget.value)
                          )
                        }
                        label={t('replenishment.minPeriodLabel')}
                        disabled={isUpdating}
                      />
                    )}
                  </div>
                </div>
              </div>

              <div className={cls.divider} />

              <div className={cls.setting_row}>
                <h5>{t('replenishment.replenishmentLimit')}</h5>
                <div className={cls.input}>
                  <Input
                    value={
                      maxReplenishmentAmount
                        ? numberWithSpaces(Number(maxReplenishmentAmount), lang)
                        : ''
                    }
                    onChange={(e) =>
                      setMaxReplenishmentAmount(
                        onlyNumbers(e.currentTarget.value)
                      )
                    }
                    label={t('replenishment.maxReplenishmentAmountLabel')}
                    disabled={isUpdating}
                  />
                  <p className={cls.bottom_label}>
                    {t('replenishment.maxReplenishmentAmountDescription')}
                  </p>
                </div>
              </div>
            </>
          )}

          {!autoSave && (
            <Button
              color="green"
              onClick={handleSaveSettings}
              loading={isUpdating}
            >
              {t('common.save')}
            </Button>
          )}
        </div>
      )}
    </>
  );
}
