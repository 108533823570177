import { useState, useCallback, useEffect } from 'react';

import { axios } from '@/app/api';
import { PROMOTION_API_ROUTES } from '@/modules/promotion/api';
import { GetPromotionPackagesRes } from '@/modules/promotion/types';
import { usePermissionAccess } from '@/modules/roles/helpers';
import { showAlert } from '@/utils/network';

export function usePromotionPackages(
  skip?: boolean
): [GetPromotionPackagesRes | undefined, boolean] {
  const { tariffs } = usePermissionAccess();
  const hasAccess = tariffs.access;

  const [isLoading, setLoading] = useState<boolean>(false);
  const [data, setData] = useState<GetPromotionPackagesRes>();

  const load = useCallback(async () => {
    setLoading(true);

    try {
      const r = await axios.get<GetPromotionPackagesRes>(
        PROMOTION_API_ROUTES.getPromotionPackages
      );
      setData([...r.data.sort((a, b) => b.price - a.price)]);
    } catch (error) {
      showAlert({ error });
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    if (hasAccess && !skip) load();
  }, [hasAccess, load, skip]);

  return [data, isLoading];
}
