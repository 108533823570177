import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { LongPressEventType, useLongPress } from 'use-long-press';

import { deleteQuickReply } from '@/modules/chat/api-req';
import { handleText } from '@/modules/chat/Detail/Messages/Message/helpers';
import { MyTailIcon, RemoveIcon } from '@/modules/chat/QuickReplies/icons';
import { useQuickRepliesStore } from '@/modules/chat/QuickReplies/store';
import { QuickReplyType } from '@/modules/chat/types';
import { Dropdown, DropdownItem } from '@/ui/Dropdown/Dropdown';
import { isAndroid } from '@/utils/detect';
import { showAlert } from '@/utils/network';

import cls from './QuickReplies.module.scss';

type Props = {
  reply: QuickReplyType;
};

export function QuickReply({ reply }: Props) {
  const { t } = useTranslation();

  const setOpen = useQuickRepliesStore((s) => s.setOpen);
  const setQuickReplyText = useQuickRepliesStore((s) => s.setQuickReplyText);
  const fetchQuickReplies = useQuickRepliesStore((s) => s.fetchQuickReplies);

  const onReplyClick = (reply: QuickReplyType) => {
    const trimmed = reply.text.trim();
    setQuickReplyText(trimmed);
    setOpen(false);
  };

  const [isDropdownOpen, setDropdownOpen] = useState(false);
  const onCtxMenuClick = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    e.preventDefault();
    setDropdownOpen(true);
  };

  const longPressBind = useLongPress(
    (e) => {
      e.preventDefault();
      setDropdownOpen(true);
    },
    {
      detect: isAndroid() ? LongPressEventType.Mouse : LongPressEventType.Touch
    }
  );

  const [isDeleting, setDeleting] = useState(false);
  const onDeleteClick = async () => {
    setDeleting(true);

    try {
      await deleteQuickReply(reply.id);
      fetchQuickReplies();
      setDropdownOpen(false);
    } catch (error) {
      showAlert({ error });
    } finally {
      setDeleting(false);
    }
  };

  return (
    <div className={cls.reply}>
      <button
        className={cls.reply_btn}
        onClick={() => onReplyClick(reply)}
        onContextMenu={onCtxMenuClick}
        disabled={isDeleting}
        type="button"
        {...longPressBind()}
      />

      <div className={cls.message}>
        <p
          dangerouslySetInnerHTML={{
            __html: reply.text ? handleText(reply.text, false, true) : ''
          }}
        />

        <div className={cls.tail}>
          <MyTailIcon />
        </div>
      </div>

      {isDropdownOpen && (
        <Dropdown
          name={`quick-reply-actions-${reply.id}`}
          isOpen={isDropdownOpen}
          close={() => setDropdownOpen(false)}
          withModal
        >
          <DropdownItem>
            <button
              className={cls.dropdown_btn}
              type="button"
              onClick={onDeleteClick}
              disabled={isDeleting}
            >
              {t('common.delete')}
              <RemoveIcon />
            </button>
          </DropdownItem>
        </Dropdown>
      )}
    </div>
  );
}
