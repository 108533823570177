import cn from 'classnames';
import Tooltip from 'rc-tooltip';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import { useCommonStore } from '@/app/common-store';
import { Header } from '@/ui/layouts/SidebarLayout/Header/Header';
import { NavBar } from '@/ui/layouts/SidebarLayout/NavBar/NavBar';
import { Sidebar } from '@/ui/layouts/SidebarLayout/Sidebar/Sidebar';
import { TopupBanners } from '@/ui/layouts/SidebarLayout/TopupBanners/TopupBanners';
import { rcTooltipProps } from '@/utils/consts';

import { ChevronIcon } from './icons';
import cls from './SidebarLayout.module.scss';

type Props = {
  children: React.ReactNode;
};

export function SidebarLayout({ children }: Props) {
  const { t } = useTranslation();
  const { pathname } = useLocation();

  const isSidebarMinimized = useCommonStore((s) => s.isSidebarMinimized);
  const setSidebarMinimized = useCommonStore((s) => s.setSidebarMinimized);

  const isOverview = pathname === '/';
  const isChat = pathname.startsWith('/chat');
  const isWallet = pathname.startsWith('/payment/wallet');
  const isAdverts = pathname.startsWith('/adverts/');
  const isBooking = pathname.startsWith('/booking/');
  const isLeads = pathname.startsWith('/leads');
  const isTariffs = pathname.startsWith('/payment/tariffs');
  const isAddresses = pathname.startsWith('/showroom/address');
  const isContacts = pathname.startsWith('/showroom/contacts');
  const isReviews = pathname.startsWith('/showroom/reviews');
  const isRoles = pathname.startsWith('/showroom/roles');
  const isSettings = pathname.startsWith('/settings');
  const isCreditReqs = pathname.startsWith('/credits/requests');
  const isCallback = pathname.startsWith('/callback');

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <>
      <TopupBanners />
      <div
        className={cn(cls.root, {
          [cls.root_overview]: isOverview,
          [cls.root_chat]: isChat,
          [cls.root_adverts]: isAdverts || isBooking || isLeads,
          [cls.root_wallet]: isWallet,
          [cls.root_tariffs]: isTariffs,
          [cls.root_addresses]: isAddresses,
          [cls.root_contacts]: isContacts,
          [cls.root_reviews]: isReviews,
          [cls.root_roles]: isRoles,
          [cls.root_settings]: isSettings,
          [cls.root_creditreq]: isCreditReqs,
          [cls.root_callback]: isCallback
        })}
      >
        <div
          className={cn(cls.sidebar, {
            [cls.sidebar_minimized]: isSidebarMinimized
          })}
        >
          <Sidebar />
        </div>

        <div
          className={cn(cls.container, {
            [cls.container_sidebar_minimized]: isSidebarMinimized
          })}
        >
          <Header />
          <main className={cls.main}>
            <div className={cls.inner}>{children}</div>
          </main>
          <NavBar />
        </div>

        <div
          className={cn(cls.toggle_btn_pos, {
            [cls.toggle_btn_pos_min]: isSidebarMinimized
          })}
        >
          <div className={cls.toggle_btn_wrap}>
            <Tooltip
              placement="right"
              overlay={t(isSidebarMinimized ? 'maxSidebar' : 'minSidebar')}
              overlayStyle={{ pointerEvents: 'none', userSelect: 'none' }}
              {...rcTooltipProps}
            >
              <button
                className={cls.toggle_btn}
                type="button"
                onClick={() => setSidebarMinimized(!isSidebarMinimized)}
              >
                <ChevronIcon />
              </button>
            </Tooltip>
          </div>
        </div>
      </div>
    </>
  );
}
