export function ChevronIcon({
  className
}: {
  className?: string;
}): JSX.Element {
  return (
    <svg
      width="14"
      height="8"
      viewBox="0 0 14 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M6.99999 7.84574C7.26366 7.8382 7.48967 7.74027 7.69307 7.53687L13.4261 1.67582C13.5918 1.50255 13.6822 1.29914 13.6822 1.05054C13.6822 0.545794 13.2904 0.154053 12.7857 0.154053C12.5446 0.154053 12.3186 0.251988 12.1454 0.425258L6.99999 5.6987L1.85462 0.425258C1.68135 0.251988 1.46288 0.154053 1.21428 0.154053C0.709534 0.154053 0.317793 0.545794 0.317793 1.05054C0.317793 1.29161 0.408195 1.50255 0.573931 1.67582L6.31444 7.53687C6.51031 7.7478 6.73632 7.84574 6.99999 7.84574Z"
        fill="currentColor"
      />
    </svg>
  );
}

export function CheckedIcon(): JSX.Element {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.19071 17.5C8.62826 17.5 8.97342 17.3174 9.21323 16.9515L17.1225 4.62159C17.2997 4.34425 17.3708 4.11218 17.3708 3.88381C17.3708 3.30439 16.9633 2.90454 16.3779 2.90454C15.9625 2.90454 15.7214 3.04422 15.4694 3.4397L8.15705 15.0568L4.38887 10.2117C4.14367 9.88692 3.88636 9.75092 3.52286 9.75092C2.92207 9.75092 2.5 10.1715 2.5 10.7524C2.5 11.002 2.59323 11.2541 2.80309 11.509L7.16264 16.9635C7.45294 17.3265 7.76679 17.5 8.19071 17.5Z"
        fill="currentColor"
      />
    </svg>
  );
}
