import cn from 'classnames';

import { Nullable } from '@/app/types';
import { Button } from '@/ui/Button/Button';
import { AdaptiveModal } from '@/ui/modals/AdaptiveModal';
import { CommonModalProps } from '@/ui/modals/CommonModal/CommonModal';
import { Placeholder } from '@/ui/Placeholder/Placeholder';

import cls from './ConfirmModal.module.scss';

interface Props extends CommonModalProps {
  title: string;
  text?: Nullable<string>;
  confirmText: string;
  cancelText: string;
  onButtonClick: (confirmed: boolean) => void;
  loading?: boolean;
  imgSrc?: string;
  lottieSrc?: string;
}

export const ConfirmModal: React.FC<Props> = ({
  title,
  text,
  confirmText,
  cancelText,
  onButtonClick,
  containerClass,
  loading,
  imgSrc,
  lottieSrc,
  ...rest
}) => {
  return (
    <AdaptiveModal
      {...rest}
      containerClass={cn(containerClass, cls.modal_container)}
    >
      <Placeholder
        imgSrc={imgSrc}
        lottieSrc={lottieSrc}
        title={title}
        text={text}
      />
      <div className={cls.buttons}>
        <Button
          variant="secondary"
          color="black"
          onClick={() => onButtonClick(false)}
          disabled={loading}
        >
          {cancelText}
        </Button>
        <Button
          loading={loading}
          disabled={loading}
          onClick={() => onButtonClick(true)}
        >
          {confirmText}
        </Button>
      </div>
    </AdaptiveModal>
  );
};

ConfirmModal.displayName = 'ConfirmModal';
