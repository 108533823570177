/* eslint-disable import/no-duplicates */

import { sub } from 'date-fns';
import differenceInDays from 'date-fns/differenceInDays';
import differenceInHours from 'date-fns/differenceInHours';
import differenceInMilliseconds from 'date-fns/differenceInMilliseconds';
import differenceInMinutes from 'date-fns/differenceInMinutes';
import differenceInSeconds from 'date-fns/differenceInSeconds';
import differenceInYears from 'date-fns/differenceInYears';
import format from 'date-fns/format';
import formatDistanceToNowStrict from 'date-fns/formatDistanceToNowStrict';
import isDateSameMonth from 'date-fns/isSameMonth';
import isDateSameYear from 'date-fns/isSameYear';
import isDateThisWeek from 'date-fns/isThisWeek';
import isDateThisYear from 'date-fns/isThisYear';
import isDateToday from 'date-fns/isToday';
import isDateYesterday from 'date-fns/isYesterday';
import en from 'date-fns/locale/en-GB';
import ru from 'date-fns/locale/ru';

import { Nullable } from '@/app/types';
import { LANGS_MAP } from '@/modules/i18n';

// i18nADD
function getLocale(lang: string) {
  if (lang === LANGS_MAP.en) {
    return en;
  }

  return ru;
}

export function formatDate(d: Date, pattern: string, lang: string): string {
  return format(d, pattern, { locale: getLocale(lang) });
}

export function getWeekName(
  date: Date,
  lang: string,
  format: 'long' | 'short' | 'narrow' = 'long'
): string {
  return date.toLocaleDateString(lang, { weekday: format });
}

export function isToday(date: Date): boolean {
  return isDateToday(date);
}

export function isYesterday(date: Date): boolean {
  return isDateYesterday(date);
}

export function isThisYear(date: Date): boolean {
  return isDateThisYear(date);
}

export function isThisWeek(date: Date): boolean {
  return isDateThisWeek(date);
}

export function diffInHours(later: Date, earlier: Date) {
  let diff = (later.getTime() - earlier.getTime()) / 1000;
  diff /= 60 * 60;

  return Math.abs(Math.round(diff));
}

export function diffInDays(later: Date, earlier: Date) {
  return differenceInDays(later, earlier);
}

export function diffInMinutes(later: Date, earlier: Date): number {
  return differenceInMinutes(later, earlier);
}

export function diffInSeconds(later: Date, earlier: Date): number {
  return differenceInSeconds(later, earlier);
}

export function diffInMilliseconds(later: Date, earlier: Date): number {
  return differenceInMilliseconds(later, earlier);
}

export function isSameMonth(dateLeft: Date, dateRight: Date): boolean {
  return isDateSameMonth(dateLeft, dateRight);
}

export function isSameYear(dateLeft: Date, dateRight?: Date): boolean {
  return isDateSameYear(dateLeft, dateRight || new Date());
}

export function makeHoursArray(hours = 24): number[] {
  const arr = [];

  for (let i = 0; i <= hours; i++) {
    arr.push(i);
  }

  return arr;
}

export function minutesToHHMM(minutes: number): string {
  return new Date(minutes * 60 * 1000).toISOString().substr(11, 5);
}

export function getDateString(
  date: Date,
  lang: string,
  options?: Intl.DateTimeFormatOptions
): string {
  return date.toLocaleString(lang, {
    month: 'long',
    day: 'numeric',
    ...options
  });
}

export function getDateAgo(daysAgo: number): Date {
  const d = new Date();
  const pastDate = d.getDate() - daysAgo;
  d.setDate(pastDate);
  return d;
}

export function getRangeDatesString(
  from: Nullable<Date>,
  to: Nullable<Date>,
  lang: string,
  options?: Intl.DateTimeFormatOptions
) {
  const dateStringOptions: Intl.DateTimeFormatOptions = {
    month: 'short',
    day: 'numeric',
    ...options
  };

  if (!from && !to) {
    return '';
  }

  if (from && to) {
    return `${getDateString(from, lang, dateStringOptions)} - ${getDateString(
      to,
      lang,
      dateStringOptions
    )}`;
  }

  if (from && !to) {
    return getDateString(from, lang, dateStringOptions);
  }

  if (!from && to) {
    return getDateString(to, lang, dateStringOptions);
  }

  return '';
}

export function roundDate(d: Date): Date {
  return new Date(d.getFullYear(), d.getMonth(), d.getDate(), 0, 0, 0, 0);
}

export function isEqualRoundedDate(d1: Date, d2: Date): boolean {
  return roundDate(d1).getTime() === roundDate(d2).getTime();
}

export function secondsToHHMMSS(seconds: number): string {
  const str = new Date(seconds * 1000).toISOString();
  if (seconds < 60) return str.substr(15, 4);
  return str.substr(11, 8);
}

export function dateDistanceToNowArray(date: Date, lang: string) {
  const currentDate = new Date();

  const yearsDiff = differenceInYears(date, currentDate);
  const daysDiff = differenceInDays(date, currentDate);
  const hoursDiff = differenceInHours(date, currentDate);
  const hoursDiffDate = sub(date, { days: daysDiff });

  const res = [];

  if (yearsDiff)
    res.push(
      formatDistanceToNowStrict(date, {
        locale: getLocale(lang),
        unit: 'year'
      })
    );

  if (!yearsDiff)
    res.push(
      formatDistanceToNowStrict(date, {
        locale: getLocale(lang),
        unit: 'day',
        roundingMethod: 'floor'
      })
    );

  if (!yearsDiff && !daysDiff)
    res.push(
      formatDistanceToNowStrict(hoursDiffDate, {
        locale: getLocale(lang),
        unit: 'hour'
      })
    );

  if (!yearsDiff && !daysDiff && !hoursDiff)
    res.push(
      formatDistanceToNowStrict(hoursDiffDate, {
        locale: getLocale(lang),
        unit: 'minute'
      })
    );

  return res.filter((val) => !val.startsWith('0'));
}

export function formatUnixTime(unixTime: number) {
  const date = new Date(unixTime * 1000);

  const formattedDate = format(date, 'dd.MM');
  const formattedTime = format(date, 'HH:mm');

  return { date: formattedDate, time: formattedTime };
}

export function decomposeTime(timestamp: number): {
  days: number;
  hours: number;
  minutes: number;
} {
  const currentDate = new Date();
  const publicationDate = new Date(timestamp * 1000);

  const days = differenceInDays(currentDate, publicationDate);
  const hours = differenceInHours(currentDate, publicationDate) % 24;
  const minutes = differenceInMinutes(currentDate, publicationDate) % 60;

  return {
    days,
    hours,
    minutes
  };
}
