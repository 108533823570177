import { StateCreator } from 'zustand';

import { Nullable } from '@/app/types';
import { getAuthorDetail } from '@/modules/showroom/advert/detail/api';
import { AdvertAuthorDetail } from '@/modules/showroom/advert/detail/types';
import { updateAdvertisement } from '@/modules/showroom/advert/update/api';
import {
  getDetailFilledMaps,
  makeUpdateAdvertReqForVrp
} from '@/modules/showroom/advert/update/helpers';
import { UpdateAdvertContact } from '@/modules/showroom/advert/update/types';
import { showAlert } from '@/utils/network';

export interface CreateVrpStore {
  showPageLeaveWarn: boolean;
  setShowPageLeaveWarn: (showPageLeaveWarn: boolean) => void;
  lastSectionVisible: boolean;
  setLastSectionVisible: (lastSectionVisible: boolean) => void;
  advertId: Nullable<number>;
  setAdvertId: (advertId: Nullable<number>) => void;
  // Detail
  detail: Nullable<AdvertAuthorDetail>;
  saveDetail: (id: number) => Promise<void>;
  // Plate
  emirateId: Nullable<number>;
  setEmirateId: (v: Nullable<number>) => void;
  typeId: Nullable<number>;
  setTypeId: (v: Nullable<number>) => void;
  codeId: Nullable<number>;
  setCodeId: (v: Nullable<number>) => void;
  previewUrl: string;
  setPreviewUrl: (v: string) => void;
  imageUrl: string;
  setImageUrl: (v: string) => void;
  plateNumber: string;
  setPlateNumber: (v: string) => void;
  isNumberSaved: boolean;
  setNumberSaved: (v: boolean) => void;
  numberWasSaved: boolean;
  setNumberWasSaved: (v: boolean) => void;
  // Description
  description: string;
  setDescription: (v: string) => void;
  isDescriptionFilled: boolean;
  setDescriptionFilled: (v: boolean) => void;
  isDescrGenerating: boolean;
  setDescrGenerating: (v: boolean) => void;
  // Phone
  contacts: Nullable<UpdateAdvertContact[]>;
  setContacts: (contacts: Nullable<UpdateAdvertContact[]>) => void;
  isPhoneFilled: boolean;
  setPhoneFilled: (isPhoneFilled: boolean) => void;
  chatOnly: boolean;
  setChatOnly: (chatOnly: boolean) => void;
  disableChat: boolean;
  setDisableChat: (disableChat: boolean) => void;
  // Contacts
  addressId: Nullable<number>;
  setAddressId: (addressId: Nullable<number>) => void;
  isAddressFilled: boolean;
  setAddressFilled: (isAddressFilled: boolean) => void;
  // Price
  price: number;
  setPrice: (price: number) => void;
  isPriceFilled: boolean;
  setPriceFilled: (v: boolean) => void;
  // Discount
  discount: number;
  setDiscount: (discount: number) => void;
  discountTypes: number[];
  setDiscountTypes: (discountTypes: number[]) => void;
  // Promo package
  promotionPackageId: Nullable<number>;
  setPromotionPackageId: (promotionPackageId: Nullable<number>) => void;
  autoExtension: boolean;
  setAutoExtension: (autoExtension: boolean) => void;
  // Detail
  handleDetail: (detail: AdvertAuthorDetail) => void;
  // Reset
  resetAll: () => void;
}

export const stateCreator: StateCreator<CreateVrpStore> = (set, get) => ({
  showPageLeaveWarn: true,
  setShowPageLeaveWarn: (showPageLeaveWarn) => set({ showPageLeaveWarn }),
  lastSectionVisible: false,
  setLastSectionVisible: (lastSectionVisible: boolean) =>
    set({ lastSectionVisible }),
  advertId: null,
  setAdvertId: (advertId: Nullable<number>) => set({ advertId }),
  // Detail
  detail: null,
  handleDetail: (detail: AdvertAuthorDetail) => {
    const chatOnly =
      detail.available_phone === null ? false : !detail.available_phone;
    const isNumberSaved = !!detail.vrp_image_url;
    const { newMap } = getDetailFilledMaps(detail);

    set({
      showPageLeaveWarn: true,
      detail,
      advertId: detail.id,
      emirateId: detail.vrp_plate_emirate?.id || null,
      typeId: detail.vrp_type?.id || null,
      codeId: detail.vrp_plate_code?.id || null,
      imageUrl: detail.vrp_image_url || '',
      previewUrl: '',
      plateNumber: detail.vrp_plate_number || '',
      isNumberSaved,
      numberWasSaved: newMap.numberWasSaved,
      description: detail.description || '',
      isDescriptionFilled: newMap.isDescriptionFilled,
      chatOnly,
      disableChat:
        detail.available_chat === null ? false : !detail.available_chat,
      contacts:
        detail.communication_method
          ?.filter((c) => !!c.showroom_contact)
          ?.map((c) => ({
            id: c.showroom_contact?.id as number,
            communication_method: {
              phone: {
                available: !!c.available_phone,
                call_after: c.call_after,
                call_before: c.call_before
              },
              whatsapp: {
                available: !!c.available_whatsapp
              }
            }
          })) || [],
      isPhoneFilled: newMap.isPhoneFilled,
      addressId: detail.address_id,
      isAddressFilled: newMap.isAddressFilled,
      price: detail.price || 0,
      isPriceFilled: newMap.isPriceFilled,
      discount: detail.discount?.discount_amount || 0,
      discountTypes: detail.discount?.discounts?.map((d) => d.id) || []
    });
  },
  saveDetail: (id: number) => {
    return new Promise((resolve, reject) => {
      const state = get();

      getAuthorDetail(id).then((r) => {
        updateAdvertisement(id, {
          ...makeUpdateAdvertReqForVrp(state, r.data)
        })
          .then(() => resolve())
          .catch((error) => {
            showAlert({ error });
            reject();
          });
      });
    });
  },
  // Plate
  emirateId: null,
  setEmirateId: (emirateId: Nullable<number>) => set({ emirateId }),
  typeId: null,
  setTypeId: (typeId: Nullable<number>) => set({ typeId }),
  codeId: null,
  setCodeId: (codeId: Nullable<number>) => set({ codeId }),
  imageUrl: '',
  setImageUrl: (imageUrl: string) => set({ imageUrl }),
  previewUrl: '',
  setPreviewUrl: (previewUrl: string) => set({ previewUrl }),
  plateNumber: '',
  setPlateNumber: (plateNumber: string) => set({ plateNumber }),
  isNumberSaved: false,
  setNumberSaved: (isNumberSaved: boolean) => set({ isNumberSaved }),
  numberWasSaved: false,
  setNumberWasSaved: (numberWasSaved: boolean) => set({ numberWasSaved }),
  // Description
  description: '',
  setDescription: (description: string) => set({ description }),
  isDescriptionFilled: false,
  setDescriptionFilled: (isDescriptionFilled: boolean) =>
    set({ isDescriptionFilled }),
  isDescrGenerating: false,
  setDescrGenerating: (isDescrGenerating: boolean) =>
    set({ isDescrGenerating }),
  // Phone
  contacts: null,
  setContacts: (contacts: Nullable<UpdateAdvertContact[]>) => set({ contacts }),
  isPhoneFilled: false,
  setPhoneFilled: (isPhoneFilled: boolean) => set({ isPhoneFilled }),
  chatOnly: false,
  setChatOnly: (chatOnly: boolean) => set({ chatOnly }),
  disableChat: false,
  setDisableChat: (disableChat: boolean) => set({ disableChat }),
  // Contacts
  addressId: null,
  setAddressId: (addressId: Nullable<number>) => set({ addressId }),
  isAddressFilled: false,
  setAddressFilled: (isAddressFilled: boolean) => set({ isAddressFilled }),
  // Price
  price: 0,
  setPrice: (price: number) => set({ price }),
  isPriceFilled: false,
  setPriceFilled: (isPriceFilled: boolean) => set({ isPriceFilled }),
  // Discount
  discount: 0,
  setDiscount: (discount: number) => set({ discount }),
  discountTypes: [],
  setDiscountTypes: (discountTypes: number[]) => set({ discountTypes }),
  // Promo
  promotionPackageId: null,
  setPromotionPackageId: (promotionPackageId: Nullable<number>) =>
    set({ promotionPackageId }),
  autoExtension: false,
  setAutoExtension: (autoExtension: boolean) => set({ autoExtension }),
  resetAll: () => {
    set({
      emirateId: null,
      typeId: null,
      codeId: null,
      previewUrl: '',
      imageUrl: '',
      plateNumber: '',
      isNumberSaved: false,
      numberWasSaved: false,
      description: '',
      isDescriptionFilled: false,
      isDescrGenerating: false,
      contacts: null,
      isPhoneFilled: false,
      chatOnly: false,
      disableChat: false,
      addressId: null,
      isAddressFilled: false,
      price: 0,
      discount: 0,
      discountTypes: [],
      isPriceFilled: false,
      promotionPackageId: null,
      autoExtension: false,
      lastSectionVisible: false,
      showPageLeaveWarn: true
    });
  }
});
