import cn from 'classnames';
import { useContext, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { useImgPlaceholder } from '@/hooks/useImgPlaceholder';
import { useLang } from '@/hooks/useLang';
import { useOnceTrue } from '@/hooks/useOnceTrue';
import { BookingConfirm } from '@/modules/booking/MyBookings/MyBookingsList/MyBookingCard/BookingConfirm/BookingConfirm';
import {
  BookingExistedStatus,
  BookingExistedStatusType,
  BookingType
} from '@/modules/booking/types';
import creditCls from '@/modules/credit/CreditRequests/CreditRequest/CreditRequest.module.scss';
import {
  CopyIcon,
  MailIcon,
  PersonIcon,
  PhoneIcon
} from '@/modules/credit/CreditRequests/CreditRequest/icons';
import { usePermissionAccess } from '@/modules/roles/helpers';
import { useAdvertName } from '@/modules/showroom/advert/hooks';
import { MyAdvertsContext } from '@/modules/showroom/advert/my/MyAdverts/my-adverts-ctx';
import { Tariffs } from '@/modules/showroom/advert/rent/my/MyRentAdvertsList/MyRentAdvertCard/Tariffs/Tariffs';
import { Button } from '@/ui/Button/Button';
import { ImgSkeleton } from '@/ui/ImgSkeleton/ImgSkeleton';
import { useFormatPhone } from '@/ui/PhoneInput/helpers';
import { APP_URL, CURRENCY_DISPLAY } from '@/utils/consts';
import { copyToClipboard } from '@/utils/copy';
import {
  diffInDays,
  getDateString,
  isSameMonth,
  isSameYear,
  isThisYear
} from '@/utils/date';
import { numberWithSpaces, pluralKey } from '@/utils/format';

import { RemoveIcon, CheckIcon } from './icons';
import cls from './MyBookingCard.module.scss';

type Props = {
  booking: BookingType;
  simple?: boolean;
  onConfirm?: () => void;
};

export function MyBookingCard({ booking, simple, onConfirm }: Props) {
  const { t } = useTranslation();
  const [lang] = useLang();

  const permissions = usePermissionAccess();
  const canControl = permissions.rent.book.control;

  const { status: _status } = useContext(MyAdvertsContext);
  const status = _status as BookingExistedStatus;
  const isArchived = booking.archived;
  const isConfirmed =
    !isArchived && status.status === BookingExistedStatusType.confirmed;

  // Ad info
  const advert = booking.rent_ad;

  const imgPlaceholder = useImgPlaceholder();
  const imgSrc = useMemo(() => {
    if (advert.photos && advert.photos[0]) {
      return advert.photos[0].thumbnail_url || advert.photos[0].photo_url;
    }
    return imgPlaceholder;
  }, [advert.photos, imgPlaceholder]);
  const name = useAdvertName(advert);

  const advertUrl = advert.url ? `${APP_URL}/${advert.url}` : '';
  const editUrl = `/cars/rent/edit/${advert.id}`;

  const mod = advert.modification;

  // Book
  const bookingReqDate = useMemo(() => {
    if (!booking.booking_request_date) return '';
    const date = new Date(booking.booking_request_date * 1000);

    const thisYear = isThisYear(date);
    const dateStr = getDateString(date, lang, {
      day: '2-digit',
      month: 'long',
      hour: '2-digit',
      minute: '2-digit',
      year: thisYear ? undefined : 'numeric'
    });
    return dateStr;
  }, [booking.booking_request_date, lang]);

  const startDate = useMemo(
    () => new Date(booking.about_booking.dates.start * 1000),
    [booking.about_booking.dates.start]
  );
  const endDate = useMemo(
    () => new Date(booking.about_booking.dates.end * 1000),
    [booking.about_booking.dates.end]
  );

  const daysCount = useMemo(() => {
    const days = diffInDays(endDate, startDate);
    return days;
  }, [endDate, startDate]);

  const daysPriceStr = useMemo(() => {
    return t('common.slashSeparated', {
      first: t('priceCurrency', {
        price: numberWithSpaces(booking.about_booking.total_cost, lang),
        currency: CURRENCY_DISPLAY.aed
      }),
      second: pluralKey(
        daysCount,
        t('daysPlural.one', {
          days: daysCount
        }),
        t('daysPlural.few', {
          days: daysCount
        }),
        t('daysPlural.many', {
          days: daysCount
        })
      )
    });
  }, [booking.about_booking.total_cost, daysCount, lang, t]);

  const bookingPeriodStr = useMemo(() => {
    const sameMonth = isSameMonth(startDate, endDate);
    const sameYear = isSameYear(startDate, endDate);

    const startStr = getDateString(startDate, lang, {
      day: '2-digit',
      month: sameMonth ? undefined : 'numeric',
      year: sameYear ? undefined : 'numeric'
    });
    const endStr = getDateString(endDate, lang, {
      day: '2-digit',
      month: sameMonth ? 'long' : 'numeric',
      year: sameYear ? undefined : 'numeric'
    });

    return t('common.hyphenSeparated', { first: startStr, second: endStr });
  }, [endDate, lang, startDate, t]);

  // Hover
  const [hovered, setHovered] = useState(false);

  // Lead info
  const lead = booking.about_lead;
  const formattedPhone = useFormatPhone(lead.phone_number);
  const onCopyClick = (copyStr: string) => () => {
    copyToClipboard(copyStr, t('common.copied') as string);
  };

  // Confirm
  const [isConfirmOpen, setConfirmOpen] = useState(false);
  const [accept, setAccept] = useState(false);
  const confirmOpenedOnce = useOnceTrue(isConfirmOpen);
  const onConfirmClick = (accept: boolean) => () => {
    setAccept(accept);
    setConfirmOpen(true);
  };

  return (
    <div
      className={cn(cls.root, { [cls.root_simple]: simple })}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
    >
      <div className={cls.advert}>
        <div className={cls.img}>
          <ImgSkeleton
            className={cls.img_inner}
            src={imgSrc}
            alt=""
            imgProxyWidth={189}
            imgProxyHeight={142}
          />
          {!simple && (
            <>
              {advertUrl ? (
                <a
                  className={cls.link}
                  href={advertUrl}
                  target="_blank"
                  rel="noreferrer"
                />
              ) : (
                <Link className={cls.link} to={editUrl} />
              )}
            </>
          )}
        </div>

        <div className={cls.info}>
          <p className={cls.name_wrap}>
            <b className={cls.name}>{name}</b>
            {advertUrl ? (
              <a
                className={cls.link}
                href={advertUrl}
                target="_blank"
                rel="noreferrer"
              />
            ) : (
              <Link className={cls.link} to={editUrl} />
            )}
          </p>

          {(advert.body || mod) && (
            <p className={cls.specs}>
              {advert.body?.name}
              <span>{t('common.dot')}</span>
              {t('common.commaSeparated', {
                first: t('volume', { value: mod?.volume?.short_name }),
                second: mod?.engine?.name
              })}
              <span>{t('common.dot')}</span>
              {mod?.drive_unit?.name}
            </p>
          )}

          {advert.tariffs && (
            <div className={cls.tariffs}>
              <Tariffs
                tariffs={advert.tariffs}
                hovered={hovered || !!simple}
                listOnly
              />
            </div>
          )}

          {!simple && bookingReqDate && (
            <p className={cls.dates}>{bookingReqDate}</p>
          )}
        </div>

        {!simple && (
          <>
            <div
              className={cn(creditCls.col, cls.col)}
              style={{ minHeight: 146 }}
            >
              <p className={creditCls.col_label}>{t('booking.about.title')}</p>
              <div className={creditCls.row}>
                <p>{t('booking.about.dates')}</p>
                <b>{bookingPeriodStr}</b>
              </div>

              {daysPriceStr && (
                <div className={creditCls.row}>
                  <p>{t('booking.about.totalCost')}</p>
                  <b>{daysPriceStr}</b>
                </div>
              )}

              {isConfirmed && (
                <div className={creditCls.row}>
                  <p>{t('booking.status')}</p>
                  <b style={{ color: 'var(--clr-green)' }}>
                    {t('booking.confirmed')}
                  </b>
                </div>
              )}

              {isArchived && (
                <div className={creditCls.row}>
                  <p>{t('booking.status')}</p>
                  <b style={{ color: 'var(--clr-primary)' }}>
                    {t('booking.rejected')}
                  </b>
                </div>
              )}

              {canControl && !isArchived && !isConfirmed && (
                <div style={{ display: 'flex', gap: 4 }}>
                  <Button
                    onClick={onConfirmClick(false)}
                    variant="secondary"
                    color="red"
                    size="compact"
                    gap={4}
                    fullWidth
                  >
                    <RemoveIcon />
                    {t('booking.decline')}
                  </Button>
                  <Button
                    onClick={onConfirmClick(true)}
                    variant="secondary"
                    color="green"
                    size="compact"
                    gap={4}
                    fullWidth
                  >
                    <CheckIcon />
                    {t('booking.accept')}
                  </Button>
                </div>
              )}
            </div>

            <div
              className={cn(creditCls.col, cls.col)}
              style={{ minHeight: 146 }}
            >
              <p className={creditCls.col_label}>
                {t('credits.req.aboutLead')}
              </p>

              <button
                className={creditCls.contact}
                type="button"
                onClick={onCopyClick(lead.name)}
              >
                <PersonIcon />
                <span>{lead.name}</span>
                <CopyIcon />
              </button>
              <button
                className={creditCls.contact}
                type="button"
                onClick={onCopyClick(formattedPhone)}
              >
                <PhoneIcon />
                <span>{formattedPhone}</span>
                <CopyIcon />
              </button>
              {lead.email && (
                <button
                  className={creditCls.contact}
                  type="button"
                  onClick={onCopyClick(lead.email)}
                >
                  <MailIcon />
                  <span>{lead.email}</span>
                  <CopyIcon />
                </button>
              )}
            </div>
          </>
        )}
      </div>

      {canControl && !simple && confirmOpenedOnce && (
        <BookingConfirm
          isOpen={isConfirmOpen}
          close={() => setConfirmOpen(false)}
          booking={booking}
          decline={!accept}
          onConfirm={onConfirm}
        />
      )}
    </div>
  );
}
