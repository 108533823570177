import { axios } from '@/app/api';
import {
  GetPubDaysReq,
  GetPubDaysRes,
  GetBrokenParamsReq,
  GetBrokenParamsRes,
  GetSortFiltersReq,
  GetSortFiltersRes,
  GetReasonsOfRemoveReq,
  GetReasonsOfRemoveRes,
  RemoveFromSaleReq,
  getViewsAndExpensesByDayRes,
  getPriceStatsRes,
  AdvertEngagementSmallRes,
  RefreshAdOnceParams,
  SetRefreshScheduleReq,
  SetRefreshScheduleParams,
  CancelRefreshScheduleParams
} from '@/modules/showroom/advert/my/types';
import {
  SearchAdvertsReq,
  SearchAdvertsRes
} from '@/modules/showroom/advert/search/types';

export function removeFromSale(id: number, req: RemoveFromSaleReq) {
  return axios.patch<unknown>(
    `/dealers/showrooms/advertisements/${id}/remove-from-sale`,
    req
  );
}

// Pub days
export function getPubDays(params?: GetPubDaysReq) {
  return axios.get<GetPubDaysRes>('/advertisements/pub_days/list', { params });
}

// Broken params
export function getBrokenParams(params?: GetBrokenParamsReq) {
  return axios.get<GetBrokenParamsRes>('/advertisements/broken-params/list', {
    params
  });
}

// Sort filters
export function getSortFilters(params?: GetSortFiltersReq) {
  return axios.get<GetSortFiltersRes>('/advertisements/sort_filters/list', {
    params
  });
}

// Reasons of remove
export function getReasonsOfRemove(params?: GetReasonsOfRemoveReq) {
  return axios.get<GetReasonsOfRemoveRes>(
    '/advertisements/reasons-of-remove/list',
    {
      params
    }
  );
}

//Detailed advert statistics
export function getViewsAndExpensesByDay(id: number) {
  return axios.get<getViewsAndExpensesByDayRes>(
    `/dealers/showrooms/advertisements/${id}/statistic-since-last-publication/views-with-exps`
  );
}

export function getPriceStats(id: number) {
  return axios.get<getPriceStatsRes>(
    `/dealers/showrooms/advertisements/${id}/statistic-since-last-publication/depending-price`
  );
}

export function getAdvertStatsEngagement(id: number) {
  return axios.get<AdvertEngagementSmallRes>(
    `/dealers/showrooms/advertisements/${id}/stats/engagement`
  );
}

export function getMyAds(req: Partial<SearchAdvertsReq>) {
  return axios.post<SearchAdvertsRes>(
    '/dealers/showrooms/advertisements/my',
    req
  );
}

// Refresh
export function refreshAdOnce(params: RefreshAdOnceParams) {
  return axios.post<unknown>(`/advertisements/refresh`, null, { params });
}

export function setRefreshSchedule(
  params: SetRefreshScheduleParams,
  req: SetRefreshScheduleReq
) {
  return axios.patch<unknown>(`/advertisements/refresh/schedule`, req, {
    params
  });
}

export function cancelRefreshSchedule(params: CancelRefreshScheduleParams) {
  return axios.delete<unknown>(`/advertisements/refresh/schedule`, { params });
}
