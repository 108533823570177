import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Nullable, SortType } from '@/app/types';
import { useDebounce } from '@/hooks/useDebounce';
import { Leads } from '@/modules/leads/LeadsPage/Leads/Leads';
import { Toolbar } from '@/modules/leads/LeadsPage/Toolbar/Toolbar';
import { GetLeadsReq, LeadsPeriod, LeadType } from '@/modules/leads/types';
import { SortFilter } from '@/modules/showroom/advert/my/types';
import {
  emptyAutoFilter,
  EMPTY_BRAND_ID
} from '@/modules/showroom/advert/search/consts';
import {
  countByArr,
  smartAutoFiltersIds
} from '@/modules/showroom/advert/search/helpers';
import { AutoFilterIds } from '@/modules/showroom/advert/search/types';
import { LayoutHeader } from '@/ui/layouts/SidebarLayout/LayoutHeader';
import { PageHead } from '@/ui/PageHead/PageHead';

import { Header } from './Header/Header';
import cls from './LeadsPage.module.scss';

const sortMap: Record<SortType, number> = {
  asc: 1,
  desc: -1
};

const sortMapRev: Record<number, SortType> = {
  [1]: 'asc',
  [-1]: 'desc'
};

const defaults = {
  search: '',
  autoFilters: [
    {
      brand_id: EMPTY_BRAND_ID,
      model_id: null,
      generation_id: null
    }
  ],
  contactIds: null,
  sort: null
};

export function LeadsPage() {
  const { t } = useTranslation();

  // Refresh key
  const [updateKey, setUpdateKey] = useState<number>(0);
  const refresh = useCallback(() => {
    setUpdateKey((p) => p + 1);
  }, []);

  // Type
  const [type, setType] = useState<Nullable<LeadType>>(null);

  // Search
  const [search, setSearch] = useState(defaults.search);
  const debouncedSearch = useDebounce(search, 800);

  // Auto filters
  const [autoFilters, setAutoFilters] = useState<AutoFilterIds[]>(
    defaults.autoFilters
  );
  const updateAutoFilter = (v: AutoFilterIds) => {
    const current = autoFilters.filter(
      (v) => v.brand_id !== emptyAutoFilter.brand_id
    )[0];

    if (current) {
      setAutoFilters([{ ...current, ...v }]);
    } else {
      setAutoFilters([v]);
    }
  };

  // Contact
  const [contactIds, setContactIds] = useState<Nullable<number[]>>(
    defaults.contactIds
  );

  // Sort
  const sortFilters = useMemo<SortFilter[]>(
    () => [
      { id: sortMap.asc, name: t('ascending') },
      { id: sortMap.desc, name: t('descending') }
    ],
    [t]
  );
  const [sortId, setSortId] = useState<Nullable<number>>(defaults.sort);

  // Period
  const [period, setPeriod] = useState<Nullable<LeadsPeriod>>(null);

  // Reset
  const hasAutoFilters = !!countByArr(
    autoFilters.filter((a) => a.brand_id !== EMPTY_BRAND_ID)
  );
  const hasContacts = !!contactIds && contactIds.length > 0;
  const hasFilters = !!search || hasAutoFilters || hasContacts || !!period;
  const showReset = hasFilters;
  const onResetClick = () => {
    setSearch(defaults.search);
    setAutoFilters(defaults.autoFilters);
    setContactIds(defaults.contactIds);
    setSortId(defaults.sort);
    setPeriod(null);
  };

  // Req
  const req = useMemo<GetLeadsReq>(() => {
    const autoIds = smartAutoFiltersIds(autoFilters);
    return {
      ...autoIds,
      showroom_contact_ids: contactIds,
      lead_type: type,
      period
    };
  }, [autoFilters, contactIds, period, type]);

  const filtersKey = useMemo(() => {
    const { brand_ids, model_ids, showroom_contact_ids, lead_type, period } =
      req;
    const brands = brand_ids ? brand_ids.join('') : '';
    const models = model_ids ? model_ids.join('') : '';
    const contacts = showroom_contact_ids ? showroom_contact_ids.join('') : '';
    return `${debouncedSearch}${brands}${models}${contacts}${lead_type || ''}${
      sortId || ''
    }${period ? JSON.stringify(period) : ''}`;
  }, [debouncedSearch, req, sortId]);

  return (
    <div>
      <LayoutHeader title={t('leads.title')} />
      <PageHead title={t('pageTitle', { title: t('leads.title') })} />

      <div className={cls.root}>
        <Header type={type} onTypeChange={setType} onMarkAll={refresh} />
        <Toolbar
          search={search}
          setSearch={setSearch}
          autoFilters={autoFilters}
          updateAutoFilter={updateAutoFilter}
          contactIds={contactIds}
          setContactIds={setContactIds}
          sortFilters={sortFilters}
          sortId={sortId}
          setSortId={setSortId}
          period={period}
          setPeriod={setPeriod}
          showReset={showReset}
          onResetClick={onResetClick}
        />
        <Leads
          key={`${filtersKey}${updateKey}`}
          sort={sortId ? sortMapRev[sortId] : null}
          search={debouncedSearch}
          req={req}
          hasFilters={hasFilters}
        />
      </div>
    </div>
  );
}
